import { logout } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { INVALID_REQUEST } from 'config'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { validateBudgetSignSignature } from 'services'

import BudgetScopeOfWork from '.'

export default function BorrowerEditBudget() {
  const urlParams: any = useParams()
  const { loanNumber, token, urlSignature } = urlParams
  const registeredToken = useSelector((state: any) => state.auth.token)
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)

  const dispatch = useDispatch()
  const navigate = useHistory()

  useEffect(() => {
    const payload: any = jwtDecode(token)

    validateBudgetSignSignature(payload.budgetId, {
      loanNumber,
      urlSignature,
      token,
    }).then(async ({ success }) => {
      if (!success) {
        toast(INVALID_REQUEST, { type: 'error' })
        navigate.push('/')
        dispatch(logout())
        return
      }
      setValidated(true)
    })
  }, [token, loanNumber, urlSignature])

  useEffect(() => {
    if (!registeredToken || !validated) return

    setLoading(false)
  }, [registeredToken, validated])

  return (
    <TemporaryLayout title="Budget & Scope of Work" noOverview={true} requireSign>
      {!loading && <BudgetScopeOfWork isBorrower={true} />}
      <LayoutLoading show={loading} />
    </TemporaryLayout>
  )
}
