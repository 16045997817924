import {
  API_PAY_INVOICE,
  API_PAY_PLAID_ACCOUNTS_BALANCE,
  API_PAY_PLAID_CLIENT_TOKEN,
  API_PAY_SEND_PAYMENT_LINK,
  API_REFUND_INVOICE,
} from 'config'
import Api from 'services/api'

export enum AccountSubtype {
  Checking = 'checking',
  Savings = 'savings',
  MoneyMarket = 'money market',
}

export interface IPlaidBalanceData {
  id: string
  institution: string
  balance: number
  mask: string
  name: string
  accessToken: string
  subtype: AccountSubtype | null
}

export const getPlaidClientToken = (
  type: 'kyc' | 'bank' | 'withdraw' | 'deposit',
  data: Record<string, any> = {},
): Promise<{ link_token: string; expiration: string; [key: string]: any }> => {
  return Api.post(API_PAY_PLAID_CLIENT_TOKEN, data, { type })
}

export const getPlaidAccountBalance = (data: Record<string, any>): Promise<IPlaidBalanceData[]> => {
  return Api.post(API_PAY_PLAID_ACCOUNTS_BALANCE, data, {}, { timeout: 600000 })
}

export const sendPaymentLink = (data: Record<string, any>) => {
  return Api.post(API_PAY_SEND_PAYMENT_LINK, data)
}

export const getInvoices = () => {
  return Api.get(API_PAY_INVOICE)
}

export const refundInvoice = (intentId: string) => {
  return Api.post(API_REFUND_INVOICE, {}, { intentId })
}
