import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import { persistor, store } from './reducers'
import reportWebVitals from './reportWebVitals'

window.process = window.process || {
  nextTick: (fun) => fun(),
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <PersistGate loading={<div></div>} persistor={persistor}>
      <Router>
        <App />
      </Router>
      <ToastContainer theme="colored" />
    </PersistGate>
  </Provider>,
)

reportWebVitals()
