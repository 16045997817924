import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    name: {
      inputType: 'text',
      title: 'Name',
      required: true,
    },
  }
}
