import Api from 'services/api'

const API_PARTICIPANTS = '/participants'
const API_PARTICIPANT_BY_ID = '/participants/:id'
const API_PARTICIPANT_LOAN_DETAIL = '/participants/loanDetail/:loanNumber'
const API_PARTICIPANT_LOAN = '/participants/loan/:participantID'

export const filterParticipants = (filters: Record<string, any>) => {
  return Api.get(API_PARTICIPANTS, filters)
}

export const createParticipant = (data: Record<string, any>) => {
  return Api.post(API_PARTICIPANTS, data)
}

export const updateParticipant = (id: number, data: Record<string, any>) => {
  return Api.put(API_PARTICIPANT_BY_ID, data, { id })
}

export const sendParticipantEmail = (id: number) => {
  return Api.post(`${API_PARTICIPANT_BY_ID}/email`, {}, { id })
}

export const updateParticipantPassword = (id: number, password: string) => {
  return Api.post(API_PARTICIPANT_BY_ID, { password }, { id })
}

export const getParticipantAssignedLoans = (id: number) => {
  return Api.get(API_PARTICIPANT_BY_ID, {}, { id })
}

export const getLoanParticipantDetail = (loanNumber: number) => {
  return Api.get(API_PARTICIPANT_LOAN_DETAIL, {}, { loanNumber })
}

export const addParticipantLoan = (participantID: number, data: any) => {
  return Api.post(API_PARTICIPANT_LOAN, data, { participantID })
}
