import {
  API_ADDITIONAL_PROPERTY_INFO,
  API_ADDITIONAL_PROPERTY_INFO_LOGS,
  API_BORROWER_INFO,
  API_BORROWER_LOG,
  API_BORROWER_LOG_DOWNLOAD_CSV,
  API_BORROWER_LOG_DOWNLOAD_PDF,
  API_DELETE_ADDITIONAL_PROPERTY_INFO,
  API_LOAN_APPLICATION_ADD_HAND_SIGNED_PDF,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER_ITEM,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER_PULL_CREDIT_REPORT,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER_SEND_SIGNATURE_REQUEST,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER_UPLOAD_SIGNED_PDF,
  API_LOAN_APPLICATION_ADDITIONAL_MEMBER_VALIDATE_SIGN_SIGNATURE,
  API_LOAN_APPLICATION_ASSET,
  API_LOAN_APPLICATION_ASSET_CREATE,
  API_LOAN_APPLICATION_ASSET_DELETE,
  API_LOAN_APPLICATION_ASSET_LOG,
  API_LOAN_APPLICATION_ASSET_RESET,
  API_LOAN_APPLICATION_ASSET_TRACK_LOG,
  API_LOAN_APPLICATION_ASSET_UPDATE,
  API_LOAN_APPLICATION_ASSET_VERIFY,
  API_LOAN_APPLICATION_ASSET_VERIFY_BANK_STATEMENT,
  API_LOAN_APPLICATION_COPY_APPLICATION_TO_URL,
  API_LOAN_APPLICATION_EARNEST_MONEY_UPDATE,
  API_LOAN_APPLICATION_LIABILITY_CREATE,
  API_LOAN_APPLICATION_LIABILITY_DELETE,
  API_LOAN_APPLICATION_LIABILITY_UPDATE,
  API_LOAN_APPLICATION_PULL_CREDIT_REPORT,
  API_LOAN_APPLICATION_PULL_CREDIT_REPORT_HISTORY,
  API_LOAN_APPLICATION_SEND_APPLICATION_TO,
  API_LOAN_APPLICATION_SEND_SIGNATURE_REQUEST,
  API_LOAN_APPLICATION_TRACK,
  API_LOAN_APPLICATION_TRACK_CREATE,
  API_LOAN_APPLICATION_TRACK_DELETE,
  API_LOAN_APPLICATION_TRACK_DOCUMENT,
  API_LOAN_APPLICATION_TRACK_DOCUMENT_DELETE,
  API_LOAN_APPLICATION_TRACK_DOWNLOAD,
  API_LOAN_APPLICATION_TRACK_UPDATE,
  API_LOAN_APPLICATION_UPLOAD_SIGNED_PDF,
  API_LOAN_APPLICATION_VALIDATE_SIGN_SIGNATURE,
  API_LOAN_BORROWER_SIGN_STATUS,
  API_SWAP_BORROWERS,
  API_TEMPORARY_USER_INFO,
  API_UPDATE_ADDITIONAL_PROPERTY_INFO,
} from 'config'
import Api from 'services/api'

import { downloadFile } from '../documents'

// Liability Information of Loan Application
export const createLoanLiabilityInformation = (data: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_LIABILITY_CREATE, data)
}

export const updateLoanLiabilityInformation = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_APPLICATION_LIABILITY_UPDATE, data, { id })
}

export const deleteLoanLiabilityInformation = (id: string) => {
  return Api.delete(API_LOAN_APPLICATION_LIABILITY_DELETE, {}, { id })
}

export const getAdditionalPropertyInfo = () => {
  return Api.get(API_ADDITIONAL_PROPERTY_INFO)
}

export const getAdditionalPropertyInfoLogs = (fieldName: string) => {
  return Api.get(API_ADDITIONAL_PROPERTY_INFO_LOGS, {}, { fieldName })
}

export const createAdditionalPropertyInfo = (data: Record<string, any>) => {
  return Api.post(API_ADDITIONAL_PROPERTY_INFO, data)
}

export const updateAdditionalPropertyInfo = (id: number, data: Record<string, any>) => {
  return Api.put(API_UPDATE_ADDITIONAL_PROPERTY_INFO, data, { id })
}

export const deleteAdditionalPropertyInfo = (id: number) => {
  return Api.delete(API_DELETE_ADDITIONAL_PROPERTY_INFO, {}, { id })
}

// Asset Information of Loan Application
export const getLoanAssetInformation = () => {
  return Api.get(API_LOAN_APPLICATION_ASSET)
}

export const createLoanAssetInformation = (data: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_ASSET_CREATE, data)
}

export const reloadLoanAssetInformation = () => {
  return Api.put(API_LOAN_APPLICATION_ASSET, {}, {}, { timeout: 600000 })
}

export const resetLoanAssetInformation = () => {
  return Api.delete(API_LOAN_APPLICATION_ASSET_RESET)
}

export const updateLoanAssetInformation = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_APPLICATION_ASSET_UPDATE, data, { id })
}

export const deleteLoanAssetInformation = (id: string) => {
  return Api.delete(API_LOAN_APPLICATION_ASSET_DELETE, {}, { id })
}

export const getLoanAssetInformationVerificationEmailLogs = () => {
  return Api.get(API_LOAN_APPLICATION_ASSET_LOG)
}

export const copyAssetVerificationUrl = () => {
  return Api.get(API_LOAN_APPLICATION_ASSET_VERIFY)
}

export const sendLoanAssetInformationVerificationEmail = () => {
  return Api.post(API_LOAN_APPLICATION_ASSET_VERIFY)
}

export const createAssetInformationVerifyTask = () => {
  return Api.put(API_LOAN_APPLICATION_ASSET_VERIFY)
}

export const uploadAssetInformationBankStatement = (id: string, file: File) => {
  return Api.uploadFiles(API_LOAN_APPLICATION_ASSET_VERIFY_BANK_STATEMENT, {}, { id }, [file])
}

// Track Record of Loan Application
export const getLoanTrackRecord = () => {
  return Api.get(API_LOAN_APPLICATION_TRACK)
}

export const downloadLoanTrackRecord = async (loanNumber: number) => {
  const res: Blob = await Api.get(
    API_LOAN_APPLICATION_TRACK_DOWNLOAD,
    {},
    {},
    {
      responseType: 'blob',
    },
  )
  downloadFile(`Track Record - ${loanNumber}.csv`, res)
}

export const createLoanTrackRecord = (data: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_TRACK_CREATE, data)
}

export const updateLoanTrackRecord = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_APPLICATION_TRACK_UPDATE, data, { id })
}

export const deleteLoanTrackRecord = (id: string) => {
  return Api.delete(API_LOAN_APPLICATION_TRACK_DELETE, {}, { id })
}

export const addTrackRecordDocuments = (data: Array<Record<string, any>>) => {
  return Api.post(API_LOAN_APPLICATION_TRACK_DOCUMENT, data)
}

export const deleteTrackRecordDocument = (id: number) => {
  return Api.delete(API_LOAN_APPLICATION_TRACK_DOCUMENT_DELETE, {}, { id })
}

export const getAssetTrackLogs = (key: string) => {
  return Api.get(API_LOAN_APPLICATION_ASSET_TRACK_LOG, {}, { key })
}

export const pullCreditReport = (method: string, borrowerSeperator: string) => {
  return Api.post(API_LOAN_APPLICATION_PULL_CREDIT_REPORT, { method, borrowerSeperator }, {}, { timeout: 600000 })
}

export const getCreditRequestHistory = (borrowerSeperator: string) => {
  return Api.get(API_LOAN_APPLICATION_PULL_CREDIT_REPORT_HISTORY, {}, { borrowerSeperator })
}

export const sendSignatureRequest = () => {
  return Api.post(API_LOAN_APPLICATION_SEND_SIGNATURE_REQUEST)
}

export const sendApplicationTo = (email: string, borrowerSeperator: string) => {
  return Api.post(API_LOAN_APPLICATION_SEND_APPLICATION_TO, { email, borrowerSeperator })
}

export const copyApplicationToUrl = (email: string, borrowerSeperator: string) => {
  return Api.post(API_LOAN_APPLICATION_COPY_APPLICATION_TO_URL, { email, borrowerSeperator })
}

export const validateSignApplicationSignature = (data: object) => {
  return Api.post(API_LOAN_APPLICATION_VALIDATE_SIGN_SIGNATURE, data)
}

export const uploadSignApplicationPdf = (commercialJson: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_UPLOAD_SIGNED_PDF, { commercialJson }, {}, { timeout: 150000 })
}

export const addHandSignApplicationPdf = (fileKey: string) => {
  return Api.post(API_LOAN_APPLICATION_ADD_HAND_SIGNED_PDF, { fileKey })
}

export const borrowerInfoUpdate = (data: Record<string, string | boolean | any>) => {
  return Api.put(API_BORROWER_INFO, data)
}

export const getBorrowerInfo = (loanNumber?: number) => {
  return Api.get(API_BORROWER_INFO, {}, {}, loanNumber ? { loanNumber } : {})
}

export const getBorrowerLogs = (borrowerSeperator: string, fieldName: string) => {
  return Api.get(API_BORROWER_LOG, {}, { borrowerSeperator, fieldName })
}

export const swapBorrowers = (isReset: boolean) => {
  return Api.post(API_SWAP_BORROWERS, { isReset })
}

export const getBorrowerSignStatus = () => {
  return Api.get(API_LOAN_BORROWER_SIGN_STATUS)
}

export const validateMemberSignApplicationSignature = (data: object) => {
  return Api.post(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_VALIDATE_SIGN_SIGNATURE, data)
}

export const uploadMemberSignApplicationPdf = (id: string | number) => {
  return Api.post(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_UPLOAD_SIGNED_PDF, { id }, {}, { timeout: 150000 })
}

// Additional Members of Loan Application
export const getAdditionalMembers = () => {
  return Api.get(API_LOAN_APPLICATION_ADDITIONAL_MEMBER)
}

export const createAdditionalMember = (data: Record<string, any>) => {
  return Api.post(API_LOAN_APPLICATION_ADDITIONAL_MEMBER, data)
}

export const updateAdditionalMember = (id: string, data: Record<string, any>) => {
  return Api.put(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_ITEM, data, { id })
}

export const deleteAdditionalMember = (id: string) => {
  return Api.delete(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_ITEM, {}, { id })
}

export const pullCreditReportForAdditionalMember = (method: string, id: string | number) => {
  return Api.post(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_PULL_CREDIT_REPORT, { method, id }, {}, { timeout: 600000 })
}

export const sendMemberSignatureRequest = (id: string | number, sendEmail: boolean = true) => {
  return Api.post(API_LOAN_APPLICATION_ADDITIONAL_MEMBER_SEND_SIGNATURE_REQUEST, { id, sendEmail })
}

export const downloadAuditLogCSV = async (data: any, table: string, field: string) => {
  const res: Blob = await Api.post(
    API_BORROWER_LOG_DOWNLOAD_CSV,
    { data, table, field },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`Audit log Details}.csv`, res)
}

export const downloadAuditLogPDF = async (data: any, table: string, field: string) => {
  const res: Blob = await Api.post(
    API_BORROWER_LOG_DOWNLOAD_PDF,
    { data, table, field },
    {},
    {
      responseType: 'blob',
    },
  )

  downloadFile(`Audit log Details - ${table} - ${field}.PDF`, res)
}

export const updateEarnestMoney = (amount: Number) => {
  return Api.put(API_LOAN_APPLICATION_EARNEST_MONEY_UPDATE, { amount })
}

export const getTemporaryUserInfo = (loanNumber?: number) => {
  return Api.get(API_TEMPORARY_USER_INFO, {}, {}, loanNumber ? { loanNumber } : {})
}
