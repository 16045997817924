import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { useEffect, useState } from 'react'
import { deleteAppraisalInformation, getNewAppraisalInformation } from 'services'
import { Button } from 'stories/components'
import { confirm, formatTime, getPrice3decimal } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddAppraisalInformation } from './AddAppraisalInformation'
import { DeliveryMethods } from './constants'

export default function AppraisalInformation({ child = false }) {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [appraiserInfo, setAppraiserInfo] = useState<any>({})
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [editingData, setEditingData] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    setLoanNumber()
    refetch()
  }, [])

  const refetch = () => {
    setLoading(true)
    getNewAppraisalInformation()
      .then(({ data, appraisalReviewInfo }) => {
        setData(data)
        setAppraiserInfo(appraisalReviewInfo)
      })
      .finally(() => setLoading(false))
  }

  const onAdd = () => {
    setEditingData(null)
    setIsOpen(true)
  }

  const onCloseModal = (isNeedRefetch: boolean) => {
    setIsOpen(false)
    setEditingData(null)
    if (isNeedRefetch) refetch()
  }

  const onEdit = (id: number, index: number) => {
    setEditingData(data[index])
    setIsOpen(true)
    setSelectedIndex(index)
  }

  const onDelete = async (id: number, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove this item?
        <br />
        No. {index + 1}
      </div>
    )
    const result = await confirm(content)
    if (!result) return
    setLoading(true)
    await deleteAppraisalInformation(id)
    refetch()
  }

  return (
    <div className={`Appraisal-container ${!child && 'px-2 py-6'}`}>
      {!child && <Overview title="Appraisal Information" />}
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white ${!child && 'shadow1 rounded mb-4 p-4'}`}>
          <LayoutLoading show={loading} />
          <div className="flex gap-4 my-2 justify-between items-center">
            <div className="ml-2 text-[18px] font-variation-settings-600">
              {!child && <span>Appraisal Information</span>}
            </div>
            <div className="">
              <Button onClick={onAdd} className="px-[36px]" disabled={data.length >= 2}>
                Add
              </Button>
            </div>
          </div>
          <div className="overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Company
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Appraisal Value
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ordered Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delivery Method
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created By
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[15px] text-gray-900">
                {data.map((item, index) => {
                  return (
                    <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                      <td className="px-6 py-3">{index + 1}</td>
                      <td className="px-6 py-3">{item.company}</td>
                      <td className="px-6 py-3">{[item.firstName, item.lastName].join(' ')}</td>
                      <td className="px-6 py-3">{getPrice3decimal(item.appraisedValue)}</td>
                      <td className="px-6 py-3">{item.orderedDate}</td>
                      <td className="px-6 py-3">{DeliveryMethods[item.deliveryMethod]}</td>
                      <td className="px-6 py-3">{item.createdBy}</td>
                      <td className="px-6 py-3">{formatTime(item.createdAt)}</td>
                      <td className="px-6 py-3">
                        <div className="flex gap-1">
                          <span
                            className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onDelete(item.id, index)}
                          >
                            <TrashIcon className="w-4 h-4"></TrashIcon>
                          </span>
                          <span
                            className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
                            onClick={() => onEdit(item.id, index)}
                          >
                            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && (
        <AddAppraisalInformation
          appraiserInfo={appraiserInfo}
          onClose={onCloseModal}
          data={editingData}
          index={selectedIndex}
        />
      )}
    </div>
  )
}
