import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getProjects } from 'services'
import { svgLoading } from 'stories/assets'

import { ProjectDetails } from './details'

export interface IProjectTemplate {
  id: number
  name: string
  order: number
}

export function Projects() {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IProjectTemplate>()
  const [data, setData] = useState<IProjectTemplate[]>([])

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    setIsLoading(true)
    getProjects()
      .then((data) => {
        if (data.length === 0) {
          setData([])
        } else {
          const orderedData = data.map((item: any, index: any) => ({ ...item, order: index }))
          setData(orderedData)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const onEdit = (item: IProjectTemplate) => {
    setSelectedItem(item)
  }

  if (selectedItem)
    return (
      <ProjectDetails
        defaults={selectedItem}
        onBack={() => {
          setSelectedItem(undefined)
          refresh()
        }}
        onComplete={() => {
          setSelectedItem(undefined)
          refresh()
        }}
      />
    )

  return (
    <div className="List-container relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Project Templates
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              <th scope="col" className="px-3">
                Title
              </th>
              <th scope="col" className="px-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                  <td scope="row" className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-3">{item.name}</td>
                  <td className="px-3">
                    <span className="flex">
                      <span className="text-shade-blue p-1 hover-shadow1 cursor-pointer" onClick={() => onEdit(item)}>
                        <PencilSquareIcon className="w-4 h-4" />
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
