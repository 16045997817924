import { AUTH_TOKEN_SET } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import jwtDecode from 'jwt-decode'
import { InvestorAuth } from 'pages/Investor/Auth'
import { TemporaryAuth } from 'pages/Temporary'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { LoanType } from 'reducers/loanDetail.reducer'
import { BorrowerHeader, Footer, HeaderSimple } from 'stories/layouts'
import { InvestorHeader } from 'stories/layouts/InvestorHeader'
import { setLoanNumber } from 'utils/setLoanNumber'

export const TemporaryLayout = (props: {
  children: any
  noOverview?: boolean
  title?: string
  requireSign?: boolean
  noHeader?: boolean
  backLink?: string
  noShadow?: boolean
}) => {
  const { children, noOverview, requireSign = false, noShadow = false } = props
  const urlParams: any = useParams()
  let { loanNumber, token } = urlParams
  const [isLoading, setLoading] = useState(true)
  const [isLoaded, setLoaded] = useState(false)
  const [isSigned, setSigned] = useState(false)
  const [noHeader, setNoHeader] = useState(false)
  const [noAuth, setNoAuth] = useState(false)

  const location = useLocation()
  const navigate = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isTemporaryUrl = window.location.href.length > 200

  const isNewSignin = useMemo(() => {
    return [`/borrowerPipelines/signin`, `/investorPipelines/signin`].includes(pathname)
  }, [])

  const hasBackButton = useMemo(() => {
    return pathname.includes('/borrowerPipeline') || urlParams.token == '_'
  }, [pathname, urlParams.token])

  const { registeredToken, loanType } = useSelector((state: any) => {
    return {
      registeredToken: state.auth.token,
      loanType: state.loanDetail.loanType,
    }
  })

  if (token == '_' && registeredToken) token = undefined

  useEffect(() => {
    if (isNewSignin) {
      setLoaded(true)
      setLoading(false)
      return
    }

    if (token === undefined && registeredToken) {
      const payload: any = jwtDecode(registeredToken)
      if (payload.isSigned) {
        setSigned(true)
        setLoaded(true)
        setLoading(false)
        return
      }
    }

    setLoanNumber()

    if (pathname.includes('investorPipeline') || pathname.includes('borrowerPipeline')) {
      setLoaded(true)
      setLoading(false)
      return
    }

    if (!token || (!loanNumber && loanNumber !== undefined)) {
      navigate.push('/')
      return
    }
    const payload: any = jwtDecode(token)
    if (!payload || (loanNumber !== undefined && payload.loanNumber != loanNumber)) {
      navigate.push('/')
      return
    }

    if (payload.noHeader) setNoHeader(true)
    if (payload.noAuth) setNoAuth(true)

    if (!requireSign || payload.noAuth) {
      dispatch({
        type: AUTH_TOKEN_SET,
        token,
        user: payload,
      })
      setSigned(true)
    } else {
      if (payload.isSigned) {
        if (!registeredToken) {
          dispatch({
            type: AUTH_TOKEN_SET,
            token,
            user: payload,
          })
        }
        setSigned(true)
      } else {
        setSigned(false)
      }
    }
    setLoaded(true)
    setLoading(false)
  }, [])

  const onSigned = (newToken: string) => {
    const payload: any = jwtDecode(newToken)

    // if (!payload || (loanNumber !== undefined && payload.loanNumber != loanNumber)) {
    //   navigate.push('/')
    //   return
    // }

    dispatch({
      type: AUTH_TOKEN_SET,
      token: newToken,
      user: payload,
    })
    setSigned(true)

    const { pathname } = location
    let newUrl
    if (pathname.startsWith('/borrowerPipeline') || pathname.startsWith('/investorPipeline'))
      newUrl = `${pathname.replace(`/${token}`, '')}${location.search}`
    else newUrl = `${pathname.replace(token, newToken)}${location.search}`

    navigate.push(newUrl)
  }

  if (!isLoaded) return <LayoutLoading show />

  if (!noAuth && requireSign && !isSigned) {
    if (pathname.includes('/investorPipeline'))
      return (
        <div className="Temporary-Layout-container">
          <HeaderSimple />
          <InvestorAuth token={'signin'} onSigned={onSigned} />
          <Footer />
        </div>
      )
    else
      return (
        <div className="Temporary-Layout-container">
          <HeaderSimple />
          <TemporaryAuth token={'signin'} onSigned={onSigned} />
          <Footer />
        </div>
      )
  }

  return (
    <div className="Temporary-Layout-container">
      {noHeader ? null : isTemporaryUrl ? (
        <HeaderSimple />
      ) : isSigned && requireSign ? (
        pathname.includes('/borrowerPipeline') ? (
          <BorrowerHeader />
        ) : (
          <InvestorHeader />
        )
      ) : (
        loanType === LoanType.DEFAULT && <HeaderSimple />
      )}

      <main className="relative mx-auto main">
        <LayoutLoading show={isLoading} />
        {isLoaded ? (
          noOverview ? (
            <div>{children}</div>
          ) : (
            <div className="px-2 mt-6">
              <Overview
                title={props.title}
                hasBackButton={hasBackButton}
                backLink={props.backLink || `/borrowerPipeline/overview/${loanNumber}`}
                noCompanyTitle={props.title == 'Asset Verification'}
              />
              {noShadow ? (
                children
              ) : (
                <div className="max-w-screen-2xl m-auto mb-4">
                  <div className="relative bg-white shadow1 rounded p-7 min-h-[100px]">{children}</div>
                </div>
              )}
            </div>
          )
        ) : null}
      </main>

      {noHeader
        ? null
        : (loanNumber === undefined || loanType === LoanType.DEFAULT) && (
            <div className="mt-6">
              <Footer />
            </div>
          )}
    </div>
  )
}
