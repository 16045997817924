import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAllPaymentType, submitPaymentTypeTemplate, updatePaymentTypeTemplate } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { PaymentType } from '.'
import { defaultInputs } from './constants'

interface PaymentTypeDetailsProps {
  defaults: PaymentType | null
  onBack: Function
  onComplete: Function
}

export function PaymentTypeDetails(props: PaymentTypeDetailsProps) {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const { defaults = null } = props

  useEffect(() => {
    onUpdateDefaults()
  }, [defaults])

  const onUpdateDefaults = async () => {
    if (!defaults) {
      setInputs(defaultInputs())
      return
    }
    const newStats = cloneDeep(defaultInputs())
    for (const key in newStats) {
      newStats[key].value = (defaults as any)[key]
    }
    setInputs(newStats)
  }

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: number) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      data[key] = value
    }

    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)

    data.cost = removeComma(`${data.cost}`)

    const existingPaymentTypes = await getAllPaymentType()
    const isDuplicate =
      existingPaymentTypes?.length > 0 &&
      existingPaymentTypes.some((item: PaymentType) => {
        if (defaults) {
          return item.id !== defaults.id && item.title.toLowerCase() === data.title.toLowerCase()
        }
        return item.title.toLowerCase() === data.title.toLowerCase()
      })

    if (isDuplicate) {
      toast('Same Payment type already exists.', { type: 'error' })
      setLoading(false)
      return
    }

    const id = defaults ? defaults.id : 0

    if (defaults) {
      await updatePaymentTypeTemplate(id, data).then(() => {
        toast('Payment Type Template updated successfully.', { type: 'success' })
        setLoading(false)
        onBack()
      })
    } else {
      await submitPaymentTypeTemplate(data).then(() => {
        toast('New Payment Type Template added successfully.', { type: 'success' })
        setLoading(false)
        onBack()
      })
    }
  }

  return (
    <div className="email-template-container max-w-[720px]">
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Invoice Fee - {!defaults ? 'Add' : `Update`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-1" /> <p>Return to Invoice Fee Templates</p>
        </div>
      </Button>
      <div className="mb-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]

          return (
            <div
              className={`mb-4 input ${input.span ? 'col-span-1 lg:col-span-4 md:col-span-3 sm:col-span-2' : ''}`}
              key={index}
            >
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'}</>
        </Button>
      </div>
    </div>
  )
}
