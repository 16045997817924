import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { LoanDetail } from 'components/LoanDetail'
import { COMPANY_NAME_FC, COMPANY_TITLE } from 'config'
import { TemporaryLayout } from 'layouts'
import { ISurveyAnswer, ISurveyQuestion, ISurveyTemplates } from 'pages/Admin'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminConfig, loanOverviewSection, submitSurvey } from 'services'
import { Button } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

export enum SurveyType {
  Rating = 0,
  Text = 1,
}

export interface ISurveyItem {
  type: SurveyType
  title: string
  value: string | number
}

export default function SurveyPage() {
  const [isLoading, setLoading] = useState(false)
  const [detail, setDetail] = useState({
    loanNumber: '-',
    entityTitle: 'N/A',
    borrowerName: '-',
    propertyAddress: '-',
  })
  const [isSubmitted, setSubmitted] = useState(false)
  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const [formErrors, setFormErrors] = useState<Record<string, any>>({})
  const [surveyData, setSurveyData] = useState<ISurveyTemplates | null>(null)

  const profile = useSelector((state: any) => state.auth.profile)

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)
    loanOverviewSection()
      .then((data) => {
        if (data.byteproFileName !== 0) data.loanNumber = data.byteproFileName

        setDetail(data)
        dispatch(
          setLoanDetail({
            loanType: data.type,
          }),
        )
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    getAdminConfig('surveyTemplate').then((data) => {
      setSurveyData(data.filter((v: ISurveyQuestion) => !v.disabled))
    })
  }, [])

  const onChange = (key: number, value: any) => {
    let temp = cloneDeep(formValues)
    temp = { ...temp, [`${key}`]: value }
    setFormValues(temp)

    setFormErrors({
      ...formErrors,
      [`${key}`]: '',
    })
  }

  const onSubmit = () => {
    if (!surveyData) return

    const errors: Record<string, any> = {}
    const data: ISurveyAnswer[] = []
    surveyData.forEach((item, index) => {
      if (index == 0) return

      if (formValues[index]) {
        data.push({
          title: item.title,
          type: item.type,
          value: formValues[index],
        })
        return
      }
      errors[index] = 'Required field'
    })
    setFormErrors(errors)
    if (Object.keys(errors).length) return

    const payload = {
      email: profile.email,
      name: profile.name,
      data,
    }

    setLoading(true)
    submitSurvey(payload)
      .then(() => setSubmitted(true))
      .finally(() => setLoading(false))
  }

  const renderInput = (item: ISurveyQuestion, index: number) => {
    const key = `input-${index}`
    return (
      <div key={key} className="mb-4">
        <RenderInput
          input={{
            inputType: item.type == SurveyType.Rating ? 'rating' : 'text',
            title: item.title,
            value: formValues[index],
            error: formErrors[index],
          }}
          Key={key}
          onChange={(_: string, value: any) => onChange(index, value)}
        />
      </div>
    )
  }

  const renderContent = () => {
    if (!surveyData) return null
    const basicText = surveyData[0]
    const ratingQuestions = surveyData
      .map((item, index) => ({ ...item, index }))
      .filter((item, index) => item.type === SurveyType.Rating && index > 0)
    const textQuestions = surveyData
      .map((item, index) => ({ ...item, index }))
      .filter((item, index) => item.type === SurveyType.Text && index > 0)

    return (
      <div className="Overview-container">
        <div className="shadow1 mb-6 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w/full">
          <p className="text-xl font-semibold mb-6">Congratulations on your {COMPANY_TITLE} loan funding!</p>

          <div className="mb-6">
            <LoanDetail
              loan_number={isLoading ? '' : detail.loanNumber}
              entity_title={isLoading ? '' : detail.entityTitle}
              borrower_name={isLoading ? '' : detail.borrowerName}
              property_address={isLoading ? '' : detail.propertyAddress}
              channel={false}
            />
          </div>

          {basicText && (
            <div className="mb-8">
              <p>{basicText.title}</p>
            </div>
          )}

          <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
            <div className="grid grid-cols-1 gap-4">
              {ratingQuestions?.map((item) => renderInput(item, item.index))}
            </div>
            <div className="grid grid-cols-1 gap-4">{textQuestions?.map((item) => renderInput(item, item.index))}</div>
          </div>
          <div className="flex flex-col items-center">
            {!isSubmitted ? (
              <Button className="" onClick={onSubmit} loading={isLoading}>
                Submit
              </Button>
            ) : (
              <>
                <CheckCircleIcon className="text-green-600 w-20 h-20 mb-2" />
                <p>
                  Thank you for your Survey! Your form has been submitted and this will help to improve our{' '}
                  {COMPANY_NAME_FC} Platform.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <TemporaryLayout noOverview={true}>
      <div className="mt-6 relative">
        {renderContent()}
        <LayoutLoading show={isLoading} />
      </div>
    </TemporaryLayout>
  )
}
