import { StripeStatusLabels } from 'pages/Loan/OrderAppraisals/types'
import { Modal, PlainTable } from 'stories/components'
import { formatTime } from 'utils'

export const StripeHistoryModal = ({
  history,
  createdAt,
  onClose,
}: {
  history: Record<string, any>
  createdAt: string
  onClose: Function
}) => {
  return (
    <Modal title="Payment History" isOpen={true} onClose={() => onClose()} titleOkay="" titleCancel="">
      <PlainTable
        header={['No', 'Status', 'Date', 'User']}
        data={[
          ['1', 'Created', formatTime(createdAt)],
          ...Object.keys(history).map((status, index) => [
            index + 2,
            <span className={`text-${StripeStatusLabels[status][0]}-500`}>{StripeStatusLabels[status][1]}</span>,
            formatTime(history[status].date),
            history[status].email,
          ]),
        ]}
      />
    </Modal>
  )
}
