import {
  ArrowPathIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountType, InputType } from 'config'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  deleteGenerateDocumentsHistory,
  getGenerateDocuments,
  getGenerateDocumentUrl,
  submitGenerateDocuments,
  updateGenerateDocuments,
} from 'services'
import { Button } from 'stories/components'
import { formatTime, InputConvert, InputValidate, openAuditLog } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs, DownloadOptions } from './constants'

export interface Order {
  id: number
  createdBy: string
  createdAt: string
  downloadOption: 'zip' | 'pdf'
  fileKey: string
  filter: any
  status: 0 | 1 | -1
  message: string
}

export const LoanGenerateDocuments = () => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [orders, setOrders] = useState<Order[]>([])
  const [detailIDs, setDetailIDs] = useState<Array<number>>([])
  const [action, setAction] = useState('')
  const [inputFieldChanged, setInputFieldChanged] = useState(false)
  const [lender, setLender] = useState<Record<string, any>>({})

  const { auth } = useSelector((state: any) => {
    return {
      auth: state.auth,
    }
  })

  const canDeleteDownloadHistory = auth.profile.accountType === AccountType.ADMIN

  const changeDetailID = (id: number) => {
    let temp: any = cloneDeep(detailIDs)
    if (detailIDs.indexOf(id) === -1) {
      temp.push(id)
    } else {
      temp = []
      detailIDs.map((item) => {
        if (item !== id) temp.push(item)
      })
    }
    setDetailIDs(temp)
  }

  useEffect(() => {
    setLoanNumber()
    setLoading(true)
    getGenerateDocuments(true)
      .then(({ data, list, options, parties }) => {
        let newInputs = defaultInputs()
        if (!data) {
          setInputs(newInputs)
          return
        }
        Object.keys(data).forEach((key) => {
          if (newInputs[key] && data[key] !== undefined) newInputs[key].value = data[key]
        })
        if (options && options.prepaymentTypes) {
          const { prepaymentTypes } = options
          const prepaymentOptions: Record<string, string> = {}
          prepaymentTypes.forEach((v: Record<string, any>) => {
            prepaymentOptions[`${v.id}`] = `${v.value} - ${v.period} months of interest`
          })
          ;(newInputs.prepayment as any).options = prepaymentOptions
        }
        setInputs(newInputs)
        setOrders(list)
        setLender(parties.find((v: any) => v.Type === 'Lender') || {})
      })
      .finally(() => setLoading(false))
  }, [])

  const onUpdateStatus = () => {
    setLoading(true)
    getGenerateDocuments()
      .then(({ list }) => setOrders(list))
      .finally(() => setLoading(false))
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
    setInputFieldChanged(true)
  }

  const onClear = async () => {
    setLoading(true)
    setAction('Clear')
    const res = await deleteGenerateDocumentsHistory()
    if (res.success) {
      setOrders(res.data)
    }
    setLoading(false)
    setAction('')
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    if (!lender?.FirstName) {
      toast('Please fill your Lender Information', { type: 'error' })
      return
    }

    setLoading(true)
    setAction('Submit')
    submitGenerateDocuments(data)
      .then(() => toast('Submitted generating document.', { type: 'info' }))
      .finally(() => {
        onUpdateStatus()
        setAction('')
      })
  }

  const onDownloadGeneratedDocument = async (id: number) => {
    setLoading(true)
    const res = await getGenerateDocumentUrl(id)
    setLoading(false)
    if (!res || !res.url) return
    var windowReference: any = window.open()
    windowReference.location = res.url
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'GenerateDocument',
      field: key,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  const onBlur = (key: string) => {
    if (!inputFieldChanged) return
    setInputFieldChanged(false)

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    data[key] = newInputs[key].value
    newInputs[key].error = InputValidate(newInputs[key])
    if (newInputs[key].error) return

    setLoading(true)

    updateGenerateDocuments(data)
      .then(() => {})
      .finally(() => setLoading(false))
  }

  return (
    <div className="GenerateDocument-container relative">
      <LayoutLoading show={isLoading} />
      <div className="grid gap-4 md:grid-cols-12 grid-cols-1">
        <div className="md:col-span-4">
          <div className="border-b font-semibold">Order Process</div>
          <div className="mt-4">
            {orders.map((item, index: number) => {
              const color = {
                '1': 'green',
                '0': 'gray',
                '-1': 'red',
              }[item.status]
              const showDetail = detailIDs.indexOf(item.id) !== -1
              const details: any = {
                State: item.filter.state,
                'Loan Purpose': item.filter.loanPurpose,
                Occupancy: item.filter.occupancy,
                Entity: item.filter.entity,
                Prepay: item.filter.prepay,
                'Amortization Type': item.filter.amortizationType,
                Construction: item.filter.construction,
                'Interest Calculation': item.filter.interestCalculation,
              }
              return (
                <div className="mb-4" key={index}>
                  <div
                    className={`text-[14.5px] bg-${color}-100 rounded-l-lg border-l-4 border-${color}-500 text-${color}-700 px-2 md:px-4 py-2 md:py-3`}
                    role="alert"
                  >
                    <div className="flex gap-4 justify-between">
                      <div>
                        <div className="flex gap-4">
                          <div className="w-[85px]">Status:</div>
                          <div className="font-bold">
                            {{ '1': 'Completed', '0': 'Pending', '-1': 'Failed' }[item.status]}
                          </div>
                        </div>
                        <div className="flex gap-4">
                          <div className="w-[85px]">Order Date:</div>
                          <div>{formatTime(item.createdAt)}</div>
                        </div>
                        <div className="flex gap-4">
                          <div className="w-[85px]">Order Type:</div>
                          <div>{DownloadOptions[item.downloadOption]}</div>
                        </div>
                      </div>
                      <div className="">
                        <div className="cursor-pointer hover:underline">
                          {
                            {
                              '1': (
                                <span
                                  className="flex gap-1 items-center font-variation-settings-600"
                                  onClick={() => onDownloadGeneratedDocument(item.id)}
                                >
                                  <DocumentArrowDownIcon className="w-4 h-4"></DocumentArrowDownIcon>
                                  Download
                                </span>
                              ),
                              '0': (
                                <span
                                  className="flex gap-1 items-center font-variation-settings-600"
                                  onClick={() => onUpdateStatus()}
                                >
                                  <ArrowPathIcon className="w-4 h-4"></ArrowPathIcon>Update Status
                                </span>
                              ),
                              '-1': <span className="flex gap-1 items-center font-variation-settings-600">Failed</span>,
                            }[item.status]
                          }
                        </div>
                        <div className="mt-4 text-right">
                          Order ID:
                          <span className="ml-1">{item.id}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex gap-4">
                        <div className="w-[85px]">Order By:</div>
                        <div>{item.createdBy}</div>
                      </div>
                      <div
                        className="flex gap-1 items-center font-variation-settings-500 text-[14px] hover:underline cursor-pointer"
                        onClick={() => changeDetailID(item.id)}
                      >
                        Details
                        {!showDetail ? (
                          <ChevronDoubleDownIcon className="w-4 h-4"></ChevronDoubleDownIcon>
                        ) : (
                          <ChevronDoubleUpIcon className="w-4 h-4"></ChevronDoubleUpIcon>
                        )}
                      </div>
                    </div>
                    {showDetail && (
                      <div className="mt-2 pt-2 border-gray-300 border-t">
                        <div className="flex flex-wrap gap-x-4 gap-y-1">
                          {Object.keys(details).map((key, index) => {
                            return (
                              <div className="flex gap-2" key={index}>
                                <div className="italic">{key}:</div>
                                <div className="">{details[key]},</div>
                              </div>
                            )
                          })}
                        </div>
                        {item.message && (
                          <div className="flex gap-2">
                            <div className="italic">Error:</div>
                            <div className="">{item.message}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          {canDeleteDownloadHistory && orders.length > 1 && (
            <div className="mt-4">
              <Button onClick={onClear} color="gray" loading={action === 'Clear'}>
                Clear History
              </Button>
            </div>
          )}
        </div>
        <div className="md:col-span-8">
          <div className="border-b font-semibold mb-4">Details</div>
          <div className="w-full overflow-auto rounded">
            <div className="w-full grid gap-4 md:grid-cols-2 grid-cols-1">
              {Object.keys(inputs).map((key, index) => {
                let input = inputs[key]
                input.history = !input.disabled && key != 'interestType'

                return (
                  <div className={`input md:col-span-${input.span || 1}`} key={index}>
                    <RenderInput
                      input={input}
                      Key={key}
                      onChange={onChange}
                      onBlur={onBlur}
                      showHistory={showHistory}
                    />
                  </div>
                )
              })}
            </div>
            <div className="flex justify-center max-w-[275px] mt-3 mx-auto">
              <Button onClick={onSubmit} loading={action === 'Submit'}>
                Download Documents
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
