import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { updateBorrowerPassword, updateInvestorPassword, updatePassword } from 'services'
import { updateParticipantPassword } from 'services/apis/participants'
import { Input2, Modal } from 'stories/components'

export const ChangePassword = ({
  email,
  isBorrower = false,
  isInvestor = false,
  isParticipant = false,
  ...props
}: {
  userId: number
  email: string
  isOpen: boolean
  lastUpdatedAt: number
  onClose: any
  isBorrower?: boolean
  isInvestor?: boolean
  isParticipant?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const [passwords, setPasswords] = useState({
    new: '',
    confirm: '',
  })
  const [inputErrors, setInputErrors] = useState({
    new: '',
    confirm: '',
  })

  useEffect(() => {
    setIsOpen(props.isOpen)
    setLastUpdatedAt(props.lastUpdatedAt)
  }, [props.lastUpdatedAt])

  const reset = () => {
    setPasswords({
      new: '',
      confirm: '',
    })
    setInputErrors({
      new: '',
      confirm: '',
    })
  }

  const onChange = (key: string, value: string) => {
    const newPwds = Object.assign({}, passwords) as any
    newPwds[key] = value
    setPasswords(newPwds)

    const newInputErrors = Object.assign({}, inputErrors) as any
    newInputErrors[key] = ''
    setInputErrors(newInputErrors)
  }

  const onSubmit = async () => {
    const errors = {
      new: '',
      confirm: '',
    }
    if (!passwords.new) errors.new = 'Required'
    if (!passwords.confirm) errors.confirm = 'Required'
    if (passwords.new && passwords.new != passwords.confirm) errors.confirm = 'Not same as new password'
    if (errors.new || errors.confirm) {
      setInputErrors(errors)
      return
    }

    setLoading(true)
    if (isBorrower) await updateBorrowerPassword(props.userId, passwords.new)
    else if (isInvestor) await updateInvestorPassword(props.userId, passwords.new)
    else if (isParticipant) await updateParticipantPassword(props.userId, passwords.new)
    else await updatePassword(email, passwords.new)
    setLoading(false)
    toast('Password is updated', { type: 'info' })
    reset()
    setLastUpdatedAt(Date.now())
    setIsOpen(false)
    props.onClose()
  }

  const onClose = () => {
    reset()
    setIsOpen(false)
    props.onClose()
  }

  if (!isOpen) return null

  return (
    <form>
      <Modal
        title="Change Password"
        titleOkay="Update Password"
        loading={loading}
        isOpen={isOpen}
        lastUpdatedAt={lastUpdatedAt}
        onClose={onClose}
        onOk={onSubmit}
      >
        <div className="grid gap-x-3 grid-cols-1 w-96">
          <label className="mb-6">{email}</label>
          <Input2
            title="New Password"
            type="password"
            error={inputErrors.new}
            value={passwords.new}
            required
            onChange={(value) => onChange('new', value)}
          />
          <div className="mb-3"></div>
          <Input2
            title="Confirm Password"
            type="password"
            error={inputErrors.confirm}
            value={passwords.confirm}
            required
            onChange={(value) => onChange('confirm', value)}
          />
        </div>
      </Modal>
    </form>
  )
}
