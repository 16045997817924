import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getAdminConfig, setAdminConfig } from 'services'
import { ToggleButton } from 'stories/components'
import { capitalizeLettersSeparatedBySlash } from 'utils'

export const Features = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, boolean>>({})

  const initData = async () => {
    setIsLoading(true)
    const res = await getAdminConfig('features')
    setData(res)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = async (key: string, value: boolean) => {
    const temp = cloneDeep(data)
    temp[key] = value
    setData(temp)
    setIsLoading(true)
    await setAdminConfig('features', temp)
    setIsLoading(false)
  }

  return (
    <div className="relative px-3 py-1.5">
      <LayoutLoading show={isLoading} />

      <div className="text-xl font-bold mb-4">Features</div>
      {Object.keys(data).map((key, index: number) => {
        return (
          <div
            className={`flex justify-between items-center p-2 pl-4 ${index % 2 && 'bg-slate-100'}`}
            key={`${key}-${index}`}
          >
            <div className="flex-1 flex justify-between items-center">
              <p className="text-sm">
                {index + 1}. {capitalizeLettersSeparatedBySlash(key)}
              </p>
              <ToggleButton id={`${key}-${index}`} value={data[key]} onChange={(value) => onChange(key, value)} />
            </div>
          </div>
        )
      })}
    </div>
  )
}
