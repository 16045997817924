import { SET_VENDOR } from 'actions/types'
import cloneDeep from 'clone-deep'

import { createReducer } from '../utils'

export const vendor = createReducer(
  {},
  {
    [SET_VENDOR]: (state: any, payload: any) => {
      let newState = cloneDeep(state)
      newState = {
        ...newState,
        ...payload.data,
      }
      return newState
    },
  },
)
