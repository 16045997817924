import Api from 'services/api'

const API_LOAN_CHANGE_REQUEST = '/loan/loanChangeRequest'
const API_LOAN_STRUCTURE_CHANGE_REQUEST = '/loan/loanChangeRequest/structure'

export const getLoanChangeRequest = () => {
  return Api.get(API_LOAN_CHANGE_REQUEST)
}

export const postLoanChangeRequest = (data: any) => {
  return Api.post(API_LOAN_CHANGE_REQUEST, data)
}

export const getPendingLoanChangeRequest = () => {
  return Api.get(API_LOAN_STRUCTURE_CHANGE_REQUEST)
}

export const cancelLoanStructureChangeRequest = (data: Record<string, any>) => {
  return Api.put(API_LOAN_STRUCTURE_CHANGE_REQUEST, data)
}

export const cancelManuallyChangeRequest = (taskID: number) => {
  return Api.post(`${API_LOAN_CHANGE_REQUEST}/cancelManual`, { taskID })
}
