import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import { Checkbox, Select } from 'stories/components'
import { formatDate, formatDateYMD, renderHeader, thousandSeperator } from 'utils'

import type { IAccountingCategory } from '../AccountingCategories'
import type { IAccountingAccount } from '../Accounts/types'
import { getAccountOptions } from '../Accounts/utils'
import type { IAccounting } from './types'

export const EditableAccountingTable = ({
  baseCategory,
  category,
  accounts,
  data,
  setData,
  showCheck = true,
  sortable = true,
  onSort = () => {},
  orders = { by: '', dir: 1 },
  onDelete,
}: {
  baseCategory: Record<number, string>
  category: IAccountingCategory[]
  accounts: IAccountingAccount[]
  data: IAccounting[]
  setData: Function
  showCheck?: boolean
  sortable?: boolean
  onSort?: Function
  orders?: { by: string; dir: 1 | -1 }
  onDelete?: Function
}) => {
  const accountOptions = useMemo(() => getAccountOptions(accounts), [accounts])

  useEffect(() => {
    const newData = cloneDeep(data)
    newData.forEach((item) => {
      const baseId = category.find((v) => v.id == item.category)?.baseId
      if (!baseId) return
      item.baseId = baseId
    })
    setData(newData)
  }, [])

  const onUpdate = (
    index: number,
    key:
      | 'checked'
      | 'baseId'
      | 'category'
      | 'subCategory'
      | 'date'
      | 'description'
      | 'amount'
      | 'loanNumber'
      | 'accountId',
    value: string | number | boolean,
  ) => {
    value = typeof value == 'string' ? value.trim() : value
    if (key == 'date' && typeof value == 'string') value = moment(value).isValid() ? formatDate(value) : ''
    if (key == 'amount') value = Number(value).toFixed(2)
    if (key == 'loanNumber' && typeof value == 'string') value = Number(value) ? `${parseInt(value)}` : ''

    const newData = cloneDeep(data)
    ;(newData[index] as any)[key] = value
    if (key == 'baseId') newData[index].category = 0
    setData(newData)
  }

  const hasAction = !!onDelete

  return (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-3 py-3 w-5">
            No
          </th>

          {renderHeader({
            title: 'Date',
            index: 1,
            key: 'date',
            className: 'px-3 py-3 w-24',
            sortable,
            sortOrder: orders.by == 'date' ? orders.dir : 0,
            onSort,
          })}

          {renderHeader({
            title: 'Description',
            index: 1,
            key: 'description',
            className: 'px-3 py-3 min-w-24',
            sortable,
            sortOrder: orders.by == 'description' ? orders.dir : 0,
            onSort,
          })}

          {renderHeader({
            title: 'Amount',
            index: 1,
            key: 'amount',
            className: 'px-3 py-3 w-28',
            sortable,
            sortOrder: orders.by == 'amount' ? orders.dir : 0,
            onSort,
          })}

          <th scope="col" className="px-3 py-3 w-28">
            Base
          </th>

          <th scope="col" className="px-3 py-3 w-28">
            <div>
              <div className="border-b w-fit mb-1 border-gray-300">Category</div>
              Sub Category
            </div>
          </th>
          <th scope="col" className="px-3 py-3 w-28">
            Loan Number
          </th>
          <th scope="col" className="px-3 py-3 w-28">
            Account
          </th>
          {hasAction && <th scope="col" className="px-3 py-3"></th>}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const { baseId, category: _category, valid, checked } = item
          const properCategories: Record<number, string> = {}
          const properSubCategories: Record<number, string> = {}
          if (baseId) {
            const categories = category.filter((v) => v.baseId == baseId && v.parentId == 0)
            categories.forEach((v) => (properCategories[v.id] = v.value))
          }
          if (_category) {
            const categories = category.filter((v) => v.baseId == baseId && v.parentId == _category)
            categories.forEach((v) => (properSubCategories[v.id] = v.value))
          }
          let bgColor = 'bg-white'
          if (valid === false) bgColor = 'bg-red-100'
          else if (valid === true) bgColor = 'bg-green-100'
          if (showCheck && checked === false) bgColor = 'bg-gray-100 opacity-50'
          const noTitle = `${index + 1}${item.id > 0 ? ` #${item.id}` : ''}`

          return (
            <tr className={`border-b dark:bg-gray-800 dark:border-gray-700 ${bgColor}`} key={`${item.id}-${index}`}>
              <td scope="row" className="px-3 py-1 font-medium dark:text-white whitespace-nowrap">
                {showCheck ? (
                  <div className="-mb-2">
                    <Checkbox
                      id={`check-${index + 1}`}
                      title={noTitle}
                      checked={item.checked}
                      onChange={(v) => onUpdate(index, 'checked', v)}
                    />
                  </div>
                ) : (
                  <p className="text-gray-900 mt-1 font-semibold">{noTitle}</p>
                )}
              </td>

              {['date', 'description', 'amount'].map((key) => {
                let value = (item as any)[key] || ''
                let content = value
                if (key == 'date') value = (item as any)[key] ? formatDateYMD((item as any)[key]) : ''
                if (key == 'amount') content = thousandSeperator(value)

                return (
                  <td
                    scope="row"
                    className="px-3 font-medium dark:text-white whitespace-nowrap"
                    key={`${item.id}-${key}`}
                  >
                    <PlainInput
                      type={{ date: 'date', description: 'text', amount: 'number' }[key]}
                      value={value}
                      content={content}
                      className={`h-[30px] ${key == 'description' ? 'text-ellipsis overflow-hidden w-48' : 'w-32'}`}
                      onChange={(newValue: string) => onUpdate(index, key as any, newValue)}
                    />
                  </td>
                )
              })}

              <td scope="row" className="px-3 font-medium dark:text-white whitespace-nowrap">
                <Select
                  id={`accounting-base-category-${index}`}
                  size={3}
                  className="-mb-4 bg-white w-32"
                  borderClassName="border-none hover:border"
                  options={baseCategory}
                  value={`${baseId}` || ''}
                  hasDefaultOption
                  defaultOptionText=""
                  onChange={(value) => onUpdate(index, 'baseId', value)}
                />
              </td>

              <td scope="row" className="px-3 font-medium dark:text-white whitespace-nowrap">
                <Select
                  id={`accounting-category-${index}`}
                  size={3}
                  className="-mb-4 bg-white w-52"
                  borderClassName="border-none"
                  options={properCategories}
                  value={`${item.category}` || ''}
                  hasDefaultOption
                  sort
                  defaultOptionText=""
                  onChange={(value) => onUpdate(index, 'category', value)}
                />

                <Select
                  id={`accounting-Sub-category-${index}`}
                  size={3}
                  className="-mb-4 bg-white w-52"
                  borderClassName="border-none"
                  options={properSubCategories}
                  value={`${item.subCategory}` || ''}
                  hasDefaultOption
                  sort
                  defaultOptionText=""
                  onChange={(value) => onUpdate(index, 'subCategory', value)}
                />
              </td>

              <td scope="row" className="px-3 font-medium dark:text-white whitespace-nowrap">
                <PlainInput
                  type="number"
                  value={item.loanNumber || ''}
                  content={item.loanNumber || ''}
                  className="h-[35px] w-28"
                  onChange={(newValue: string) => onUpdate(index, 'loanNumber', newValue)}
                />
              </td>

              <td scope="row" className="px-3 font-medium dark:text-white whitespace-nowrap">
                <Select
                  id={`accounting-account-${index}`}
                  size={3}
                  className="-mb-4 bg-white w-52"
                  borderClassName="border-none"
                  options={accountOptions}
                  value={`${item.accountId}` || ''}
                  hasDefaultOption
                  defaultOptionText=""
                  onChange={(value) => onUpdate(index, 'accountId', value)}
                />
              </td>

              {hasAction && (
                <td className="px-3 py-1">
                  <div className="flex gap-2">
                    {onDelete && (
                      <span className="text-red-800 p-1 hover-shadow1 cursor-pointer" onClick={() => onDelete(item)}>
                        <TrashIcon className="w-4 h-4" />
                      </span>
                    )}
                  </div>
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
