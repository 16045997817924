import { useEffect, useState } from 'react'
import { Checkbox, Modal } from 'stories/components'

export const OverrideCaclModal = (props: any) => {
  const [check, setCheck] = useState('')
  const [ovValue, setOvValue] = useState(props.ovValue.toString())

  const options = props.ovOptions

  useEffect(() => {
    setCheck(props.ovValue.toString().length > 0 ? 'Override' : 'Calc')
  }, [props.ovValue])

  const onClose = () => {
    props.onClose(false)
  }

  const onSubmit = () => {
    props.onClose(true, { ovValue, type: props.type })
  }

  const onChangeCheck = (key: string) => {
    setCheck(key)
    if (key === 'Calc') {
      if (!!options) setOvValue(0)
      else setOvValue('')
    }
  }

  return (
    <Modal
      title={`Override Calc Value${props.title ? ' - ' + props.title : ''}`}
      titleOkay={'Okay'}
      disabled={check === 'Override' && ((!options && ovValue.length === 0) || (!!options && ovValue == 0))}
      isOpen
      onClose={onClose}
      onOk={onSubmit}
      titleCancel=""
    >
      <div className="w-[600px]">
        <div className="flex flex-wrap items-center gap-4">
          <Checkbox
            className={`w-fit`}
            id={'Calc'}
            title={'Use calculated value:'}
            key={'Calc'}
            value={check === 'Calc'}
            onChange={() => onChangeCheck('Calc')}
          />
          <div className="">{props.calcValue}</div>
        </div>
        <div className="flex flex-wrap items-center gap-4 mt-4">
          <Checkbox
            className={`w-fit`}
            id={'Override'}
            title={'Override the calculation with this value:'}
            key={'Override'}
            value={check === 'Override'}
            onChange={() => onChangeCheck('Override')}
          />
          <div className="">
            {!!options && Object.keys(options).length ? (
              <select value={ovValue} onChange={(e) => setOvValue(e.target.value)} disabled={check !== 'Override'}>
                {Object.keys(options).map((key) => (
                  <option key={key} value={key}>
                    {options[key]}
                  </option>
                ))}
              </select>
            ) : (
              <input
                disabled={check !== 'Override'}
                className="px-2 text-left border w-[180px]"
                value={ovValue}
                onChange={(e) => setOvValue(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
