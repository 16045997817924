import { ClockIcon } from '@heroicons/react/24/outline'
import { setLoanDetail } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountType } from 'config'
import { usePermissions } from 'hooks/usePermissions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loanTypeMap } from 'reducers/loanDetail.reducer'
import { updateLoanFields } from 'services'
import { CopyableText, Select } from 'stories/components'
import { openAuditLog } from 'utils'

interface InputProps {
  loan_number: any
  entity_title: string
  borrower_name: string
  property_address: string
  channel?: boolean
}

export function LoanDetail({ loan_number, entity_title, borrower_name, property_address, channel = true }: InputProps) {
  const { auth, loanDetail } = useSelector((state: any) => ({
    auth: state.auth,
    loanDetail: state.loanDetail,
  }))

  const { hasPermission } = usePermissions()
  const seeLog = hasPermission('CAN_VIEW_AUDIT_LOG')

  const isAdmin = auth.profile.accountType === AccountType.ADMIN
  const { isAuthenticated } = auth
  const isNewLoan = loan_number === 'New'

  const [action, setAction] = useState('')
  const dispatch = useDispatch()

  const changeLoanChannel = async (value: string) => {
    if (isNewLoan) return
    const data = {
      type: Number(value),
    }
    setAction('changeLoanChannel')
    await updateLoanFields(data)
    dispatch(setLoanDetail({ loanType: value }))
    setAction('')
  }

  const showHistory = (table: string, field: string, keys: any, map?: any) => {
    if (isNewLoan) return
    const options = {
      table,
      field,
      keys,
      map,
    }
    openAuditLog(options)
  }

  const sizeClass = 'w-4 h-4'

  return (
    <div className="text-[14px] text-left">
      <LayoutLoading show={action === 'changeLoanChannel'} />

      <div className="flex mb-1">
        <div className="w-36">Loan Number:</div>
        <div className="font-bold ml-2">
          <CopyableText>{loan_number}</CopyableText>
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Entity Name:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{entity_title}</CopyableText>
          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() =>
                  showHistory('Borrower', 'entityTitle', { field: 'entityTitle', borrowerSeperator: 'borrower' })
                }
              />
            </span>
          )}
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Borrower Name:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{borrower_name}</CopyableText>
          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() =>
                  showHistory('Borrower', 'firstName', { field: 'firstName', borrowerSeperator: 'borrower' })
                }
              />
            </span>
          )}
        </div>
      </div>

      <div className="flex mb-1">
        <div className="w-36">Property Address:</div>
        <div className="flex items-center ml-2 group">
          <CopyableText>{property_address}</CopyableText>

          {seeLog && (
            <span className="text-gray-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover-shadow1 rounded p-1">
              <ClockIcon
                className={sizeClass}
                onClick={() => showHistory('Loan', 'subjectPropertyAddress', { field: 'subjectPropertyAddress' })}
              />
            </span>
          )}
        </div>
      </div>

      {isAuthenticated && channel && (
        <div className="flex mb-1 text-sm">
          <div className="w-36 mr-2 mt-2">Channel:</div>
          <Select
            id="loan-type"
            title=""
            className="w-52"
            options={loanTypeMap}
            value={loanDetail.loanType}
            onChange={isAdmin ? changeLoanChannel : () => {}}
            history={isAdmin}
            showHistory={() =>
              isAdmin && showHistory('Loan', 'type', { field: 'type', loanNumber: loanDetail.loanNumber }, loanTypeMap)
            }
          />
        </div>
      )}
    </div>
  )
}
