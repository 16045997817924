import { COMPANY_ICON, GOOGLE_MAP_API_KEY } from 'config'
import GoogleMapReact, { Position } from 'google-map-react'
import { useEffect, useMemo, useState } from 'react'
import { getBounds } from 'utils'

type MarkerData = Position & { id?: number; title: string }

const Marker = (props: any) => {
  const handleClick = () => {
    props.onClick && props.onClick()
  }

  return (
    <img
      src={COMPANY_ICON}
      style={{ width: 32, height: 32, marginLeft: -16, marginTop: -32 }}
      className="cursor-pointer"
      onClick={handleClick}
    />
  )
}

export function GoogleMap({ markers = [], onClick = () => {} }: { markers?: MarkerData[]; onClick?: Function }) {
  const [map, setMap] = useState<any>(null)
  const [key, setKey] = useState(0)
  const [showStreet, setShowStreet] = useState(false)
  const [orgMarkers, SetOrgMarkers] = useState<Array<MarkerData>>([])

  const updateBounds = (map: any, markers: MarkerData[]) => {
    if (!markers.length) return
    const bounds = new (window as any).google.maps.LatLngBounds()
    markers.map((marker) => {
      bounds.extend({
        lat: marker.lat,
        lng: marker.lng,
      })
    })
    map.fitBounds(bounds)
  }

  useEffect(() => {
    if (JSON.stringify(markers) !== JSON.stringify(orgMarkers)) {
      SetOrgMarkers(markers)
      setKey(Date.now())
    }
  }, [markers])

  const defaultProps = useMemo(() => {
    if (map) updateBounds(map, markers)

    if (!markers.length)
      return {
        center: {
          lat: -1,
          lng: -1,
        },
        zoom: 4,
      }
    return getBounds(markers)
  }, [markers])

  const renderMarkers = ({ map }: { map: any; maps: any }) => {
    setMap(map)
    updateBounds(map, markers)
  }

  const showStreetView = (marker: MarkerData) => {
    if (!map) return

    // Ensure the map has a StreetViewPanorama object
    const streetViewService = new (window as any).google.maps.StreetViewService()
    const streetViewContainer = document.getElementById('street-view')

    // Check if streetViewContainer is available
    if (!streetViewContainer) {
      console.error('Street View container not found!')
      return
    }

    // Use the street view service to find a panorama near the marker's location
    streetViewService.getPanorama(
      { location: { lat: marker.lat, lng: marker.lng }, radius: 500 }, // Search within 50 meters
      (data: any, status: any) => {
        if (status === (window as any).google.maps.StreetViewStatus.OK) {
          const panorama = new (window as any).google.maps.StreetViewPanorama(streetViewContainer, {
            position: data.location.latLng,
            pov: { heading: 34, pitch: 10 }, // Customize heading and pitch as desired
            visible: true,
          })

          // Attach the street view to the map's streetView instance
          map.setStreetView(panorama)
          setShowStreet(true)
        } else {
          console.error('Street View data not found for this location.')
        }
      },
    )
  }
  return (
    <div className="w-full h-full">
      {/* Map view container */}
      {!showStreet && defaultProps.center.lat !== -1 && (
        <GoogleMapReact
          key={key}
          options={{
            maxZoom: markers.length === 1 ? 12 : undefined,
            mapTypeId: 'roadmap',
            mapTypeControl: true,
            mapTypeControlOptions: {
              mapTypeIds: ['roadmap', 'hybrid'],
            },
          }}
          bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY! }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={renderMarkers}
        >
          {markers.map(({ lat, lng, id, title }, index) => {
            return (
              <Marker
                key={index}
                lat={lat}
                lng={lng}
                text={id}
                tooltip={title}
                onClick={() => {
                  onClick(id)
                  showStreetView({ lat, lng, id, title }) // Show StreetView on click
                }}
              />
            )
          })}
        </GoogleMapReact>
      )}

      {/* Street View container */}
      <div id="street-view" className={`h-full relative ${showStreet ? 'block' : 'hidden'}`}>
        {' '}
        <button
          onClick={() => setShowStreet(false)} // Hide Street View and show map
          className="absolute top-[80px] left-0 z-[1000] bg-black bg-opacity-60 text-white border-none rounded-full px-2 py-1 cursor-pointer text-xs font-bold shadow transition-all duration-150 hover:bg-opacity-80 transform hover:scale-105"
        >
          {`<`} Back to Map
        </button>
      </div>
    </div>
  )
}
