import { ArrowDownTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { COMPANY_DNS } from 'config'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getPurchaseAdviceSellerSignData, openS3Document, signPurchaseAdviceSellerData } from 'services'
import { Button } from 'stories/components'
import { capitalizeFirstLetter, confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultSendSignatureInfos } from './constant'

export default function PurchaseAdviceSellerSign() {
  const urlParams: any = useParams()
  const { token, id } = urlParams
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<Record<string, any>>({})
  const dispatch = useDispatch()
  const [signResult, setSignResult] = useState(false)

  useEffect(() => {
    setLoanNumber()
    const payload: any = jwtDecode(token)

    dispatch({
      type: AUTH_TOKEN_SET,
      token,
      user: payload,
    })
    load()
  }, [])

  const load = async () => {
    setLoanNumber()
    getPurchaseAdviceSellerSignData(id).then((data) => {
      setData(data)
      setLoaded(true)
      setSignResult(!!data.signedPdfKey)
    })
  }

  const onSign = async () => {
    const result = await confirm('Approve Purchase Advice?', { titleYes: 'Yes and Sign' })
    if (!result) return

    setLoading(true)
    signPurchaseAdviceSellerData(id)
      .then(({ signedPdfKey }) => {
        setSignResult(true)
        setData({
          ...data,
          signedPdfKey,
        })
      })
      .finally(() => setLoading(false))
  }

  const onClose = () => {
    window.open(`https://${COMPANY_DNS}`, '_self')
  }

  if (!loaded) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true}>
      <div className="PurchaseAdviceSellerSign-container">
        <div className="px-2 mt-6">
          <Overview title={`Purchase Advice Seller Sign`} hasBackButton={false} />
          <div className="max-w-screen-2xl m-auto">
            <LayoutLoading show={loading} />
            <div className="relative bg-white shadow1 rounded mb-6 p-4">
              <div className={`grid gap-4 md:grid-cols-3 grid-cols-1 mb-3`}>
                {Object.keys(defaultSendSignatureInfos).map((key) => {
                  return (
                    <div className={`input`} key={key}>
                      <RenderInput
                        input={{
                          ...defaultSendSignatureInfos[key],
                          value: data[key],
                          error: '',
                          readOnly: true,
                        }}
                        Key={key}
                        onChange={() => {}}
                      />
                    </div>
                  )
                })}
              </div>
              {!signResult ? (
                <div className="ml-2">
                  <Button link onClick={() => openS3Document(data.unsignedPdfKey)}>
                    <div className="flex gap-2 items-center underline text-gray-900 hover:text-shade-blue">
                      View Unsigned PDF <ArrowDownTrayIcon className="w-4 h-4" />
                    </div>
                  </Button>
                  <div className="mt-4">
                    <Button color="gray" onClick={onSign}>
                      Click here to Sign
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="mx-2 my-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px] flex items-center"
                    role="alert"
                  >
                    <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
                    <span className="ml-1">The Purchase Advice has been successfully signed!</span>
                  </div>
                  <Button link onClick={() => openS3Document(data.signedPdfKey)} className="mx-2">
                    <div className="flex items-center underline hover:text-shade-blue">
                      View Signed PDF <ArrowDownTrayIcon className="w-4 h-4" />
                    </div>
                  </Button>
                  <div className="mt-4 mx-2">
                    <Button color="gray" onClick={onClose}>
                      <>Go to {capitalizeFirstLetter(COMPANY_DNS)}</>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
