import cloneDeep from 'clone-deep'
import { useCallback, useRef } from 'react'
import { DndProvider, DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

interface ISortableItem {
  id: number | string
  data: any
}

const ItemTypes = {
  CARD: 'card',
}

const Card = ({
  id,
  data,
  index,
  moveCard,
  renderItem,
}: {
  id: number | string
  data: any
  index: number
  moveCard: Function
  renderItem: Function
}) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as any).getBoundingClientRect()

      const clientOffset = monitor.getClientOffset()
      if (!clientOffset) return
      if (Math.abs(clientOffset.x - hoverBoundingRect.left) > hoverBoundingRect.width / 1.8) return
      /*
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      if (!clientOffset) return

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }*/
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{ cursor: 'move', opacity }}>
      {renderItem(data, index)}
    </div>
  )
}

export const Sortable = ({
  data,
  setData,
  renderItem,
}: {
  data: ISortableItem[]
  setData: Function
  renderItem: Function
}) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = data[dragIndex]
      const newData = cloneDeep(data)
      newData.splice(dragIndex, 1)
      newData.splice(hoverIndex, 0, dragCard)
      setData(newData)
    },
    [data],
  )

  const renderCard = (item: ISortableItem, index: number) => {
    return (
      <Card
        key={`card-${item.id}`}
        index={index}
        id={item.id}
        data={item.data}
        moveCard={moveCard}
        renderItem={renderItem}
      />
    )
  }

  return <DndProvider backend={HTML5Backend}>{data.map((item, i) => renderCard(item, i))}</DndProvider>
}
