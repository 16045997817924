import { ArrowRightIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { COMPANY_TITLE } from 'config'
import type { IContractorApplication } from 'pages/Loan'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  createContractorApplication,
  deleteContractorApplication,
  getContractorApplications,
  updateContractorApplication,
} from 'services/apis'
import { FormTable } from 'stories/components'
import { setLoanNumber } from 'utils/setLoanNumber'

import { defaultInputs } from './constants'

const ContractorApplications = () => {
  const navigate = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IContractorApplication[]>([])

  const loanNumber = setLoanNumber()

  useEffect(() => {
    getContractorApplications().then((data) => {
      setData(data)
    })
    setLoading(false)
  }, [])

  const onSubmitReferences = (currentId: any, values: Record<string, any>) => {
    setLoading(true)
    return new Promise(async (resolve) => {
      let newData = cloneDeep(data)
      values.name = `${values.firstName} ${values.lastName}`
      values.statusDesc = renderStatus(values as IContractorApplication)

      if (!currentId) {
        const newValues = await createContractorApplication(values)
        values.id = newValues.id
        values.no = newValues.no
        newData.push(values as IContractorApplication)
      } else {
        let no = 0
        newData.map((item, index: number) => {
          if (item.id == currentId) {
            no = item.no
            newData[index] = {
              ...newData[index],
              ...values,
            }
          }
        })
        await updateContractorApplication(no, values)
        values.no = no
      }
      setData(newData)
      setLoading(false)
      resolve(values)
    })
  }

  const onRemoveReferences = async (id: any) => {
    return new Promise(async (resolve) => {
      setLoading(true)
      const temp: IContractorApplication[] = []
      let no = 0
      data.forEach((item) => {
        if (item.id != id) temp.push(item)
        else no = item.no
      })
      await deleteContractorApplication(no)
      setData(temp)
      setLoading(false)
      resolve(true)
    })
  }

  const renderStatus = (item: IContractorApplication) => {
    return `
      <div class="flex flex-wrap gap-4 italic font-bold text-[13px]">
        ${item.lockContractor ? '<div class="text-red-700">Locked</div>' : ''}
        ${item.approveContractor ? '<div class="text-shade-blue">Approved</div>' : ''}
      </div>`
  }

  const renderAdditionalActions = (row: Record<string, any>) => {
    return (
      <div className="flex gap-1">
        <div
          className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded w-fit"
          onClick={() => navigate.push(`/contractor_application/${row.no}/${loanNumber}`)}
        >
          <ArrowRightIcon className="w-4 h-4" />
        </div>
      </div>
    )
  }

  return (
    <div className="ContractorApplications-container px-2 my-6">
      <Overview title="Contractor/Builder Applications" />
      <div className="relative max-w-screen-2xl m-auto md:gap-6">
        <LayoutLoading show={loading} />

        <div className="relative h-fit content-right bg-white p-4 rounded shadow1 mb-4">
          <div
            className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative mb-4 text-[14px]"
            role="alert"
          >
            <div className="font-normal">
              Please provide the following information in order to invite a contractor or builder to apply for vendor
              approval with {COMPANY_TITLE}.
            </div>
          </div>

          <FormTable
            header={[
              { key: 'noLink', title: `Contractor No` },
              {
                key: 'name',
                title: 'Full Name',
              },
              {
                key: 'entityTitle',
                title: 'Company Name',
              },
              { key: 'entityEmail', title: 'Email' },
              { key: 'statusDesc', title: 'Status' },
            ]}
            permission={1}
            inputs={defaultInputs()}
            data={data.map((item) => ({
              ...item,
              noLink: (
                <button
                  className="text-shade-blue py-3 hover:underline cursor-pointer font-bold dark:text-white whitespace-nowrap flex flex-col"
                  onClick={() => navigate.push(`/contractor_application/${item.no}/${loanNumber}`)}
                >
                  <span>{item.no}</span>
                </button>
              ),
              name: `${item.firstName} ${item.lastName}`,
              statusDesc: renderStatus(item),
            }))}
            addText="Add Contractor"
            renderAdditionalActions={renderAdditionalActions}
            onSubmit={(id, values) => onSubmitReferences(id, values)}
            onRemove={(id) => onRemoveReferences(id)}
          />
        </div>
      </div>
    </div>
  )
}

export default ContractorApplications
