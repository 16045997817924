import { ArrowDownTrayIcon, MinusIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { InputType, INVALID_ALL_INPUTS } from 'config'
import jwt from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  downloadFile,
  downloadS3Documents,
  fetchLoanOverviewForDocumentGenie,
  getDocumentGenie,
  getDocumentGeniePdf,
  uploadSignedDocumentGeniePdf,
} from 'services'
import { combinedMonthlyExpense } from 'services/pdfs/utils'
import { Button } from 'stories/components'
import { borrowerFullName, formatDate, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ConfirmSignDialog } from './ConfirmSignDialog'
import { defaultInputs } from './constants'

export const getDefaultInputs = (
  loanNumber: number,
  loan: Record<string, any>,
  borrower: Record<string, any>,
  data: Record<string, any>[],
  isForBroker = false,
) => {
  const defaults = defaultInputs()
  const newInputs: Record<string, Record<string, any>> = {}
  data
    .filter((item) => isForBroker || item.status >= 1)
    .forEach(({ key, signedData, status }) => {
      let defaultInputs: Record<string, any> = defaults[key] as any
      defaultInputs =
        typeof defaultInputs === 'string'
          ? (cloneDeep(defaults[defaultInputs]) as Record<string, any>)
          : cloneDeep(defaultInputs)

      const inputIdentity = typeof defaultInputs === 'string' ? defaultInputs : key
      const borrowerName = borrowerFullName(borrower.borrower)

      if (
        [
          'after_renovation',
          'non_renovation',
          'letter_renovation',
          'cash_out_non_renovation',
          'one_time_close_letter',
          'funds_contribution',
          'purpose_letter',
          'purchase_price_loe',
        ].includes(inputIdentity)
      ) {
        defaultInputs.borrowerName.value = borrowerName
        defaultInputs.borrowerAddress.value = borrower.borrower.presentAddress
        defaultInputs.propertyAddress.value = loan.subjectPropertyAddress
        if (loan.includeCoborrower) {
          defaultInputs.coBorrowerName.value = borrowerFullName(borrower.coBorrower)
          defaultInputs.coBorrowerAddress.value = borrower.coBorrower.presentAddress
        } else {
          defaultInputs.coBorrowerName.required = false
          defaultInputs.coBorrowerAddress.required = false
          defaultInputs.coBorrowerName.disabled = true
          defaultInputs.coBorrowerAddress.disabled = true
        }
        if (defaultInputs.appraisedValue) {
          defaultInputs.appraisedValue.value = loan.asIsValue
        }
        if (defaultInputs.purchasePrice) {
          defaultInputs.purchasePrice.value = loan.propertyPurchasePrice
        }
      }

      if (inputIdentity === 'entity_resolution') {
        defaultInputs.propertyAddress.value = loan.subjectPropertyAddress
      }

      if (inputIdentity === 'tenant_estopple') {
        defaultInputs.propertyAddress.value = loan.subjectPropertyAddress
      }

      if (inputIdentity === 'ach_form') {
        defaultInputs.name.value = borrowerName
        defaultInputs.accountLoanNumber.value = loanNumber
        defaultInputs.email.value = borrower.borrower.email
        defaultInputs.phoneNumber.value = borrower.borrower.phone
        defaultInputs.borrowerAddress.value = borrower.borrower.presentAddress
        defaultInputs.propertyAddress.value = loan.subjectPropertyAddress
      }
      if (inputIdentity === 'fci_form') {
        defaultInputs.name.value = borrowerName
        defaultInputs.accountLoanNumber.value = loanNumber
        defaultInputs.email.value = borrower.borrower.email
        defaultInputs.phoneNumber.value = borrower.borrower.phone
        defaultInputs.address.value = borrower.borrower.presentAddress
      }
      if (['legal_entity', 'stock_ledger'].includes(inputIdentity)) {
        defaultInputs.borrowerName.value = borrowerName
        if (defaultInputs.entityName) {
          defaultInputs.entityName.value = borrower.borrower.entityTitle
        }
        if (defaultInputs.entityAddress) {
          defaultInputs.entityAddress.value = borrower.borrower.entityAddress
        }
      }

      if (defaultInputs.entityTakingTitle) {
        defaultInputs.entityTakingTitle.value = borrower.borrower.entityTitle
      }

      if (signedData) {
        Object.keys(signedData).forEach((key) => {
          if (defaultInputs[key]) defaultInputs[key].value = signedData[key]
        })
      }
      if (!isForBroker && status == 2) {
        Object.keys(defaultInputs).forEach((key) => {
          defaultInputs[key].disabled = true
        })
      }

      if (inputIdentity === 'fci_form') {
        const pitis = combinedMonthlyExpense(loan, { rate: loan.rate, type: loan.pricingType })
        defaultInputs.monthlyPaymentAmount.value = pitis['sum']
        let { closingDate } = loan
        if (closingDate === '') closingDate = 'now'
        closingDate = formatDate(closingDate)
        let month: any = Number(closingDate.substring(0, 2))
        let year = Number(closingDate.substring(6, 10))
        defaultInputs.startDateYear.options = [year, year + 1, year + 2]
        month += 2
        if (month > 12) {
          year += 1
          month -= 12
        }
        defaultInputs.startDateYear.value = year
        defaultInputs.startDateMonth.value = month
      }

      newInputs[key] = defaultInputs
    })
  return newInputs
}

export default function CustomSignDocumentGenie() {
  const urlParams: any = useParams()
  const { loanNumber, token } = urlParams
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>[]>([])
  const [currentOpenedItem, setCurrentItem] = useState('')
  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({})
  const [isShowConfirmSign, setShowConfirmSign] = useState(false)
  const [overview, setOverview] = useState<Record<string, Record<string, any>> | null>(null)
  const dispatch = useDispatch()
  const navigate = useHistory()

  const loan = useMemo(() => (overview ? overview.loan : {}), [overview])
  const borrower = useMemo(() => (overview ? overview.borrower : {}), [overview])

  const { profile } = useSelector((state: any) => ({
    profile: state.auth.profile,
  }))

  useEffect(() => {
    if (!profile.id) return
    const payload: any = jwt(token)

    dispatch({
      type: AUTH_TOKEN_SET,
      token,
      user: payload,
    })
    loadDocuments()
  }, [profile])

  useEffect(() => {
    if (!overview) return
    const inputs = getDefaultInputs(loanNumber, loan, borrower, data)
    setInputs(inputs)
  }, [overview])

  const loadDocuments = async () => {
    const key = navigate.location.search.replace('?key=', '')
    setLoanNumber()
    setLoaded(false)
    const data: Record<string, any>[] = await getDocumentGenie()
    setData(data)
    key && setCurrentItem(key)

    const overview = await fetchLoanOverviewForDocumentGenie()
    setOverview(overview)
    setLoaded(true)
  }

  if (!loaded) return <LayoutLoading show={true} />

  const onClickItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, key: string) => {
    if (e.isDefaultPrevented()) return

    setCurrentItem(currentOpenedItem === key ? '' : key)
  }

  const getStatusColor = (status: number) => {
    switch (status) {
      case 0:
        return ''
      case 1:
        return 'text-red-500 font-variation-settings-600'
      case 2:
        return 'text-shade-blue font-variation-settings-600'
      default:
        return ''
    }
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[currentOpenedItem][key], value)
    newInputs[currentOpenedItem][key].value = value
    newInputs[currentOpenedItem][key].error = InputValidate(newInputs[currentOpenedItem][key])
    setInputs(newInputs)
  }

  const onSignDocument = () => {
    if (!currentOpenedItem) return

    const newInputs = cloneDeep(inputs)
    const selectedInputs = newInputs[currentOpenedItem]
    const values: Record<string, any> = {}
    let hasError = false
    Object.keys(selectedInputs).forEach((key) => {
      selectedInputs[key].error = InputValidate(selectedInputs[key])
      const { value, error } = selectedInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) {
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return
    }

    setShowConfirmSign(true)
  }

  const onDownloadUnsignedPdf = async () => {
    const selectedInputs = inputs[currentOpenedItem]
    const values: Record<string, any> = {}
    Object.keys(selectedInputs).forEach((key) => {
      const { value } = selectedInputs[key]
      values[key] = value
    })
    const currentItem = data.find((item) => item.key === currentOpenedItem)
    if (!currentItem) return
    const pdfData: Blob = await getDocumentGeniePdf({
      pdfKey: currentOpenedItem,
      title: currentItem.title,
      values,
      loan,
    })
    downloadFile(`${currentItem.title}-Unsigned.pdf`, pdfData)
  }

  const onConfirmSignPdf = async () => {
    const selectedInputs = inputs[currentOpenedItem]
    const values: Record<string, any> = {}
    Object.keys(selectedInputs).forEach((key) => {
      const { value } = selectedInputs[key]
      values[key] = value
    })
    const currentItem = data.find((item) => item.key === currentOpenedItem)

    const pdfData = {
      pdfKey: currentOpenedItem,
      title: currentItem?.title,
      values,
      loan,
    }

    let json: any[] = []
    if (
      [
        'one_time_close_letter',
        'after_renovation',
        'non_renovation',
        'letter_renovation',
        'cash_out_non_renovation',
        'funds_contribution',
        'purpose_letter',
        'purchase_price_loe',
      ].indexOf(currentOpenedItem) !== -1
    ) {
      json = [
        {
          sign: values.borrowerName,
          email: borrower.borrower.email,
        },
        {
          sign: values.coBorrowerName,
          email: borrower.coBorrower.email,
        },
      ]
    }
    if ('ach_form' === currentOpenedItem) {
      json = [
        {
          sign: values.name,
          email: values.email,
        },
      ]
    }
    if ('fci_form' === currentOpenedItem) {
      json = [
        {
          sign: values.name,
          email: values.email,
        },
      ]
    }
    if ('legal_entity' === currentOpenedItem) {
      json = [
        {
          sign: values.borrowerName,
          email: borrower.borrower.email,
        },
      ]
    }

    if ('tenant_estopple' === currentOpenedItem) {
      json = [
        {
          sign: values.tenantName,
          email: values.tenantEmail,
        },
        {
          sign: [borrower.borrower.firstName, borrower.borrower.middleName, borrower.borrower.lastName].join(' '),
          email: borrower.borrower.email,
        },
      ]
    }

    if ('stock_ledger' === currentOpenedItem) {
      json = [
        {
          sign: values.borrowerName,
          email: borrower.borrower.email,
        },
      ]
    }

    if ('entity_resolution' === currentOpenedItem) {
      const items = values.individualMemberList
      for (let i = 0; i < items.length; i += 1) {
        json.push({
          sign: items[i].name,
          email: items[i].email,
        })
      }
    }

    const { signedPdfKey } = await uploadSignedDocumentGeniePdf({
      pdfKey: currentOpenedItem,
      pdfData: pdfData,
      data: json,
      values,
    })

    Object.keys(selectedInputs).forEach((key) => {
      selectedInputs[key].disabled = true
    })
    const newInputs = cloneDeep(inputs)
    newInputs[currentOpenedItem] = selectedInputs
    setInputs(newInputs)

    const index = data.findIndex((item) => item.key === currentOpenedItem)
    if (index === -1) return

    const newData = cloneDeep(data)
    newData[index] = {
      ...newData[index],
      status: 2,
      signedDate: formatDate(),
      signedPdfKey,
      signedData: values,
    }
    setData(newData)
  }

  const onDownloadPdf = async (signedPdfKey: string) => {
    setLoading(true)
    const res = await downloadS3Documents(signedPdfKey)
    var windowReference: any = window.open()
    windowReference.location = res.url
    setLoading(false)
  }

  const onDownloadSignedPdf = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, signedPdfKey: string) => {
    e.preventDefault()
    onDownloadPdf(signedPdfKey)
  }

  const renderDocument = (key: string) => {
    if (!inputs[key]) return null

    const currentInputs = inputs[currentOpenedItem]

    const currentItem = data.find((item) => item.key === currentOpenedItem)
    if (!currentItem) return

    const { status, signedPdfKey } = currentItem

    return (
      <div className="border border-gray-200 p-4 text-center">
        <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-4 text-left">
          {Object.keys(currentInputs).map((key, index) => {
            let input = currentInputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
        <div className="flex items-center gap-2 justify-center">
          {status == 2 && (
            <Button link onClick={() => onDownloadPdf(signedPdfKey)}>
              <div className="flex items-center gap-2 text-shade-blue">
                View Your Signed Document in PDF
                <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
              </div>
            </Button>
          )}
          <Button color={'gray'} onClick={onSignDocument} disabled={status === 2}>
            <div className="flex flex-wrap gap-2 items-center">
              <span>Click Here to Sign this Document</span>
              <PencilIcon className="w-4 h-4"></PencilIcon>
            </div>
          </Button>
        </div>
      </div>
    )
  }

  const renderTotalStatus = () => {
    let pending = 0
    data.map((item) => {
      if (item.status === 1) pending += 1
    })
    if (pending > 0) {
      return (
        <div
          className="mt-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]"
          role="alert"
        >
          There are {pending} documents you need to sign!
        </div>
      )
    }
    return null
  }

  return (
    <TemporaryLayout noOverview={true} requireSign>
      <div className="CustomSignDocument-container">
        <div className="px-2 mt-6">
          <Overview title="Document Genie" hasBackButton={false} />
          <div className="max-w-screen-2xl m-auto">
            <div className="relative bg-white shadow1 rounded mb-6 md:p-4 p-2">
              <LayoutLoading show={loading} />
              {renderTotalStatus()}
              <div className="overflow-auto mb-6">
                {data.map((item, index) => {
                  const { key, title, status, signedPdfKey } = item
                  if (status == 0) return null
                  const selected = currentOpenedItem === key

                  return (
                    <div className="my-3 md:px-4 px-2" key={key}>
                      <h2>
                        <button
                          type="button"
                          className={`${
                            selected ? 'bg-shade-blue text-white' : 'hover:text-gray-900 hover:bg-gray-100'
                          } flex justify-between items-center p-5 w-full font-medium text-left text-gray-900 rounded-t-xl border border-gray-200 focus:ring-4 focus:ring-gray-200 hover:ring-4 hover:ring-gray-200`}
                          onClick={(e) => onClickItem(e, key)}
                        >
                          <span className="flex-1">
                            {index + 1}. {title}
                          </span>
                          <span
                            className={`${selected ? 'text-white' : ''} pr-2 text-[14px] ${getStatusColor(status)}`}
                          >
                            {status === 2 ? (
                              <span className="flex items-center gap-2">
                                <span
                                  className="p-1 hover-shadow1 rounded"
                                  onClick={(e) => onDownloadSignedPdf(e, signedPdfKey)}
                                >
                                  <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
                                </span>
                                <span>Signed</span>
                              </span>
                            ) : (
                              'Pending'
                            )}
                          </span>
                          <span className="">
                            {selected ? (
                              <span className="">
                                <MinusIcon className="w-5 h-5 shrink-0" />
                              </span>
                            ) : (
                              <PlusIcon className="w-5 h-5 shrink-0 hover:text-gray-900" />
                            )}
                          </span>
                        </button>
                      </h2>
                      {selected && <div className="shadow">{renderDocument(key)}</div>}
                    </div>
                  )
                })}
              </div>

              {isShowConfirmSign ? (
                <ConfirmSignDialog
                  onDownloadPdf={onDownloadUnsignedPdf}
                  onConfirm={onConfirmSignPdf}
                  onClose={() => setShowConfirmSign(false)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
