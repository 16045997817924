import { ArrowDownTrayIcon, ArrowTrendingUpIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import type { InputType } from 'config'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  downloadFile,
  downloadS3Documents,
  fetchLoanOverviewForDocumentGenie,
  getDocumentGenie,
  getDocumentGeniePdf,
  sendDocumentSignRequest,
} from 'services'
import { Button } from 'stories/components'
import { confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { getDefaultInputs } from './CustomSignDocumentGenie'

export * from './CustomSignDocumentGenie'

const DocumentStatus: Record<number, string> = {
  0: '-',
  1: 'In Progress',
  2: 'Signed',
}

export default function DocumentGenie() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>[]>([])
  const [currentOpenedItem, setCurrentItem] = useState('')
  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({})
  const [overview, setOverview] = useState<Record<string, Record<string, any>> | null>(null)

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    refetch()
    getOverviewForDocumentGenie()
  }, [])

  const loan = useMemo(() => (overview ? overview.loan : {}), [overview])
  const borrower = useMemo(() => (overview ? overview.borrower : {}), [overview])

  const getOverviewForDocumentGenie = async () => {
    const overview = await fetchLoanOverviewForDocumentGenie()
    setOverview(overview)
  }

  useEffect(() => {
    if (loan.no) {
      const inputs = getDefaultInputs(loan.no, loan, borrower, data, true)
      setInputs(inputs)
    }
  }, [loan, borrower, data])

  const refetch = () => {
    getDocumentGenie()
      .then((data) => {
        setData(data)
      })
      .finally(() => setLoading(false))
  }

  const getStatusColor = (status: number) => {
    switch (status) {
      case 0:
        return ''
      case 1:
        return 'text-red-500 font-variation-settings-600'
      case 2:
        return 'text-shade-blue font-variation-settings-600'
      default:
        return ''
    }
  }

  const onSend = async (docKey: string, status: number) => {
    if (status === 2) {
      const result = await confirm('Current sign will be removed, Are you sure?')
      if (!result) return
    }

    const newInputs = cloneDeep(inputs)
    const selectedInputs = newInputs[currentOpenedItem]
    const values: Record<string, any> = {}
    // let hasError = false
    Object.keys(selectedInputs).forEach((key) => {
      selectedInputs[key].error = InputValidate(selectedInputs[key])
      const {
        value,
        // error
      } = selectedInputs[key]
      // if (error) hasError = true
      values[key] = value
    })
    // setInputs(newInputs)
    // if (hasError) {
    //   toast(INVALID_ALL_INPUTS, { type: 'error' })
    //   return
    // }

    setLoading(true)
    sendDocumentSignRequest(docKey, values)
      .then(refetch)
      .then(() => toast('Sent Sign Request successfully.', { type: 'success' }))
      .catch(() => setLoading(false))
  }

  const onOpen = (signedPdfKey: string) => {
    downloadS3Documents(signedPdfKey).then((res: any) => {
      var windowReference: any = window.open()
      windowReference.location = res.url
    })
  }

  const onDownloadUnsignedPdf = async () => {
    const selectedInputs = inputs[currentOpenedItem]
    const values: Record<string, any> = {}
    Object.keys(selectedInputs).forEach((key) => {
      const { value } = selectedInputs[key]
      values[key] = value
    })
    const currentItem = data.find((item) => item.key === currentOpenedItem)
    if (!currentItem) return

    const pdfData: Blob = await getDocumentGeniePdf({
      pdfKey: currentOpenedItem,
      title: currentItem.title,
      values,
      loan,
    })
    downloadFile(`${currentItem.title}-Unsigned.pdf`, pdfData)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[currentOpenedItem][key], value)
    newInputs[currentOpenedItem][key].value = value
    newInputs[currentOpenedItem][key].error = InputValidate(newInputs[currentOpenedItem][key])
    setInputs(newInputs)
  }

  const renderForm = (key: string, bgClass: string) => {
    if (!inputs[key]) return null

    const currentInputs = inputs[currentOpenedItem]

    const currentItem = data.find((item) => item.key === currentOpenedItem)
    if (!currentItem) return

    const { status } = currentItem

    return (
      <tr className={`${bgClass}`}>
        <td colSpan={5} className="md:p-4 p-2">
          <div className="bg-white border border-gray-200 p-4 text-center">
            <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-4 text-left">
              {Object.keys(currentInputs).map((key, index) => {
                let input = currentInputs[key]
                return (
                  <div className={`input md:col-span-${input.span || 1}`} key={index}>
                    <RenderInput input={input} Key={key} onChange={onChange} />
                  </div>
                )
              })}
            </div>
            <div className="flex flex-wrap items-center gap-4 justify-center">
              {status != 2 && (
                <Button link onClick={() => onDownloadUnsignedPdf()}>
                  <div className="flex items-center gap-1 text-shade-blue">
                    View Unsigned Document in PDF
                    <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
                  </div>
                </Button>
              )}
              <Button color={'gray'} onClick={() => onSend(key, status)}>
                <div className="flex items-center flex-wrap gap-2">
                  <span>Save and Send Sign Request</span>
                  <ArrowTrendingUpIcon className="w-4 h-4"></ArrowTrendingUpIcon>
                </div>
              </Button>
              <Button link onClick={() => setCurrentItem('')}>
                <div className="flex items-center gap-1 text-red-700">Close</div>
              </Button>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <div className="OrderAppraisals-container px-2">
      <Overview title="Document Genie" />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-6 p-4">
          <LayoutLoading show={loading} />
          <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Document
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Requested Date/By
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Signed Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-[15px] text-gray-900">
                {data.map((item, index) => {
                  const { key, title, status, requestedBy, requestedDate, signedPdfKey, signedDate } = item
                  const isSelected = currentOpenedItem == key
                  const bgClass = index % 2 ? 'bg-gray-50' : ''
                  return (
                    <Fragment key={index}>
                      <tr className={`${bgClass}`}>
                        <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                          {index + 1}. {title}
                        </td>
                        <td className={`px-6 py-4 ${getStatusColor(status)}`}>
                          <span className="flex items-center gap-2 text-[14px]">
                            {DocumentStatus[status]}
                            {status === 2 && (
                              <button
                                className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center text-shade-blue"
                                onClick={() => onOpen(signedPdfKey)}
                              >
                                <ArrowDownTrayIcon className="w-4 h-4" />
                              </button>
                            )}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          {requestedDate}
                          <br />
                          <span className="text-[14px]">{requestedBy}</span>
                        </td>
                        <td className="px-6 py-4">{signedDate}</td>
                        <td className="px-6 py-4">
                          <div className="flex gap-2 items-center">
                            <button
                              className="p-2 cursor-pointer shadow hover-shadow1 rounded inline-block"
                              onClick={() => setCurrentItem(isSelected ? '' : key)}
                            >
                              {isSelected ? (
                                <div className="flex flex-wrap items-center text-red-700">
                                  <span className="mx-2">Close</span> <ChevronDownIcon className="w-4 h-4" />
                                </div>
                              ) : (
                                <div className="flex items-center">
                                  <span className="mx-2">Send Sign Request</span>{' '}
                                  <ChevronRightIcon className="w-4 h-4" />
                                </div>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {isSelected && renderForm(key, bgClass)}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
