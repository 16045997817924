import { companyName } from 'config'
import { useState } from 'react'
import { signInInvestorUser } from 'services'
import { Button, Input2 } from 'stories/components'
import { useTitle } from 'utils/pageTitle'
const cloneDeep = require('clone-deep')

const defaultInputs = {
  email: '',
  password: '',
}

export function InvestorLogin({
  token,
  onSigned,
  onForgotPwd,
}: {
  token: string
  onSigned: Function
  onForgotPwd: Function
}) {
  useTitle(`Login - ${companyName}`)

  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    if (!inputStates.password) newErrors.password = 'Required'
    setErrors(newErrors)

    if (!inputStates.email || !inputStates.password) return

    setLoading(true)

    signInInvestorUser({
      email: inputStates.email,
      password: inputStates.password,
      token,
    })
      .then(({ token, requireReset }) => {
        onSigned(token, requireReset)
      })
      .finally(() => setLoading(false))
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  const keyPress = (e: any) => {
    if (e.charCode === 13) {
      onSubmit()
    }
  }

  return (
    <div className="home-container sm:text-center lg:text-left w-full block mt-0 relative bg-stone-100 md:py-20">
      <div className="max-w-screen-2xl m-auto lg:pt-10 md:pt-5 sm:pt-2 pb-10 px-2 pt-4">
        <div className="bg-white rounded lg:max-w-xl max-w-full pl-6 pr-6 pt-8 pb-6 relative mx-auto">
          <p className="text-stone-800 text-4xl font-bold mb-3">Login</p>

          <div className="mb-5 grid grid-cols-3 gap-4">
            <div className="h-1 bg-shade-blue w-full" />
            <div className="h-1 bg-stone-200 w-full" />
            <div className="h-1 bg-stone-200 w-full" />
          </div>

          <form>
            <Input2
              type="email"
              title="Email"
              value={inputStates.email}
              required
              error={errors.email ? 'Please provide a valid email address.' : ''}
              onChange={(value) => onChange('email', value)}
              onKeyPress={(e) => keyPress(e)}
            />
            <div className="mb-5"></div>
            <Input2
              type="password"
              title="Password"
              value={inputStates.password}
              required
              error={errors.password ? 'Please provide a valid password.' : ''}
              onChange={(value) => onChange('password', value)}
              onKeyPress={(e) => keyPress(e)}
            />

            <div className="my-7"></div>

            <Button className="mt-1" color="sky" full bold onClick={onSubmit} loading={isLoading}>
              Sign In
            </Button>

            <p
              className="block mt-3 hover:text-gray-900 hover:underline text-gray-500 cursor-pointer"
              onClick={() => onForgotPwd()}
            >
              Forgot your password?
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}
