import {
  ArrowDownCircleIcon,
  BuildingOffice2Icon,
  CheckIcon,
  ChevronRightIcon,
  FolderOpenIcon,
} from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET, logout } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { companyName, INVALID_REQUEST } from 'config'
import jwt from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { ContractorDocuments } from 'pages/Loan'
import ContractorApplication from 'pages/Loan/ContractorApplication/index'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { validateContractorSignApp } from 'services/apis'
import { useQuery, useTitle } from 'utils'
import { setLoanNumber } from 'utils/setLoanNumber'

export default function ContractorSignApplication() {
  useTitle(`${companyName} - Contractor App`)
  const urlParams: any = useParams()
  const { loanNumber, no, urlSignature, token } = urlParams
  const registeredToken = useSelector((state: any) => state.auth.token)
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState(false)
  const [applicationComplete, setApplicationComplete] = useState(false)
  const [defaultMenu, setDefaultMenu] = useState(useQuery().get('menu'))
  const [menu, setMenu] = useState('application')
  const dispatch = useDispatch()
  const navigate = useHistory()

  useEffect(() => {
    if (!token || !loanNumber || !urlSignature) {
      navigate.push('/')
      return
    }
    const payload: any = jwt(token)
    if (!payload || payload.loanNumber != loanNumber) {
      navigate.push('/')
      return
    }
    setLoading(true)
    setValidated(false)
    setLoanNumber()
    validateContractorSignApp(no, {
      loanNumber,
      urlSignature,
      token,
    }).then(async ({ success }) => {
      if (!success) {
        toast(INVALID_REQUEST, { type: 'error' })
        navigate.push('/')
        dispatch(logout())
        return
      }
      if (!registeredToken) {
        dispatch({
          type: AUTH_TOKEN_SET,
          token,
          user: payload,
        })
      }
      setValidated(true)
      setLoading(false)
    })
  }, [token, loanNumber, urlSignature])

  useEffect(() => {
    if (defaultMenu === 'documents' && applicationComplete) {
      setMenu(defaultMenu)
      setDefaultMenu('')
    }
  }, [applicationComplete, defaultMenu])

  const renderContent = () => {
    // if (loading) return null
    if (menu === 'application') {
      return (
        <ContractorApplication
          contractor={true}
          appStatusChange={(status: boolean) => setApplicationComplete(status)}
          changeMenu={() => setMenu('documents')}
        />
      )
    }
    if (menu === 'documents') {
      return (
        <div className="shadow1 mb-8 mt-5 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 text-left w-full">
          <ContractorDocuments setLoading={setLoading} contractorURL={true} />
        </div>
      )
    }
  }

  if (!validated) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true}>
      <div className="mt-6">
        <div className="mx-2">
          <Overview hasBackButton={false} title={`Contractor/Builder Application No.${no}`} />
          <div className="col-span-12 flex flex-wrap items-center gap-4 justify-center -mb-3">
            <div
              className={`rounded-t text-gray-900 mb-4 shadow1 px-5 py-2.5 border-b-4 hover:border-sky-500 cursor-pointer flex items-center ${
                menu === 'application' && 'border-shade-blue'
              }`}
              onClick={() => {
                setMenu('application')
              }}
            >
              {applicationComplete && (
                <span className="mr-2 text-shade-blue">
                  <CheckIcon className="w-6 h-6"></CheckIcon>
                </span>
              )}
              <span className="font-semibold">1. Application</span>
              <span className="ml-2">
                <BuildingOffice2Icon className="w-5 h-5"></BuildingOffice2Icon>
              </span>
            </div>
            <span className="mb-4">
              <ChevronRightIcon className="w-6 h-6"></ChevronRightIcon>
            </span>
            <div
              className={`rounded-t shadow1 mb-4 px-5 py-2.5 cursor-pointer flex items-center border-b-4 ${
                !applicationComplete ? 'text-gray-400 cursor-not-allowed' : 'text-gray-900 hover:border-sky-500'
              } ${menu === 'documents' && 'border-shade-blue'}`}
              onClick={() => {
                if (applicationComplete) setMenu('documents')
              }}
            >
              {applicationComplete && (
                <svg className="animate-bounce w-6 h-6 mr-2 text-shade-blue">
                  <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
                </svg>
              )}
              <span className="font-semibold">2. Documents Upload</span>
              <span className="ml-2">
                <FolderOpenIcon className="w-5 h-5"></FolderOpenIcon>
              </span>
            </div>
          </div>
          {applicationComplete && menu === 'application' && (
            <div className="max-w-screen-2xl m-auto mb-6 mt-4">
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-[15px]"
                role="alert"
              >
                <div className="text-[16px] flex">
                  You've Completed and Signed the Contractor Application!{' '}
                  <div
                    className="flex items-center hover:underline font-semibold cursor-pointer ml-2"
                    onClick={() => {
                      setMenu('documents')
                    }}
                  >
                    <span>
                      <svg className="animate-bounce w-6 h-6 mr-1">
                        <ArrowDownCircleIcon className="w-5 h-5"></ArrowDownCircleIcon>
                      </svg>
                    </span>
                    <span className="mr-1">Click Here</span>
                  </div>{' '}
                  to upload your Documents.
                </div>
              </div>
            </div>
          )}
        </div>
        {renderContent()}
        <LayoutLoading show={loading} />
      </div>
    </TemporaryLayout>
  )
}
