import { BuildingLibraryIcon, CreditCardIcon, EyeIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import type { InputType } from 'config/input.type.constants'
import jwtDecode from 'jwt-decode'
import { TemporaryLayout } from 'layouts'
import { FeasibilityHistory, StripeIntentDialog, StripePay, StripeStatusLabels } from 'pages/Loan'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getOneLoanServiceExtension,
  getStripeIntent,
  PaymentOrderType,
  StripeIntentPaidStatus,
  updateLoanServiceExtension,
} from 'services'
import { PlainTable } from 'stories/components'
import { formatTime, InputConvert, InputValidate, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { extensionInputLogic, loanExtensionInputs } from './constant'

export default function LoanServicingExtensionSign() {
  const urlParams: any = useParams()
  const { token, id } = urlParams
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(loanExtensionInputs(true))
  const [histories, setHistories] = useState<FeasibilityHistory[]>([])
  const [stripeIntent, setStripeIntent] = useState<Record<string, any> | null>(null)
  const [isPaid, setPaid] = useState(false)
  const [payData, setPayData] = useState<Record<string, any> | null>(null)
  const dispatch = useDispatch()
  const [requestedBy, setRequestedBy] = useState<any>({})
  const [refId, setRefId] = useState()

  useEffect(() => {
    setLoanNumber()
    const payload: any = jwtDecode(token)

    dispatch({
      type: AUTH_TOKEN_SET,
      token,
      user: payload,
    })

    setRefId(payload.refId)

    load()
  }, [])

  const load = async () => {
    setLoading(true)
    const res = await getOneLoanServiceExtension(id)

    if (res.success) {
      const { data, by, histories } = res.data
      let newInputs = cloneDeep(inputs)
      Object.keys(data).forEach((key) => {
        if (newInputs[key]) {
          newInputs[key].value = data[key]
        }
      })

      newInputs.documents.disableNameChange = true

      newInputs = extensionInputLogic(newInputs)

      setInputs(newInputs)
      setHistories(histories)
      setRequestedBy(by)
    }

    setLoading(false)
    setLoaded(true)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    newInputs = extensionInputLogic(newInputs)
    setInputs(newInputs)
  }

  useEffect(() => {
    let toEmail = ''
    try {
      let emailObj = JSON.parse(inputs['feasibilityEmailToLink']?.value || '{}')
      Object.keys(emailObj).map((key) => {
        if (emailObj[key] === 'to') toEmail = key
      })
    } catch (e) {
      toEmail = inputs['feasibilityEmailToLink']?.value
    }
    setPayData({
      loanExtensionId: id,
      type: inputs['feasibilityType']?.value,
      email: toEmail,
      amount: removeComma(inputs['paymentAmount']?.value),
      refId,
      payOrderType: PaymentOrderType.LoanExtension,
    })
  }, [inputs['feasibilityType']?.value, inputs['paymentAmount']?.value])

  const renderStatus = (status: string) => {
    if (!StripeStatusLabels[status]) return status
    return <p className={`font-medium text-${StripeStatusLabels[status][0]}-500`}>{StripeStatusLabels[status][1]}</p>
  }

  const onOpenHistory = async (history: FeasibilityHistory, index: number) => {
    setLoading(true)
    const stripeIntent = await getStripeIntent(history.data.intentId)
    const { status } = stripeIntent
    console.log('stripeIntent', stripeIntent)

    if (status == 'requires_action') {
      const url = stripeIntent.response.next_action.verify_with_microdeposits.hosted_verification_url
      var windowReference: any = window.open()
      windowReference.location = url
    } else {
      setStripeIntent({
        index,
        ...stripeIntent,
      })
    }
    setLoading(false)
  }

  const historiesTableData = useMemo(
    () =>
      histories.map((history, index) => [
        index + 1,
        <p className="flex gap-1 items-center">
          {history.type == 'ACH' ? (
            <BuildingLibraryIcon className="text-shade-blue w-4 h-4" />
          ) : (
            <CreditCardIcon className="text-shade-blue w-4 h-4" />
          )}
          {history.type}
        </p>,
        renderStatus(history.data.status),
        formatTime(history.createdAt),
        <button
          className="text-shade-blue p-1 hover-shadow1 cursor-pointer"
          onClick={() => onOpenHistory(history, index + 1)}
        >
          <EyeIcon className="w-4 h-4" />
        </button>,
      ]),
    [histories],
  )

  useEffect(() => {
    const lastPayHistory = (histories as any).findLast((history: any) => history.method == 'Pay')
    if (lastPayHistory && StripeIntentPaidStatus.includes(lastPayHistory.data.status)) setPaid(true)
  }, [histories])

  const onPay = (result: boolean) => {
    if (!result) return

    let data = {
      feasibilityType: inputs['feasibilityType'].value,
      documents: inputs['documents'].value,
      carrier: inputs['carrier'].value,
      trackingNumber: inputs['trackingNumber'].value,
    }

    updateLoanServiceExtension(data, id)

    setTimeout(async () => {
      const { data } = await getOneLoanServiceExtension(id)
      setHistories(data.histories)
    }, 2000)
    setPaid(true)
  }

  if (!loaded) return <LayoutLoading show />

  return (
    <TemporaryLayout noOverview={true}>
      <div className="LoanServicingExtensionSign-container">
        <div className="px-2 mt-6">
          <Overview title={`Loan Extension - ${id}`} hasBackButton={false} />
          <div className="max-w-screen-2xl m-auto">
            <LayoutLoading show={loading} />
            <div className="relative bg-white shadow1 rounded mb-6 p-4">
              <div
                className="w-fit bg-yellow-100 border mt-2 border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4 text-[14px]"
                role="alert"
              >
                <div className="font-variation-settings-600">
                  - Request has been sent by <span className="underline">{requestedBy.by}</span> on{' '}
                  {formatTime(requestedBy.createdAt)}.
                </div>
              </div>

              <div className={`grid gap-4 md:grid-cols-4 grid-cols-1 mb-3 max-w-[960px]`}>
                {Object.keys(inputs).map((key) => {
                  let input = inputs[key]
                  if (!input || input.visible === false) return null
                  if (isPaid) input.disabled = true
                  return (
                    <div className={`input md:col-span-${input.span || 1}`} key={key}>
                      <RenderInput input={input} Key={key} onChange={onChange} />
                    </div>
                  )
                })}
              </div>
              {!isPaid && (
                <div
                  className="w-fit bg-gray-100 border mt-4 border-gray-400 text-gray-700 px-4 py-3 rounded relative text-[14px]"
                  role="alert"
                >
                  After payment, you <span className="font-bold italic">cannot edit</span> the above information, so
                  please double-check its accuracy and ensure <span className="font-bold italic">all documents</span>{' '}
                  are uploaded correctly.
                </div>
              )}
              {!isPaid && payData && <StripePay data={payData} onPay={onPay} />}

              <div className="mt-6 w-fit">
                <div className="font-bold ml-2">Payment Histories</div>
                <PlainTable header={['No', 'Type', 'Status', 'Created At', 'View']} data={historiesTableData} />
              </div>
            </div>
            {stripeIntent && <StripeIntentDialog data={stripeIntent} onClose={() => setStripeIntent(null)} />}
          </div>
        </div>
      </div>
    </TemporaryLayout>
  )
}
