import { accountTypes } from 'components/Modals/CreateUser/config'
import { InputType } from 'config'

import { TaskAssigneeOptions } from '../LoanSubmission/constants'

export const getRulesConfigurationFields = () => {
  const rlt: Record<string, InputType> = {
    section1: {
      inputType: 'section',
      title: 'Email Rules',
      span: 'full',
    },
    emailWithdrawnLoans: {
      inputType: 'multiselect',
      title: 'Email Receivers of Withdrawn/Declined Loans',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailSurveyNotification: {
      inputType: 'multiselect',
      title: 'Email Receivers of Survey Notification',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
    emailWeeklyReport: {
      inputType: 'multiselect',
      title: 'Email Receivers of Weekly Loan Registration Report',
      options: TaskAssigneeOptions,
      defaultSelected: false,
      required: true,
    },
    section2: {
      inputType: 'section',
      title: 'Task Generation',
      span: 'full',
    },
    revalidateLoan: {
      inputType: 'multiselect',
      title: 'HUD1 Change - Loan Revalidation',
      options: accountTypes,
      defaultSelected: false,
      required: true,
    },
  }

  return rlt
}
