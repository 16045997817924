import { TrashIcon } from '@heroicons/react/24/outline'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { convertNegative2Parentheses, getPrice2decimal } from 'utils'

export function HUD1_1100({
  inputs,
  data,
  ignoreEror,
  isNewFeeLoading,
  renderSectionHeader,
  renderHUD1Input,
  calculateValue,
  setIsNewFeeLoading,
  setIsAddCCModalOpen,
  onFeeDelete,
  isHud1a,
}: {
  inputs: Record<string, any>
  data: Record<string, any>
  ignoreEror: boolean
  isNewFeeLoading: boolean
  renderSectionHeader: Function
  renderHUD1Input: Function
  calculateValue: Function
  setIsNewFeeLoading: Function
  setIsAddCCModalOpen: Function
  onFeeDelete: Function
  isHud1a: boolean
}) {
  return (
    <>
      {renderSectionHeader('1100. Title Charges')}

      {inputs['titleInsurance']?.map((item: any) => {
        const { key, title, valueKey } = item
        return (
          <tr>
            <td className="p-2 flex justify-between">
              <span>
                {key}. {title}
              </span>
              <span>
                {calculateValue('POC.B.Total') != 0 || +calculateValue('POC.S.Total') != 0 ? 'P.O.C. ' : ''}
                {calculateValue('POC.B.Total') != 0 && (
                  <>
                    {'$' +
                      convertNegative2Parentheses(getPrice2decimal(calculateValue('POC.B.Total'), false, true)) +
                      '(B*)'}
                  </>
                )}
                {calculateValue('POC.B.Total') != 0 && +calculateValue('POC.S.Total') != 0 && ' + '}
                {calculateValue('POC.S.Total') != 0 && (
                  <>
                    {'$' +
                      convertNegative2Parentheses(getPrice2decimal(calculateValue('POC.S.Total'), false, true)) +
                      '(S*)'}
                  </>
                )}
              </span>
            </td>
            <td className="p-2">
              {convertNegative2Parentheses(getPrice2decimal(calculateValue(valueKey), false, true))}
            </td>
          </tr>
        )
      })}
      {inputs['titleInsuranceChilds']?.map((item: any) => {
        const { key, error, ccid, lineIdKey, title } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td></td>
            {!isHud1a && ccid && (
              <td className="p-2 flex items-center justify-end">
                {' '}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      {inputs['1102-1104']?.map((item: any) => {
        const { key, error, ccid, lineIdKey, title } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2 flex gap-2 justify-between items-center" key={key}>
              {renderHUD1Input(item, key)}
            </td>
            <td className="p-2">
              {!data[item['pocKey']] && [3, 5, 99, '3', '5', '99'].includes(data[item['GFEBlockKey']])
                ? convertNegative2Parentheses(
                    getPrice2decimal(
                      +data[item['feeBKey']] + (data[item['GFEBlockKey']] != 99 ? +data[item['feeSKey']] : 0),
                      false,
                      true,
                    ),
                  )
                : ''}
            </td>
            {!isHud1a && (
              <td className="p-2 flex items-center justify-end">
                {!data[item['pocKey']] && data[item['GFEBlockKey']] == 99
                  ? convertNegative2Parentheses(getPrice2decimal(data[item['feeSKey']], false, true))
                  : ''}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      {inputs['1105-1106']?.map((item: any) => {
        const { key } = item
        return (
          <tr>
            <td className="p-2" key={key}>
              {renderHUD1Input(item, key)}
            </td>
          </tr>
        )
      })}
      {inputs['1107-1108']?.map((item: any) => {
        const { key, error } = item
        if (error && !ignoreEror) return
        return (
          <tr>
            <td className="p-2">{renderHUD1Input(item, key)}</td>
          </tr>
        )
      })}
      {inputs['1109-1200']?.map((item: any) => {
        const { key, ccid, title, lineIdKey } = item
        return (
          <tr>
            <td className="p-2">{renderHUD1Input(item, key)}</td>
            <td className="p-2">
              {!data[item['pocKey']] && [3, 5, 99, '3', '5', '99'].includes(data[item['GFEBlockKey']])
                ? convertNegative2Parentheses(
                    getPrice2decimal(
                      +data[item['feeBKey']] + (data[item['GFEBlockKey']] != 99 ? +data[item['feeSKey']] : 0),
                      false,
                      true,
                    ),
                  )
                : ''}
            </td>
            {!isHud1a && (
              <td className="p-2 flex items-center justify-end">
                {!data[item['pocKey']] && data[item['GFEBlockKey']] == 99
                  ? convertNegative2Parentheses(getPrice2decimal(data[item['feeSKey']], false, true))
                  : ''}
                <span
                  className="text-red-800 cursor-pointer flex items-center justify-end"
                  onClick={() => onFeeDelete(ccid, data[lineIdKey], title)}
                >
                  <TrashIcon className="w-4 h-4" />
                </span>
              </td>
            )}
          </tr>
        )
      })}
      <tr>
        <td className="flex items-center">
          <Button
            link
            color="blue"
            onClick={() => {
              setIsNewFeeLoading(true), setIsAddCCModalOpen(true)
            }}
            disabled={isNewFeeLoading}
          >
            Add new fee
          </Button>
          {isNewFeeLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-4 h-4 text-white animate-spin" />
            </span>
          )}
        </td>
      </tr>
    </>
  )
}
