import { ArrowDownTrayIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { SaveChanges } from 'components/SaveChanges'
import { COMPANY_NAME_FC, COMPANY_TITLE } from 'config'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import {
  downloadFile,
  getPurchaseAdvicePdf,
  getPurchaseAdviceSellerData,
  getUserByCompany,
  openS3Document,
  postPurchaseAdviceSellerData,
  sendPurchaseAdviceSignRequest,
} from 'services'
import { Button, PlainTable } from 'stories/components'
import { formatDate, InputValidate, openAuditLog, removeComma } from 'utils'
import { isConstructionLoan } from 'utils/isConstructionLoan'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ConfirmSignDialog } from './ConfirmSignDialog'
import {
  defaultSendSignatureInfos,
  purchaseAdviceAccounting,
  purchaseAdviceGeneralInfos,
  PurchaseAdviceSellerHistory,
} from './constant'

const calcKeys = ['PAPremiumOrDiscountCalc', 'PA_SRPAmountCalc', 'PAWireAmount', 'PAInterestDays', 'PAInterestAmount']

export * from './PurchaseAdviceSellerSign'

export default function PurchaseAdviceSeller() {
  const [data, setData] = useState<any>({})
  const [overview, setOverview] = useState<any>({})
  const [buyerData, setBuyerData] = useState<any>({})
  const [investors, setInvestors] = useState<Array<any>>([])
  const [action, setAction] = useState('')
  const [purchaseAdviceAccountingFields, setPurchaseAdviceAccountingFields] = useState<any>(purchaseAdviceAccounting)
  const [isDutch, setIsDutch] = useState(false)
  const [changed, setChanged] = useState(false)
  const [isSignRequest, setSignRequest] = useState(false)
  const [history, setHistory] = useState<PurchaseAdviceSellerHistory[]>([])

  const copyPricingFromBuyer = () => {
    const keys = [
      'PAUnpaidPrincipalBalance',
      'PA_SRPPerc',
      'PARollFee',
      'PAOtherFees',
      'PAEscrowBalance',
      'APiece',
      'BPiece',
      'PariPassu',
      'ConstructionReserve',
      'InterestReserve',
      'PABuydownFunds',
      'InvestorCode',
      'FirstPaymentDate',
      'PaidToDate',
      'NextPaymentDueDate',
      'InvestorFirstPaymentDate',
      'LoanPurchasedDate',
      'PANotes',
    ]
    let temp = cloneDeep(data)
    keys.map((key) => {
      if (buyerData[key] !== undefined) temp[key] = buyerData[key]
    })
    setData(temp)
    setChanged(true)
    if (temp.InvestorCode) fetchCompanyInfo(temp.InvestorCode, temp)
  }

  const initData = async () => {
    setAction('init')
    const res = await getPurchaseAdviceSellerData()
    setAction('')
    if (res.success) {
      if (res.data.data.InvestorCode) {
        const companyInfo = await getUserByCompany(res.data.data.InvestorCode)
        companyInfo &&
          Object.keys(companyInfo).forEach((key) => {
            res.data.data[key] = companyInfo[key]
          })
      }
      setData(res.data.data)
      setInvestors(res.data.lenders)
      setBuyerData(res.data.buyerData)
      setHistory(res.data.history)
      if (isConstructionLoan(res.data.data.ProductType)) {
        let tempPostClosing = cloneDeep(purchaseAdviceAccountingFields)
        tempPostClosing['PAUnpaidPrincipalBalance'].span = 4
        tempPostClosing['InterestCalculation'].visible = true
        setPurchaseAdviceAccountingFields(tempPostClosing)
        if (res.data.data.InterestCalculation === 'Dutch') setIsDutch(true)
      }
    }
  }

  useEffect(() => {
    setLoanNumber()
    initData()
  }, [])

  const onSync = async (needSave = true, isDownload = true) => {
    setAction('sync')
    needSave && setChanged(false)
    let calcs: any = {}

    calcKeys.map((key) => {
      calcs[key] = calcValue(key)
    })

    const json = {
      overview,
      data: {
        ...data,
        ...calcs,
      },
    }

    if (isDownload) {
      const pdfData: Blob = await getPurchaseAdvicePdf(json)
      downloadFile(`${COMPANY_TITLE} Purchase Advice - ${overview.loanNumber}.pdf`, pdfData)
    }

    if (needSave) await postPurchaseAdviceSellerData({ data })
    setAction('')
    return json
  }

  const feedOverview = async (feed: any) => {
    setOverview(feed)
  }

  const changeData = (key: string, value: any) => {
    const temp = cloneDeep(data)
    if (
      [
        'PARollFee',
        'PAOtherFees',
        'PAEscrowBalance',
        'APiece',
        'BPiece',
        'PariPassu',
        'ConstructionReserve',
        'InterestReserve',
        'PABuydownFunds',
      ].indexOf(key) !== -1
    ) {
      if (removeComma(value) > 0) value = -1 * removeComma(value)
    }
    temp[key] = value
    if (key === 'InterestCalculation') setIsDutch(value === 'Dutch')
    setChanged(true)
    if (defaultSendSignatureInfos[key]) defaultSendSignatureInfos[key].error = ''
    setData(temp)

    if (key == 'InvestorCode' && value) fetchCompanyInfo(value, temp)
  }

  const fetchCompanyInfo = (value: string, data: Record<string, any>) => {
    setAction('gettingAccount')
    getUserByCompany(value)
      .then((res) => {
        if (!res) return
        const newData = cloneDeep(data)
        Object.keys(res).forEach((key) => {
          newData[key] = res[key]
          defaultSendSignatureInfos[key].error = ''
        })
        setData(newData)
      })
      .finally(() => setAction(''))
  }

  const onOpenSignRequest = async () => {
    let hasError = false
    const newData = cloneDeep(data)
    for (const key in defaultSendSignatureInfos) {
      let error = InputValidate({
        ...defaultSendSignatureInfos[key],
        value: data[key] || defaultSendSignatureInfos[key].value,
      })
      newData[key] = data[key] || defaultSendSignatureInfos[key].value
      defaultSendSignatureInfos[key].error = error
      if (error.length > 0) hasError = true
    }
    setData(newData)
    if (hasError) return

    await onSync(true, false)
    setSignRequest(true)
  }

  const onSendSignRequest = async () => {
    const sendingData: Record<string, any> = {}
    Object.keys(defaultSendSignatureInfos).forEach((key) => (sendingData[key] = data[key]))
    const pdfData = await onSync(false, false)
    sendPurchaseAdviceSignRequest({ ...sendingData, pdfData })
  }

  const calcValue = (key: string) => {
    let rlt = 0
    const unpaidBalance = removeComma(data.PAUnpaidPrincipalBalance)
    if (key === 'PAInterestDays') {
      try {
        var start = moment(data['PaidToDate'], 'YYYY-MM-DD')
        var end = moment(data['SettlementDate'], 'YYYY-MM-DD')
        //Difference in number of days
        rlt = Math.trunc(moment.duration(start.diff(end)).asDays())
        if (rlt > 0) rlt -= 1
        else if (rlt < 0) rlt + 1
        rlt *= -1
      } catch {}
      return rlt
    }
    if (key === 'PAInterestAmount') {
      try {
        let currentBalance = removeComma(data['PAUnpaidPrincipalBalance'])
        if (isDutch) currentBalance = removeComma(data['OriginalBalance'])
        const rate = removeComma(data['BuyRate'])
        const interestDays = removeComma(calcValue('PAInterestDays'))
        rlt = ((currentBalance * rate) / 100 / 360) * interestDays
      } catch {}
    }
    if (key === 'PAPremiumOrDiscountCalc') {
      const netPrice = removeComma(data.PANetPrice)
      if (netPrice !== 0) {
        rlt = (-1 * unpaidBalance * (100 - netPrice)) / 100
      }
    }
    if (key === 'PA_SRPAmountCalc') {
      const srpPerc = removeComma(data.PA_SRPPerc)
      if (srpPerc !== 0) {
        rlt = (unpaidBalance * srpPerc) / 100
      }
    }
    if (key === 'PAWireAmount') {
      rlt =
        removeComma(data.PAUnpaidPrincipalBalance) +
        removeComma(data.PARollFee) +
        removeComma(data.PAOtherFees) +
        removeComma(data.PAEscrowBalance) +
        removeComma(data.APiece) +
        removeComma(data.BPiece) +
        removeComma(data.PariPassu) +
        removeComma(data.ConstructionReserve) +
        removeComma(data.InterestReserve) +
        removeComma(calcValue('PAInterestAmount')) +
        removeComma(data.PABuydownFunds)
      if (removeComma(data.PAPremiumOrDiscountOV) !== 0) {
        rlt += removeComma(data.PAPremiumOrDiscountOV)
      } else {
        rlt += removeComma(calcValue('PAPremiumOrDiscountCalc'))
      }
      if (removeComma(data.PA_SRPAmountOV) !== 0) {
        rlt += removeComma(data.PA_SRPAmountOV)
      } else {
        rlt += removeComma(calcValue('PA_SRPAmountCalc'))
      }
    }
    return rlt.toFixed(2)
  }

  const showHistory = (key: string) => {
    const options = {
      table: 'PurchaseSeller',
      field: `${key}`,
      keys: {
        field: key,
      },
    }
    openAuditLog(options)
  }

  return (
    <div className="purchase-advice-container px-2">
      <SaveChanges show={changed} onSave={onSync} label="Save and Generate PDF" />
      <div className="hidden">
        <Overview title="Purchase Advice Seller" feedOverview={feedOverview} />
      </div>
      <div className="max-w-screen-2xl m-auto">
        {/* <div className="relative bg-white shadow1 rounded mb-4 md:p-6 p-2"> */}
        <div className="relative bg-white mt-6">
          <LayoutLoading show={action !== ''} />
          <div className="grid md:grid-cols-12 gap-5">
            <div className="md:col-span-5">
              <div className="shadow rounded border">
                <div className="bg-gray-200 round-t py-1 px-4">General Info & Date</div>
                <div className="p-2">
                  {Object.keys(purchaseAdviceGeneralInfos).map((key, index) => {
                    let input: any = purchaseAdviceGeneralInfos[key]
                    input.value = data[key]
                    input.history = true
                    if (key === 'InvestorCode') input.options = investors
                    return (
                      <div className="py-[5px]" key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="shadow rounded border my-4">
                <div className="bg-gray-200 round-t py-1 px-4">Purchase Advice Notes</div>
                <div className="p-2">
                  <RenderInput
                    input={{
                      title: 'Notes',
                      inputType: 'textarea',
                      history: true,
                      value: data['PANotes'] ? data['PANotes'] : '',
                    }}
                    Key={'PANotes'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
              </div>
              <div className="shadow rounded border">
                <div className="bg-gray-200 round-t py-1 px-4">Signature Request</div>
                <div className="p-2">
                  {Object.keys(defaultSendSignatureInfos).map((key, index) => {
                    const input: any = defaultSendSignatureInfos[key]
                    input.history = true
                    input.value = data[key]
                    return (
                      <div className="py-1.5" key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
                <div className="mt-0 flex justify-center">
                  <Button color="gray" loading={action === 'sync'} onClick={onOpenSignRequest}>
                    Save & Send Sign Request
                  </Button>
                </div>
              </div>
            </div>
            <div className="md:col-span-7">
              <div className="shadow rounded border">
                <div className="bg-gray-200 round-t py-1 px-4">Investor Wire Calculation</div>
                <div className="grid md:grid-cols-6 gap-2 p-2">
                  {Object.keys(purchaseAdviceAccountingFields).map((key, index) => {
                    let input = purchaseAdviceAccountingFields[key]
                    input.history = true
                    input.value = data[key]
                    if (input.visible === false) return null
                    if (calcKeys.indexOf(key) !== -1) input.value = calcValue(key)
                    return (
                      <div className={`md:col-span-${input.span ? input.span : 6}`} key={index}>
                        <RenderInput input={input} Key={key} onChange={changeData} showHistory={showHistory} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="p-2 grid md:grid-cols-6 gap-2 my-2">
                <div className="md:col-span-2">
                  <RenderInput
                    input={{
                      title: `Taxes to be paid by ${COMPANY_NAME_FC}`,
                      className: 'flex items-center',
                      inputType: 'checkbox',
                      history: true,
                      value: data['TaxPaid'] !== undefined ? data['TaxPaid'] : false,
                    }}
                    Key={'TaxPaid'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
                <div className="md:col-span-2">
                  <RenderInput
                    input={{
                      title: `Insurance to be paid by ${COMPANY_NAME_FC}`,
                      className: 'flex items-center',
                      inputType: 'checkbox',
                      history: true,
                      value: data['InsurancePaid'] !== undefined ? data['InsurancePaid'] : false,
                    }}
                    Key={'InsurancePaid'}
                    onChange={changeData}
                    showHistory={showHistory}
                  />
                </div>
              </div>
              <div className="mt-0 flex justify-center">
                <Button color="gray" loading={action === 'sync'} onClick={onSync}>
                  Save & Generate PDF
                </Button>
              </div>

              <div
                className="px-2 flex gap-1 items-center hover:underline cursor-pointer text-shade-blue text-[14.5px]"
                onClick={copyPricingFromBuyer}
              >
                <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
                <span>Copy Data from Buyer-Side</span>
              </div>
            </div>
          </div>
          {history.length != 0 && (
            <div className="mt-6 -mb-6">
              <PlainTable
                header={['No', 'Company Name', 'name', 'email', 'By', 'Date', 'Status']}
                data={history.map((item, index) => [
                  index + 1,
                  item.companyName,
                  item.name,
                  item.email,
                  item.requestedBy,
                  formatDate(item.createdAt),
                  <span onClick={() => openS3Document(item.signedPdfKey || item.unsignedPdfKey)}>
                    <div className="cursor-pointer flex items-center underline text-gray-900 hover:text-shade-blue">
                      {item.signedPdfKey ? 'Signed' : 'Unsigned'}
                      <ArrowDownTrayIcon className="w-4 h-4 ml-2" />
                    </div>
                  </span>,
                ])}
              />
            </div>
          )}
        </div>
      </div>

      {isSignRequest && (
        <ConfirmSignDialog
          data={data}
          generatePdf={onSync}
          onClose={() => setSignRequest(false)}
          onConfirm={() => onSendSignRequest()}
        />
      )}
    </div>
  )
}
