import Api from 'services/api'

const API_INVESTORS = '/investors'
const API_INVESTOR_BY_ID = '/investors/:id'
const API_INVESTOR_MY_PROFILE = '/investors/myProfile'
const API_INVESTOR_OVERVIEW = '/investors/overview/:loanNumber'
const API_INVESTORS_LOAN_STATUS = '/investors/LoanStatus/:investorID/:loanNo'
const API_INVESTORS_LOAN_CHATS = '/investors/LoanChats/:investorID/:loanNo'

export const getInvestorLoanMessage = (investorID: number, loanNo: number) => {
  return Api.get(API_INVESTORS_LOAN_CHATS, {}, { investorID, loanNo })
}

export const sendInvestorLoanMessage = (investorID: number, loanNo: number, data: any) => {
  return Api.post(API_INVESTORS_LOAN_CHATS, data, { investorID, loanNo })
}

export const getInvestorLoanStatusHistory = (investorID: number, loanNo: number) => {
  return Api.get(API_INVESTORS_LOAN_STATUS, {}, { investorID, loanNo })
}

export const changeInvestorLoanStatus = (investorID: number, loanNo: number, investorStatus: string) => {
  return Api.post(API_INVESTORS_LOAN_STATUS, { investorStatus }, { investorID, loanNo })
}

export const getInvestorCodes = () => {
  return Api.get(`${API_INVESTORS}/codes`)
}

export const filterInvestors = (filters: Record<string, any>) => {
  return Api.get(API_INVESTORS, filters)
}

export const createInvestor = (data: Record<string, any>) => {
  return Api.post(API_INVESTORS, data)
}

export const updateInvestor = (id: number, data: Record<string, any>) => {
  return Api.put(API_INVESTOR_BY_ID, data, { id })
}

export const updateInvestorPassword = (id: number, password: string) => {
  return Api.post(API_INVESTOR_BY_ID, { password }, { id })
}

export const getInvestorAssignedLoans = (id: number) => {
  return Api.get(API_INVESTOR_BY_ID, {}, { id })
}

export const getMyInvestorProfile = () => {
  return Api.get(API_INVESTOR_MY_PROFILE)
}

export const updateMyInvestorProfile = (data: Record<string, any>) => {
  return Api.post(API_INVESTOR_MY_PROFILE, data)
}

export const updateMyInvestorPassword = (password: string) => {
  return Api.put(API_INVESTOR_MY_PROFILE, { password })
}

export const sendInvestorEmail = (id: number) => {
  return Api.post(`${API_INVESTOR_BY_ID}/email`, {}, { id })
}

export const getInvestorLoanOverview = (loanNumber: string) => {
  return Api.get(API_INVESTOR_OVERVIEW, {}, { loanNumber })
}
