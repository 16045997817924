import { useState } from 'react'

import { InvestorForgetPwd } from './ForgetPassword'
import { InvestorLogin } from './Login'
import { InvestorResetPwd } from './ResetPassword'

enum ScreenType {
  Login,
  ForgetPassword,
  ResetPassword,
}

export function InvestorAuth({ token, onSigned: _onSigned }: { token: string; onSigned: Function }) {
  const [screenType, setScreenType] = useState<ScreenType>(ScreenType.Login)
  const [newToken, setNewToken] = useState('')

  const onSigned = (newToken: string, isRequireResetPwd: boolean) => {
    if (!isRequireResetPwd) {
      _onSigned(newToken)
      return
    }
    setNewToken(newToken)
    setScreenType(ScreenType.ResetPassword)
  }

  if (screenType == ScreenType.Login)
    return (
      <InvestorLogin token={token} onSigned={onSigned} onForgotPwd={() => setScreenType(ScreenType.ForgetPassword)} />
    )

  if (screenType == ScreenType.ResetPassword)
    return <InvestorResetPwd token={newToken} onSubmit={() => _onSigned(newToken)} />

  return <InvestorForgetPwd token={token} onSubmit={() => setScreenType(ScreenType.Login)} />
}
