import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { loanStatusConstants, SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSetting, updateSetting } from 'services'
import { Select } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'

const requireFrom = {
  submitLoan: 'Submit Loan',
  submitSetup: 'Submit to LoanSetup',
  submitConditions: 'Submit Conditions',
}

export const RestructureLoanRelease = () => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<any>({})

  useEffect(() => {
    setLoading(true)
    getSetting(SettingKey.RESTRUCTURE_LOAN_RELEASE)
      .then(({ value }) => {
        const content = JSON.parse(value || '{}')
        setData(content)
      })
      .finally(() => setLoading(false))
  }, [])

  const onSync = async (_data: any) => {
    setLoading(true)
    await updateSetting(SettingKey.RESTRUCTURE_LOAN_RELEASE, JSON.stringify(_data))
    setLoading(false)
  }

  const onChange = (key: string, value: any, obj: string) => {
    let temp = cloneDeep(data)
    if (temp[key] === undefined) temp[key] = {}
    temp[key][obj] = value
    setData(temp)
    onSync(temp)
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="w-fit mt-4">
        <table className="w-full text-sm">
          <thead className="uppercase text-xs bg-gray-50 dark:bg-gray-700">
            <tr>
              {['No', 'Loan Status', 'Require From?', 'Loan App Sign Required?'].map((v) => (
                <th scope="col" className="p-3 border" key={`header-${v}`}>
                  {v}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(loanStatusConstants.status).map((key, index) => {
              const item = data[key] || {}
              return (
                <tr key={index}>
                  <td className="p-2 border">{index + 1}</td>
                  <td className="p-2 border">{loanStatusConstants.status[key]}</td>
                  <td className="p-2 border">
                    <div className="flex justify-center -mb-4">
                      <Select
                        id={`requireFrom-${key}`}
                        size={3}
                        title=""
                        options={requireFrom}
                        value={item?.requireFrom}
                        hasDefaultOption={true}
                        onChange={(value) => onChange(key, value, 'requireFrom')}
                      />
                    </div>
                  </td>
                  <td className="p-2 border">
                    <div className="flex justify-center">
                      <RenderInput
                        Key={key}
                        input={{
                          title: '',
                          inputType: 'check',
                          error: '',
                          value: item?.signRequired,
                        }}
                        onChange={(key: string, value: any) => onChange(key, value, 'signRequired')}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
