import Api from 'services/api'

const API_USER_FORGET_PASSWORD = '/auth/forgetPassword'
const API_USER_CHECK_PASSPORT = '/auth/checkPassport/:selector/:token'
const API_USER_RESET_PASSWORD = '/auth/resetPassword/:selector/:token'
const API_USER_REGISTER = '/auth/register'
const API_AUTH_SEND_2FA_CODE = '/auth/send2faCode'
const API_AUTH_VERIFY_2FA_CODE = '/auth/verify2faCode'

const API_TEMPORARY_AUTH_SIGNIN = '/temporaryAuth/login'
const API_TEMPORARY_AUTH_RESET_PWD = '/temporaryAuth/resetPwd'
const API_TEMPORARY_AUTH_FORGOT_PWD = '/temporaryAuth/forgotPwd'
const API_TEMPORARY_AUTH_EXISTS_EMAIL = '/temporaryAuth/exists'
const API_TEMPORARY_AUTH_REGISTER = '/temporaryAuth/register'

const API_INVESTOR_AUTH_SIGNIN = '/investorAuth/login'
const API_INVESTOR_AUTH_RESET_PWD = '/investorAuth/resetPwd'
const API_INVESTOR_AUTH_FORGOT_PWD = '/investorAuth/forgotPwd'

export const send2faCode = (data: Record<string, string>) => {
  return Api.post(API_AUTH_SEND_2FA_CODE, data)
}

export const verify2faCode = (data: Record<string, string>) => {
  return Api.post(API_AUTH_VERIFY_2FA_CODE, data)
}

export const checkPassport = (selector: string, token: string) => {
  return Api.get(API_USER_CHECK_PASSPORT, {}, { selector, token })
}

export const resetPassword = (selector: string, token: string, password: string) => {
  return Api.post(API_USER_RESET_PASSWORD, { password }, { selector, token })
}

export const sendForgetPasswordEmail = (email: string) => {
  return Api.post(API_USER_FORGET_PASSWORD, { email })
}

export const registerUser = (data: Record<string, string | number | boolean>) => {
  return Api.put(API_USER_REGISTER, data)
}

export const signInTemporaryUser = (data: Record<string, any>) => {
  return Api.post(API_TEMPORARY_AUTH_SIGNIN, data)
}

export const resetTemporaryUserPassword = (data: Record<string, any>) => {
  return Api.post(API_TEMPORARY_AUTH_RESET_PWD, data)
}

export const sendTemporaryForgetPasswordEmail = (data: Record<string, any>) => {
  return Api.post(API_TEMPORARY_AUTH_FORGOT_PWD, data)
}

export const checkTemporaryUserExists = (email: string) => {
  return Api.post(API_TEMPORARY_AUTH_EXISTS_EMAIL, { email })
}

export const registerTemporaryUser = (data: Record<string, any>) => {
  return Api.post(API_TEMPORARY_AUTH_REGISTER, data)
}

export const signInInvestorUser = (data: Record<string, any>) => {
  return Api.post(API_INVESTOR_AUTH_SIGNIN, data)
}

export const resetInvestorUserPassword = (data: Record<string, any>) => {
  return Api.post(API_INVESTOR_AUTH_RESET_PWD, data)
}

export const sendInvestorForgetPasswordEmail = (data: Record<string, any>) => {
  return Api.post(API_INVESTOR_AUTH_FORGOT_PWD, data)
}
