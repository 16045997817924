import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getLoanApplicationTemplate, submitLoanApplicationTemplate } from 'services'
import { Button } from 'stories/components'
import { confirm } from 'utils'
import { RenderInput } from 'utils/RenderInput'

export const LoanApplicationTemplate = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>>({})

  const initData = async () => {
    setIsLoading(true)
    const res = await getLoanApplicationTemplate()
    setData(res)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [])

  const onChange = (key: string, value: string) => {
    const temp = cloneDeep(data)
    if (key.includes('content')) {
      const index = key.split('.')[1]
      temp['content'][index] = value
    } else temp[key] = value
    setData(temp)
  }

  const onAddClick = () => {
    const temp = cloneDeep(data)
    temp.content.push('')
    setData(temp)
  }

  const onDelete = async (index: number) => {
    const res: any = await confirm(`Are you sure you want to delete this content?`)
    if (res === false) return
    const temp = cloneDeep(data)
    temp.content.splice(index, 1)
    setData(temp)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    await submitLoanApplicationTemplate(data)
    toast('Successfully submitted', { type: 'success' })
    setIsLoading(false)
  }
  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <h2 className="text-2xl font-bold flex items-center mb-4">Loan Application Template</h2>
      <div className="mb-6 relative">
        <h3 className="p-2 font-semibold">1. Header</h3>
        <RenderInput
          input={{
            inputType: 'textarea',
            title: 'Text',
            value: data.header,
            rows: 5,
          }}
          Key="header"
          onChange={onChange}
        />
      </div>
      <div className="mb-6 relative">
        <h3 className="p-2 font-semibold">2. Content</h3>
        {data.content?.map((v: string, index: number) => (
          <div className="mb-2">
            <div className="flex items-center justify-end">
              <span onClick={() => onDelete(index)} className="cursor-pointer hover:underline decoration-red-600">
                <div className="flex items-center gap-1 text-red-600">
                  <TrashIcon className="w-4 h-4"></TrashIcon>
                  Delete
                </div>
              </span>
            </div>
            <RenderInput
              input={{
                inputType: 'textarea',
                title: `Text ${index + 1}`,
                value: v,
                rows: 5,
              }}
              Key={`content.${index}`}
              onChange={onChange}
            />
          </div>
        ))}
        <Button link onClick={onAddClick}>
          <div className="flex gap-2 items-center">
            <PlusIcon className="w-4 h-4"></PlusIcon> Add Content
          </div>
        </Button>
      </div>
      <div className="mb-6 relative">
        <h3 className="p-2 font-semibold">3. Footer</h3>
        <RenderInput
          input={{
            inputType: 'textarea',
            title: 'Text',
            value: data.footer,
            rows: 5,
          }}
          Key="footer"
          onChange={onChange}
        />
      </div>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>
    </div>
  )
}
