import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCredentials } from 'services'
import { svgSearch } from 'stories/assets'
import { Input, Pagination, PlainTable, Select } from 'stories/components'
import { formatTime } from 'utils'

const itemCountPerPage = 10

export const IntegrationType: Record<string, any> = {
  eliteValuation: 'Elite Valuation',
  appraisalNation: 'Appraisal Nation',
  nationWide: 'Nationwide Appraisal Network (NAN)',
}

export const Credentials = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<Array<Array<any>>>([])
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    pageNum: 0,
    type: '',
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)

  const header: any = [
    'No',
    '<span><div className="border-b border-solid border-[1px] w-fit mb-1 border-gray-500">Name</div>Email</span>',
    'Credential Type',
    'Credential Username',
    'Updated at',
  ]
  const initData = async () => {
    setIsLoading(true)
    const res = await getCredentials({
      query: filterQuery,
      count: itemCountPerPage,
      skip: 0 * itemCountPerPage,
      type: filters.type,
    })
    setTotal(res.totalCounts)
    setData(res.credentials)
    setIsLoading(false)
  }
  useEffect(() => {
    initData()
  }, [filterQuery])

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (key: string, value: any) => {
    const newFilters = cloneDeep(filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else filterUsersData(newFilters)
  }

  const filterUsersData = async (filters: Record<string, any>) => {
    setIsLoading(true)
    const { credentials, totalCounts } = await getCredentials({
      query: filters.query.trim(),
      count: itemCountPerPage,
      skip: filters.pageNum * itemCountPerPage,
      type: filters.type,
    })
    setData(credentials)
    setTotal(totalCounts)
    setIsLoading(false)
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">Credentials</h1>
      <LayoutLoading show={isLoading} />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-2 items-center">
        <Input
          type="search"
          title="Search Users"
          hasIcon
          icon={svgSearch}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Select
          id="search-type"
          title="Type"
          options={IntegrationType}
          value={filters.type}
          hasDefaultOption
          onChange={(value) => onChangeFilter('type', value)}
        />
      </div>
      <PlainTable
        header={header}
        data={data.map((item: any, index: number) => [
          index + 1,
          <span>
            <div>
              <Link
                to={`/registrations/${item.eliteCredential_userID}`}
                target="_blank"
                className="font-bold text-shade-blue cursor-pointer border-b w-fit mb-2 text-left"
              >
                {item.User_name}
              </Link>
            </div>
            {item.User_email}
          </span>,
          <span className="max-w-[20px]">{IntegrationType[item.eliteCredential_type]}</span>,
          item.eliteCredential_username,
          formatTime(item.eliteCredential_updatedAt),
        ])}
        tdClass="px-6 py-4"
      />
      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>
    </div>
  )
}
