import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import {
  ClosingCostsMap,
  GFEBlockMap,
  InputType,
  PaidByMap,
  PaidToMap,
  ResponsiblePartyMap,
  TridBlockMap,
} from 'config'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteClosingCost, getClosingCostSetup } from 'services'
import { Button, Modal } from 'stories/components'
import { confirm, getPrice1or2decimal } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { OverrideCaclModal } from '../OverrideCalcModal/OverrideCalcModal'

interface ClosingCostsDetailModalProps {
  /**
   * Closing Costs Details
   */
  closingCosts?: Record<string, any>
  /**
   * Loan Amount
   */
  loanNumber?: string
  loanAmount?: string
  isPoints?: boolean
  /**
   * On close handler
   */
  onClose?: Function
  /**
   * On submit handler
   */
  onSubmit?: Function
  onDelete?: Function
}

const defaultInputs = (): Record<string, InputType | any> => {
  return {
    hudNo: {
      inputType: 'text',
      title: 'Hud Line No',
      disabled: true,
      className: 'col-span-6',
    },
    lineName: {
      inputType: 'text',
      title: 'Line Name',
      disabled: true,
      className: 'col-span-6',
    },
    points: {
      inputType: 'text',
      title: 'Points',
      prefix: '%',
      contentType: 'number',
      className: 'col-span-6',
    },
    borrowerAmount: {
      inputType: 'text',
      title: 'Paid by Borrower',
      contentType: 'number',
      className: 'col-span-6',
    },
    PaidByOtherType: {
      inputType: 'select',
      title: 'Paid By',
      options: PaidByMap,
      className: 'col-span-6',
    },
    sellerAmount: {
      inputType: 'text',
      title: 'Paid by Seller',
      contentType: 'number',
      className: 'col-span-6',
    },
    totalAmount: {
      inputType: 'text',
      title: 'Total Amount',
      contentType: 'number',
      disabled: true,
      className: 'col-span-6',
    },
    disclosedAmount: {
      inputType: 'text',
      title: 'Disclosed Amount',
      contentType: 'number',
      disabled: true,
      className: 'col-span-6',
    },
    Estimated: {
      inputType: 'text',
      title: 'Estimated',
      disabled: true,
      className: 'col-span-6',
    },
    TRIDBlock: {
      inputType: 'select',
      title: 'TRID Block',
      options: TridBlockMap,
      disabled: true,
      className: 'col-span-6 col-start-1',
    },
    GFEBlock: {
      inputType: 'select',
      title: 'GFE Block',
      options: GFEBlockMap,
      disabled: true,
      className: 'col-span-6',
    },
    ClosingCostType: {
      inputType: 'select',
      title: 'Type',
      options: ClosingCostsMap,
      disabled: true,
      className: 'col-span-6',
    },
    PaidToType: {
      inputType: 'select',
      title: 'Paid To Type',
      options: PaidToMap,
      disabled: true,
      className: 'col-span-6',
    },
    PaidTo: {
      inputType: 'text',
      title: 'Paid To',
      className: 'col-span-12',
    },
    PPFC: {
      inputType: 'checkbox',
      title: 'Prepaid Finance Charge',
      className: 'col-span-6',
      disabled: true,
    },
    NotCounted: {
      inputType: 'checkbox',
      title: 'Not Counted in Totals',
      className: 'col-span-6',
    },
    ProviderChosenByBorrower: {
      inputType: 'checkbox',
      title: 'Borrower Selected',
      className: 'col-span-6',
    },
    NetFromWire: {
      inputType: 'checkbox',
      title: 'Net from Wire',
      className: 'col-span-6',
    },
    ResponsiblePartyType: {
      inputType: 'select',
      title: 'Responsible Party',
      options: ResponsiblePartyMap,
      disabled: true,
      className: 'col-span-6',
    },
    PointsAndFeesAmount: {
      inputType: 'text',
      title: 'Points and Fees Amount',
      disabled: true,
      className: 'col-span-6',
      contentTeyp: 'number',
    },
    lineId: {
      inputType: 'text',
      title: 'Line ID',
      disabled: true,
      className: 'col-span-6',
    },
    history: {
      inputType: 'textarea',
      title: 'TRID Good Faith Amount History',
      className: 'col-span-12',
      rows: 5,
      disabled: true,
    },
  }
}

export const ClosingCostsDetailsModal = ({
  closingCosts = {},
  loanNumber = '',
  loanAmount = '',
  onClose = () => {},
  onSubmit = () => {},
  onDelete = () => {},
}: ClosingCostsDetailModalProps) => {
  const [data, setData] = useState<Record<string, any>>({})
  const [ovData, setOVData] = useState<any>({})
  const [explain, setExplain] = useState<boolean>(false)
  const [setup, setSetup] = useState<Record<string, any>>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const inputs = defaultInputs()

  const initData = async () => {
    setData(closingCosts)
    setIsLoading(true)
    const res = await getClosingCostSetup(closingCosts.lineId.value)
    setIsLoading(false)
    if (res.success) setSetup(res.data)
  }
  useEffect(() => {
    initData()
  }, [closingCosts])

  const onModalClose = () => {
    onClose(false)
  }

  const onModalSubmit = () => {
    onSubmit(true, data)
  }

  const onExplainModalClose = () => {
    setExplain(false)
  }

  const onValueChanged = (key: string, value: any) => {
    const temp = cloneDeep(data)

    if (inputs[key]?.contentType === 'number') temp[key].value = getPrice1or2decimal(value).replaceAll(',', '')
    else temp[key].value = value

    if (['sellerAmount', 'points'].includes(key) && temp.points.value?.toString().length > 0)
      temp['borrowerAmount'].value = (+temp.points.value * +loanAmount) / 100 - +temp.sellerAmount.value

    if (key == 'points' && temp.points.value?.toString().length == 0) {
      temp['borrowerAmount'].value = ''
      temp['sellerAmount'].value = ''
    }

    setData(temp)
  }

  const renderModalInput = (input: any, key: string) => {
    input.value = data[key]?.value || ''
    if (input.contentType === 'number') input.value = getPrice1or2decimal(input.value)

    if (key === 'points')
      if (!data.isPoints?.value) {
        input.disabled = true
        input.value = 'N/A'
      } else {
        input.disabled = false
        input.value = getPrice1or2decimal(input.value)
      }

    if (
      (key == 'hudNo' && !setup.hudNoLocked) ||
      (key == 'lineName' && !setup.lineNameLocked) ||
      (key == 'TRIDBlock' && !setup.tridBlockLocked) ||
      (key == 'GFEBlock' && !setup.gfeBlockLocked) ||
      (key == 'ClosingCostType' && !setup.typeLocked) ||
      (key == 'PaidToType' && !setup.paidToTypeLocked) ||
      (key == 'ResponsiblePartyType' && !setup.responsiblePartyTypeLocked) ||
      (key == 'PPFC' && !setup.ppfcLocked)
    ) {
      input.disabled = false
    }
    if (key === 'borrowerAmount') {
      if (data.points?.value && data.points?.value.toString().length > 0) input.disabled = true
      else input.disabled = false

      input.additionalElements = () => (
        <label className="ml-6 flex items-center gap-2">
          <input
            type="checkbox"
            checked={data['poc']?.value}
            onChange={(e) => onValueChanged('poc', e.target.checked)}
            className="rounded-sm"
            disabled={data['isPOCDisabled']?.value}
          />{' '}
          <span> P.O.C.</span>
        </label>
      )
    }

    if (key === 'sellerAmount') input.title = `Paid by ${PaidByMap[data['PaidByOtherType']?.value || '0']}`
    if (['totalAmount', 'disclosedAmount'].includes(key))
      input.value = getPrice1or2decimal(+data['borrowerAmount']?.value + +data['sellerAmount']?.value)

    if (key === 'PaidTo' && !!data['partyType']?.value) {
      input.additionalElements = (
        <>
          <Link
            className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
            to={`/parties/${loanNumber}?edit=${data['partyType']?.value}`}
            target="_blank"
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </Link>
          <span
            className="cursor-pointer hover:text-shade-blue flex-1 overflow-hidden"
            onClick={() =>
              setOVData({
                title: data['partyType']?.value,
                overrideKey: 'PaidTo',
                calcValue: data.partyName?.value,
                ovValue: data['PaidTo']?.value || '',
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
        </>
      )
      input.disabled = true
    }

    if (key === 'totalAmount') {
      input.additionalElements = (
        <label className="ml-6 flex items-center gap-2">
          <input
            type="checkbox"
            checked={data['Financed']?.value}
            onChange={(e) => onValueChanged('Financed', e.target.checked)}
            className="rounded-sm"
          />{' '}
          <span> Financed into Loan</span>
        </label>
      )
    }

    if (key === 'PointsAndFeesAmount') {
      let calcValue = 0
      if (data.PaidByOtherType?.value === 0 && data.borrowerAmount?.value === 0) calcValue = 0
      else if (data.PaidToType?.value === 2) calcValue = +data.borrowerAmount?.value + +data.sellerAmount?.value
      else if (
        [93, 92, 98, 94, 95, 66, 99, '93', '92', '98', '94', '95', '66', '99'].includes(data.ClosingCostType?.value) ||
        (data.PPFC?.value && [1, 5, '1', '5'].includes(data.PaidToType?.value))
      )
        calcValue = +data.borrowerAmount?.value + (data.PaidByOtherType?.value != 1 ? +data.sellerAmount?.value : 0)
      input.value = data['PointsAndFeesAmountOV']?.value || input.value
      input.additionalElements = (
        <>
          <span
            className="cursor-pointer hover:text-shade-blue"
            onClick={() =>
              setOVData({
                title: 'Points and Fees Amount',
                overrideKey: 'PointsAndFeesAmountOV',
                calcValue: getPrice1or2decimal(calcValue),
                ovValue: getPrice1or2decimal(data['PointsAndFeesAmountOV']?.value || ''),
              })
            }
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
          <span className="cursor-pointer hover:text-shade-blue" onClick={() => setExplain(true)}>
            Explain
          </span>
        </>
      )
    }

    if (key === 'PaidTo') {
      let paidToName = ''
      if (!!data['partyType']?.value) {
        if (data['PaidTo']?.value) paidToName = data['PaidTo']?.value
        else paidToName = data.partyName?.value
      } else paidToName = data['PaidTo']?.value
      input.value = paidToName
    }

    return <RenderInput input={input} Key={key} onChange={onValueChanged} />
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onValueChanged(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  const onFeeDelete = async () => {
    setIsLoading(true)
    const content = <div className="mb-4">Are you sure want to remove this Closing Cost?</div>
    const isDelete = await confirm(content, {}, 2)
    if (!isDelete) {
      setIsLoading(false)
      return
    }
    await deleteClosingCost(data.ccid.value || data.lineId.value)
    setIsLoading(false)
    onDelete()
  }

  return (
    <Modal
      title={'Closing Costs Details'}
      titleOkay={'Okay'}
      loading={isLoading}
      isOpen
      onClose={onModalClose}
      onOk={onModalSubmit}
      titleCancel="Cancle"
      childLevel={1}
    >
      <>
        <div className="grid grid-cols-12 gap-4 text-sm">
          {Object.keys(inputs).map((key) => {
            const input = inputs[key]
            return (
              <div className={input.className} key={key}>
                {renderModalInput(input, key)}
              </div>
            )
          })}
        </div>

        <Button onClick={onFeeDelete} color="gray" className="mt-4">
          Delete this fee
        </Button>
        {Object.keys(ovData).length > 0 && (
          <OverrideCaclModal
            key={ovData.overrideKey}
            title={ovData.title}
            calcValue={ovData.calcValue}
            ovValue={ovData.ovValue}
            onClose={closeOVModal}
          />
        )}
        {explain && (
          <Modal
            title="Points And Fees Explain"
            titleOkay="Okay"
            isOpen
            onClose={onExplainModalClose}
            onOk={onExplainModalClose}
            titleCancel=""
          >
            <p className="max-w-xl">
              The following tests are performed to determine if the fee is included in the 'points and fees'. The tests
              are applied in the order below. If the fee meets the criteria of the test then subsequent tests are not
              applied. If the fee meets none of the tests, it is excluded from the points and fees calculation. <br />
              <br />
              1. If the fee is the responsibility of the seller and is entirely paid by the seller, then the entire
              amount of the fee is excluded. <br /> <br />
              2. If the fee is paid to the broker then the entire amount of the fee is included.
              <br /> <br />
              3. If the Points and Fees Amount has been overridden for an individual closing cost on the Closing Cost
              Details screen then that override amount is used. <br /> <br />
              4. If the 'Points and Fees Directive' is set to any value other than 'Default', then that directive is
              used. The 'Points and Fees Directive' is set on the 'Closing Cost Setup' screen in the defaults. <br />
              <br />
              5. If the fee's closing cost type matches any of the following then the amount of the fee that is not paid
              by the lender is included.
              <br />
              (a) Credit Life Insurance Premium <br />
              (b) Accident Insurance Premium <br />
              (c) Loss Of Income Insurance Premium <br />
              (d) Credit Property Insurance Premium <br />
              (e) Health Insurance Premium <br />
              (f) Debt Cancellation Fee <br />
              <br />
              6. If the fee's closing cost type is Prepayment Penalty then the amount of the fee that is not paid by the
              lender is included.
              <br />
              <br />
              7. If (a) the fee is not a prepaid finance charge (PPFC) and (b) and the fee is paid to the lender or an
              affiliate the lender, then the amount of the fee that is not paid by the lender is included. <br />
              <br />
              8. If the fee is paid to the lender, broker, affiliate of lender or affiliate of broker and the fee is
              marked as a prepaid finance charge (PPFC), then the points and fees includes the portion of the fee that
              is both (a) included in the finance charge and (b) not paid by the lender.
            </p>
          </Modal>
        )}
      </>
    </Modal>
  )
}
