import { type InputType, COMPANY_TITLE } from 'config'

const BankAccountTypeOptions = ['Checking', 'Saving']

const afterRenovationInputs = (): Record<string, InputType> => ({
  entityTakingTitle: {
    inputType: 'text',
    type: 'text',
    title: 'Entity Taking Title',
    value: '',
    error: '',
    span: 2,
  },
  borrowerName: {
    inputType: 'text',
    type: 'text',
    title: 'Borrower Name',
    value: '',
    error: '',
    required: true,
  },
  borrowerAddress: {
    inputType: 'map',
    title: 'Borrower Address',
    value: '',
    error: '',
    required: true,
  },
  to: {
    inputType: 'text',
    type: 'text',
    title: 'To',
    disabled: true,
    value: `${COMPANY_TITLE} ISAOA/ATIMA`,
    error: '',
  },
  propertyAddress: {
    inputType: 'map',
    title: 'Property Address',
    value: '',
    error: '',
    required: true,
  },
  coBorrowerName: {
    inputType: 'text',
    type: 'text',
    title: 'Co-Borrower Name',
    value: '',
    error: '',
    required: true,
  },
  coBorrowerAddress: {
    inputType: 'map',
    title: 'Co-Borrower Address',
    value: '',
    error: '',
    required: true,
  },
})

const monthNameMap: any = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export const defaultInputs = (): Record<string, Record<string, InputType> | string> => {
  return {
    after_renovation: afterRenovationInputs(),
    non_renovation: 'after_renovation',
    letter_renovation: 'after_renovation',
    cash_out_non_renovation: 'after_renovation',
    one_time_close_letter: 'after_renovation',
    funds_contribution: 'after_renovation',
    entity_resolution: {
      entityTakingTitle: {
        inputType: 'text',
        type: 'text',
        title: 'Entity Taking Title',
        value: '',
        error: '',
        required: true,
      },
      propertyAddress: {
        inputType: 'map',
        title: 'Property Address',
        value: '',
        error: '',
        required: true,
      },
      individualMemberList: {
        inputType: 'table',
        title: 'submit',
        value: [],
        inputs: {
          name: {
            inputType: 'text',
            title: 'Member Name',
            value: '',
            required: true,
          },
          email: {
            inputType: 'text',
            title: 'Member Email',
            type: 'email',
            value: '',
            required: true,
          },
          agreeDate: {
            inputType: 'text',
            title: 'Member Agree Date',
            type: 'date',
            value: '',
            required: true,
          },
        },
        required: true,
        span: 2,
      },
    },
    purpose_letter: 'after_renovation',
    purchase_price_loe: {
      ...afterRenovationInputs(),
      appraisedValue: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Appraised Value',
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
      purchasePrice: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Purchase Price',
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
    },
    ach_form: {
      borrowerInformation: {
        inputType: 'section',
        title: 'Borrower Information',
        span: 2,
      },
      name: {
        inputType: 'text',
        type: 'text',
        title: 'Name',
        value: '',
        error: '',
        required: true,
      },
      accountLoanNumber: {
        inputType: 'text',
        type: 'number',
        title: 'Account/Loan Number',
        value: '',
        error: '',
        required: true,
      },
      email: {
        inputType: 'text',
        type: 'email',
        title: 'E-mail Address',
        value: '',
        error: '',
        required: true,
      },
      phoneNumber: {
        inputType: 'text',
        type: 'phone',
        title: 'Phone Number',
        value: '',
        error: '',
        required: true,
      },
      borrowerAddress: {
        inputType: 'map',
        title: 'Borrower Address',
        value: '',
        error: '',
        required: true,
      },
      propertyAddress: {
        inputType: 'map',
        title: 'Property Address',
        value: '',
        error: '',
        required: true,
      },
      bankingAccountInformation: {
        inputType: 'section',
        title: 'Banking Account Information',
        span: 2,
      },
      bankName: {
        inputType: 'text',
        type: 'text',
        title: 'Bank Name',
        value: '',
        error: '',
        required: true,
      },
      routingNumber: {
        inputType: 'text',
        type: 'number',
        title: 'Routing Number',
        maxLength: 9,
        value: '',
        error: '',
        required: true,
      },
      accountNumber: {
        inputType: 'text',
        type: 'number',
        title: 'Account Number',
        minLength: 8,
        maxLength: 17,
        value: '',
        error: '',
        required: true,
      },
      accountType: {
        inputType: 'select',
        title: 'Account Type (checking/saving)',
        options: BankAccountTypeOptions,
        hasDefaultOption: true,
        value: '',
        error: '',
        required: true,
      },
      bankAddress: {
        inputType: 'map',
        title: 'Bank Address',
        value: '',
        error: '',
        required: true,
        span: 2,
      },
    },
    fci_form: {
      borrowerInformation: {
        inputType: 'section',
        title: 'Borrower Information',
        span: 2,
      },
      name: {
        inputType: 'text',
        type: 'text',
        title: 'Name',
        value: '',
        error: '',
        required: true,
      },
      accountLoanNumber: {
        inputType: 'text',
        type: 'number',
        title: 'Account/Loan Number',
        value: '',
        error: '',
        required: true,
      },
      email: {
        inputType: 'text',
        type: 'email',
        title: 'E-mail Address',
        value: '',
        error: '',
        required: true,
      },
      phoneNumber: {
        inputType: 'text',
        type: 'phone',
        title: 'Phone Number',
        value: '',
        error: '',
        required: true,
      },
      address: {
        inputType: 'map',
        title: 'Address',
        value: '',
        error: '',
        required: true,
        span: 2,
      },

      bankingAccountInformation: {
        inputType: 'section',
        title: 'Banking Account Information',
        span: 2,
      },
      accountType: {
        inputType: 'select',
        title: 'Account Type (checking/saving)',
        options: BankAccountTypeOptions,
        hasDefaultOption: true,
        value: '',
        error: '',
        required: true,
        span: 2,
      },
      bankName: {
        inputType: 'text',
        type: 'text',
        title: 'Bank Name',
        value: '',
        error: '',
        required: true,
      },
      accountName: {
        inputType: 'text',
        type: 'text',
        title: 'Name on Account',
        value: '',
        error: '',
        required: true,
      },
      bankRepresentative: {
        inputType: 'text',
        type: 'text',
        title: 'Bank Representative',
        value: '',
        error: '',
        required: true,
      },
      bankPhoneNumber: {
        inputType: 'text',
        type: 'phone',
        title: 'Bank Phone Number',
        value: '',
        error: '',
        required: true,
      },
      routingNumber: {
        inputType: 'text',
        type: 'number',
        key: 'bankRoutingNumber',
        title: 'Routing Number',
        maxLength: 9,
        value: '',
        error: '',
        required: true,
      },
      accountNumber: {
        inputType: 'text',
        type: 'number',
        title: 'Account Number',
        minLength: 8,
        maxLength: 17,
        value: '',
        error: '',
        required: true,
      },

      amountAndTerms: {
        inputType: 'section',
        title: 'Amount and Terms',
        span: 2,
      },
      accountDebited: {
        inputType: 'select',
        options: ['1', '2', '3', '4', '5'],
        title: 'Account will be debited of each month',
        hasDefaultOption: true,
        value: '',
        error: '',
        required: true,
      },
      startDateYear: {
        inputType: 'select',
        options: [],
        title: 'Start Year',
        hasDefaultOption: true,
        value: '',
        error: '',
        required: true,
      },
      startDateMonth: {
        inputType: 'select',
        options: monthNameMap,
        title: 'Start Month',
        hasDefaultOption: true,
        value: '',
        error: '',
        required: true,
      },
      monthlyPaymentAmount: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Monthly Payment Amount',
        value: '',
        error: '',
        prefix: '$',
        readOnly: true,
        required: true,
      },
    },
    legal_entity: {
      nameAndTitle: {
        inputType: 'section',
        title: 'Name and title of natural person opening account or maintaining the business relationship',
        span: 2,
      },
      borrowerName: {
        inputType: 'text',
        type: 'text',
        title: 'Borrower Name',
        value: '',
        error: '',
        required: true,
      },
      title: {
        inputType: 'text',
        type: 'text',
        title: 'Title',
        value: '',
        error: '',
        required: true,
      },

      identifierAndAddress: {
        inputType: 'section',
        title: 'Identifier and address of legal entity for which the account is being opened/maintained',
        span: 2,
      },
      entityName: {
        inputType: 'text',
        type: 'text',
        title: 'Entity Name',
        value: '',
        error: '',
        required: true,
      },
      entityAddress: {
        inputType: 'map',
        title: 'Entity Address',
        value: '',
        error: '',
        required: true,
      },

      listForEach: {
        inputType: 'section',
        title:
          'List for each individual member, if any, who, directly or indirectly, through any contract, arrangement, understanding, relationship or otherwise, owns 25 percent or more of the equity interests of the legal entity listed above',
        span: 2,
      },
      individualMemberList: {
        inputType: 'table',
        title: 'submit',
        value: [],
        inputs: {
          name: {
            inputType: 'text',
            title: 'Name',
            value: '',
            required: true,
          },
          ownership: {
            inputType: 'text',
            type: 'thousandSep',
            title: 'Ownership(%)',
            prefix: '%',
            value: '',
            required: true,
          },
          birthday: {
            inputType: 'text',
            type: 'date',
            title: 'Date of Birth',
            value: '',
            required: true,
          },
          address: {
            inputType: 'map',
            title: 'Address(Residential or Business)',
            value: '',
            required: true,
          },
          usSsn: {
            inputType: 'text',
            type: 'ssn',
            title: 'For U.S. Persons: Social Security Number',
            value: '',
            required: false,
          },
          foreignIdentification: {
            inputType: 'text',
            title: 'For Foreign Persons: Identification Number and Country',
            value: '',
            required: false,
          },
        },
        required: true,
        span: 2,
      },
      listForAny: {
        inputType: 'section',
        title: 'List for any individual / entity member who regularly performs management or similar functions',
        span: 2,
      },
      entityMemberList: {
        inputType: 'table',
        title: 'submit',
        value: [],
        inputs: {
          name: {
            inputType: 'text',
            title: 'Name',
            value: '',
            required: true,
          },
          title: {
            inputType: 'text',
            title: 'Title',
            value: '',
            required: true,
          },
          birthday: {
            inputType: 'text',
            type: 'date',
            title: 'Date of Birth',
            value: '',
            required: true,
          },
          address: {
            inputType: 'map',
            title: 'Address(Residential or Business)',
            value: '',
            required: true,
          },
          usSsn: {
            inputType: 'text',
            type: 'ssn',
            title: 'For U.S. Persons: Social Security Number',
            value: '',
            required: false,
          },
          foreignIdentification: {
            inputType: 'text',
            title: 'For Foreign Persons: Identification Number and Country',
            value: '',
            required: false,
          },
        },
        required: true,
        span: 2,
      },
    },
    tenant_estopple: {
      tenantName: {
        inputType: 'text',
        type: 'text',
        title: 'Tenant Name(will be used in sign)',
        value: '',
        error: '',
        required: true,
      },
      tenantEmail: {
        inputType: 'text',
        type: 'email',
        title: 'Tenant Email',
        value: '',
        error: '',
        required: true,
      },
      landlordName: {
        inputType: 'text',
        type: 'text',
        title: 'Landlord Name',
        value: '',
        error: '',
        required: true,
      },
      leaseName: {
        inputType: 'text',
        type: 'text',
        title: 'Lease Name',
        value: '',
        error: '',
        required: true,
      },
      squareFeet: {
        inputType: 'text',
        type: 'number',
        title: "Square feet of the 'Premises'",
        value: '',
        error: '',
        required: true,
      },
      propertyAddress: {
        inputType: 'map',
        title: 'Property Address',
        value: '',
        error: '',
        required: true,
      },
      leaseBeginDate: {
        inputType: 'text',
        type: 'date',
        title: 'Lease Begin Date',
        value: '',
        error: '',
        required: true,
      },
      leaseEndDate: {
        inputType: 'text',
        type: 'date',
        title: 'Lease End Date',
        value: '',
        error: '',
        required: true,
      },
      tenantOptions: {
        inputType: 'text',
        type: 'text',
        title: "Tenant's Options to Extend the Lease",
        value: '',
        error: '',
        required: true,
      },
      yearsOfExtention: {
        inputType: 'text',
        type: 'number',
        title: 'Years of Each Extention',
        value: '',
        error: '',
        required: true,
      },
      haveAnOption: {
        inputType: 'radio',
        title: '',
        options: {
          have: 'Tenant does have an option to lease other space at the Property',
          notHave: 'Tenant does not have an option to lease other space at the Property',
        },
        value: '',
        error: '',
        required: true,
        span: 2,
      },
      haveAPrefer: {
        inputType: 'radio',
        title: '',
        options: {
          have: 'Tenant does have a preferential right to purchase all or any part of the Premises or the Property',
          notHave:
            'Tenant does not have a preferential right to purchase all or any part of the Premises or the Property',
        },
        value: '',
        error: '',
        required: true,
        span: 2,
      },
      payingBase: {
        inputType: 'text',
        type: 'thousandSep',
        title: "Tenant's Paying Base Rent Under the Lease",
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
      estimatedTriple: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Estimated Triple Net Expenses Per Month',
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
      leaseProvides: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Lease Provides for a Base-Year or Expense Stop',
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
      hasPaidThrough: {
        inputType: 'text',
        type: 'number',
        title: 'Tenant Has Paid Through And Including the Month of',
        value: '',
        error: '',
        required: true,
      },
      securityDepositAmount: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'A Security Deposit Amount Has Been Paid to Landlord',
        value: '',
        error: '',
        prefix: '$',
        required: true,
      },
      entitledParking: {
        inputType: 'text',
        title: "Tenant's Entitled Parking Spaces",
        value: '',
        error: '',
        required: true,
      },
      parkingSpaces: {
        inputType: 'radio',
        title: '',
        options: {
          assigned: 'All of Tenant’s parking spaces are assigned',
          unassigned: 'All of Tenant’s parking spaces are unassigned',
        },
        value: '',
        error: '',
        required: true,
        span: 2,
      },
      spaceAdditionalCharge: {
        inputType: 'radio',
        title: '',
        options: {
          assigned: 'Tenant may use the spaces at no additional charge',
          unassigned: 'Tenant may use the spaces for ____$ per month per space',
        },
        value: '',
        error: '',
        required: true,
        span: 2,
      },
      spacesPerSpace: {
        inputType: 'text',
        type: 'thousandSep',
        title: 'Spaces for ____$ per month per space',
        value: '',
        error: '',
        prefix: '$',
        span: 2,
      },
      tenantAcknowledges: {
        inputType: 'text',
        type: 'text',
        title:
          'Tenant acknowledges that the information contained in this Estoppel Certificate is accurate, except as follows',
        value: '',
        error: '',
        required: true,
        span: 2,
      },
    },
    stock_ledger: {
      entityTakingTitle: {
        inputType: 'text',
        type: 'text',
        title: 'Entity Taking Title',
        value: '',
        error: '',
        required: true,
      },
      borrowerName: {
        inputType: 'text',
        type: 'text',
        title: 'Borrower Name',
        value: '',
        error: '',
        required: true,
      },
      shares: {
        inputType: 'table',
        title: 'submit',
        value: [],
        inputs: {
          shareHolders: {
            inputType: 'text',
            title: 'Share Holders',
            value: '',
            required: true,
          },
          ownership: {
            inputType: 'text',
            type: 'thousandSep',
            title: 'Ownership(%)',
            prefix: '%',
            value: '',
            required: true,
          },
        },
        required: true,
        span: 2,
      },
    },
  }
}
