import {
  API_PAYMENT_DATA,
  API_PAYMENT_FIND_DATA,
  API_STRIPE_CLIENT_SESSION_KEY,
  API_STRIPE_INTENT,
  API_STRIPE_INTENT_BY_ID,
} from 'config'
import Api from 'services/api'

export enum StripeMethod {
  us_bank_account = 'us_bank_account',
  card = 'card',
}

export enum PaymentOrderType {
  FeasibilitySign = 'feasibilitySign',
  LoanExtension = 'loanExtension',
  LoanSubmissionRush = 'loanSubmissionRush',
  PullCreditReport = 'pullCreditReport',
}

export const PaymentOrderTypeTitles: Record<PaymentOrderType, string> = {
  [PaymentOrderType.FeasibilitySign]: 'Feasibility Sign',
  [PaymentOrderType.LoanExtension]: 'Loan Extension',
  [PaymentOrderType.LoanSubmissionRush]: 'Loan Submission Rush',
  [PaymentOrderType.PullCreditReport]: 'Pull Credit Report',
}

export interface IMakePaymentData {
  orderType: PaymentOrderType
  refId: string
  email: string
  amount: number
  [key: string]: any
}

export interface IStripeIntent {
  id: number
  intentId: string
  email: string
  data: Record<string, any>
  orderType: PaymentOrderType
  refId: string
  method: StripeMethod
  amount: number
  status: StripeIntentStatus
  createdAt: Date
  history: Record<string, any>
  loan: {
    loanNumber: number
    byteproFileName: number
  } | null
}

export enum StripeIntentStatus {
  requires_action = 'requires_action',
  payment_failed = 'payment_failed',
  partially_funded = 'partially_funded',
  processing = 'processing',
  canceled = 'canceled',
  created = 'created',
  succeeded = 'succeeded',
  requires_payment_method = 'requires_payment_method',
}

export const StripeIntentPaidStatus = [
  StripeIntentStatus.created,
  StripeIntentStatus.partially_funded,
  StripeIntentStatus.processing,
  StripeIntentStatus.succeeded,
]

/**
 * @param method: us_bank_account | card
 */
export const getClientSessionKey = (data: {
  orderType: any
  refId: string
  method: string
  amount: number
  data: Record<string, any>
}) => {
  return Api.post(API_STRIPE_CLIENT_SESSION_KEY, data)
}

export const getStripeIntent = (id: number) => {
  return Api.get(API_STRIPE_INTENT_BY_ID, {}, { id })
}

export const getPaymentData = (data: Record<string, any>) => {
  return Api.post(API_PAYMENT_DATA, data)
}

export const updatePaymentStatus = (id: string) => {
  return Api.post(API_STRIPE_INTENT_BY_ID, {}, { id })
}

export const getStripeIntents = (filters: Record<string, any>) => {
  return Api.get(API_STRIPE_INTENT, filters)
}

export const findStripeIntents = (data: Record<string, any>): Promise<IStripeIntent[]> => {
  return Api.post(API_PAYMENT_FIND_DATA, data)
}
