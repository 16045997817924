import {
  API_ALL_ORDER_APPRAISAL2,
  API_FIND_NATIONAL_FLOOR,
  API_LOAN_GET_ALL_DATAVERIFY,
  API_STATISTICS_APPRAISAL2,
  API_VENDOR_CREDIT_REPORT,
  API_VENDOR_ENABLED_BY_TYPE,
  API_VENDOR_ENABLED_BY_TYPES,
  API_VENDOR_OPTIONS_Active_BY_TYPE_AND_KEY,
  API_VENDOR_OPTIONS_BY_TYPE,
  API_VENDOR_OPTIONS_BY_TYPE_PUBLIC,
  API_VENDOR_OPTIONS_DELECT_BY_TYPE_AND_KEY,
  API_VENDOR_OPTIONS_UPATE_BY_TYPE_AND_INDEX,
} from 'config'
import Api from 'services/api'

export const isVendorEnabled = async (type: string): Promise<boolean> => {
  const data = await Api.get(API_VENDOR_ENABLED_BY_TYPE, {}, { type })
  return data.success || false
}

export const getVendorsEnabled = (data: string[]) => {
  return Api.post(API_VENDOR_ENABLED_BY_TYPES, { data }, {})
}

export const vendorCreditReport = () => {
  return Api.get(API_VENDOR_CREDIT_REPORT)
}

export const getAllServiceLink = (filters: Record<string, any>) => {
  return Api.get(API_FIND_NATIONAL_FLOOR, filters)
}

export const getAllOrderAppraisal2 = (filters: Record<string, any>) => {
  return Api.get(API_ALL_ORDER_APPRAISAL2, filters)
}

export const getAppraisal2Statistics = (filters: Record<string, any>) => {
  return Api.get(API_STATISTICS_APPRAISAL2, filters)
}

export const getAllDataverifySSA = (type: string, filters: Record<string, any>) => {
  return Api.get(API_LOAN_GET_ALL_DATAVERIFY, filters, { type })
}

export const getVendorOptions = (type: string) => {
  return Api.get(API_VENDOR_OPTIONS_BY_TYPE, {}, { type })
}

export const getVendorOptionsPublic = (type: string) => {
  return Api.get(API_VENDOR_OPTIONS_BY_TYPE_PUBLIC, {}, { type })
}

export const updateVendorOptions = (type: string, data: Record<string, any>) => {
  return Api.post(API_VENDOR_OPTIONS_UPATE_BY_TYPE_AND_INDEX, data, { type })
}

export const deleteVendorOptions = (type: string, key: string) => {
  return Api.delete(API_VENDOR_OPTIONS_DELECT_BY_TYPE_AND_KEY, { key }, { type })
}

export const activeVendorOptions = (type: string, key: string) => {
  return Api.post(API_VENDOR_OPTIONS_Active_BY_TYPE_AND_KEY, { key }, { type })
}

export const enableVendorOptions = (type: string, enabled: boolean) => {
  return Api.post(API_VENDOR_ENABLED_BY_TYPE, { enabled }, { type })
}
