import { Overview } from 'components/Overview'
import { useEffect, useMemo, useState } from 'react'

import { FraudGaurd } from './FraudGaurd'
import { Lexis } from './Lexis'
import { SSA } from './SSA'
import { Tax } from './Tax'

type menuType = {
  [key: string]: string //fix this
}

const leftMenuItems: menuType = {
  ssa: 'DataVerify SSA',
  tax4506: 'DataVerify 4506',
  fraudGaurd: 'DataVerify Drive',
  lexis: 'SmartLinx Person Report',
}

export default function Dataverify() {
  const [selectedMenu, setSelectedMenu] = useState('')

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'ssa':
        return <SSA />
      case 'tax4506':
        return <Tax />
      case 'fraudGaurd':
        return <FraudGaurd />
      case 'lexis':
        return <Lexis />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu])

  useEffect(() => {
    setMenu(Object.keys(leftMenuItems)[0])
  }, [])

  return (
    <div className="dataverify-container px-2 py-6">
      <Overview title="DataVerify SSA / 4506" />
      <div className="dataverify-wrapper max-w-screen-2xl m-auto grid grid-cols-12 gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded">
          <ul className="sidebar-items flex flex-col p-4 pb-20">
            {Object.keys(leftMenuItems).map((item: string, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setMenu(item)
                  }}
                  className="border-b py-2"
                >
                  <p
                    className={`hover:underline cursor-pointer ${
                      selectedMenu === item ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                    }`}
                  >
                    {index + 1}. {leftMenuItems[item as keyof typeof leftMenuItems]}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1">{renderFragment}</div>
      </div>
    </div>
  )
}
