import cloneDeep from 'clone-deep'
import type { FormTableHeader } from 'stories/components'

export const AppraisalDocumentCategories = [
  'Appraisal',
  'Architectural Plans',
  'Reconsideration of Value',
  'Lease Agreement/Rent Roll',
  'Sales Contract',
  'Scope Of Work',
  'Survey',
  'Title Report',
  'Payment Authorization',
]

export const Appraisal2ThridPartyType: Record<string, string> = {
  AppraisalNation: 'AppraisalNation',
  EliteValuation: 'EliteValuation',
  Trinity: 'Trinity',
  NationWide: 'NationWide',
}

export const Appraisal2StatusType: Record<string, string> = {
  acceptedWConditions: 'Accepted W/ Conditions',
  accepted: 'Accepted',
  appointmentSet: 'Appointment Set',
  assigned: 'Assigned',
  bidPackageDelivered: 'Bid Package Delivered',
  bidPackageReceived: 'Bid Package Received',
  cancelled: 'Cancelled',
  completed: 'Completed',
  declined: 'Declined',
  onHold: 'On Hold',
  inspected: 'Inspected',
  newOrder: 'New Order',
  pendingAssignment: 'Pending Assignment',
  paymentAuthorized: 'Payment Authorization Received',
  pendingPayment: 'Pending Payment',
  paymentReceived: 'Payment Received',
  qcAssigned: 'QC Assigned',
  qcCompleted: 'QC Completed',
  qcReview: 'QC Review',
  qcRevision: 'QC Revision',
  reconsiderationOfValue: 'Reconsideration of Value',
  revision: 'Revision',
  revisionsReceived: 'Revisions Received',
  underwritingCondition: 'Underwriting Condition',
}

export const Appraisal2StatusTypeColor: Record<string, string> = {
  acceptedWConditions: 'slate',
  accepted: 'green',
  appointmentSet: 'zinc',
  assigned: 'sky',
  bidPackageDelivered: 'blue',
  bidPackageReceived: 'orange',
  cancelled: 'slate',
  completed: 'blue',
  declined: 'red',
  onHold: 'gray',
  inspected: 'green',
  newOrder: 'gray',
  pendingAssignment: 'zinc',
  paymentAuthorized: 'orange',
  pendingPayment: 'yellow',
  paymentReceived: 'purple',
  qcAssigned: 'sky',
  qcCompleted: 'blue',
  qcReview: 'red',
  qcRevision: 'yellow',
  reconsiderationOfValue: 'stone',
  revision: 'purple',
  revisionsReceived: 'sky',
  underwritingCondition: 'purple',
}

export const CCOptions = ['Visa', 'Mastercard', 'AMEX', 'Discover']

export const feasibilityOrderTypes = ['Feasibility Study (FNF)', 'Budget Review (GUC)']

export const feasibilityRequiredKeys = ['feasibilityEmailToLink']

export const feasibilityKeys = [
  'feasibilityTitle',
  'feasibilityType',
  // 'feasibilitySignerName',
  ...feasibilityRequiredKeys,
]

export const feasibilityACHKeys = [
  'feasibilityACHPaymentAmount',
  'feasibilityACHFinancialInstitution',
  'feasibilityACHAccount',
  'feasibilityACHRoutingNumber',
  'feasibilityACHAccountNumber',
  'feasibilityACHAccountType',
]

export const feasibilityCCKeys = [
  'feasibilityCCType',
  'feasibilityCCStudyFee',
  'feasibilityCCFirstName',
  'feasibilityCCLastName',
  'feasibilityCCCardNumber',
  'feasibilityCCCardCode',
  'feasibilityCCExpMonth',
  'feasibilityCCExpYear',
  'feasibilityCCBillingAddress',
]

export const feasibilityAllKeys = [...feasibilityKeys, ...feasibilityACHKeys, ...feasibilityCCKeys]

export const Appraisal2StatusTypeWithGroup = () => {
  let Active = cloneDeep(Appraisal2StatusType)
  delete Active.cancelled
  delete Active.completed
  return {
    Active,
    Archived: {
      cancelled: 'Cancelled',
      completed: 'Completed',
    },
  }
}

export const budgetReviewOptions = ['Budget Review (GUC)']

export const budgetReviewAmounts = [600]

export const feasibilityStudyOptions = [
  'Feasibility Study (1-4 FNF <=750k)',
  'Engineer Project Review (> 750k 1-4 FNF)',
  'Feasibility Study + GC Review <=700k SFR GUC',
  'Feasibility Study + GC Review  >700k SFR GUC',
  'Feasibility Study + GC Review <=500k 2-4 Unit GUC',
  'Feasibility Study + GC Review >500k 2-4 Unit GUC',
  'Feasibility Study + GC Review <=1,000,000k 5+ Unit / Mixed Use GUC',
  'Feasibility Study + GC Review >1,000,000k 5+ Unit / Mixed Use GUC',
]

export const feasibilityStudyAmounts = [600, 2200, 600, 4500, 750, 4500, 750, 5500]

export const mapOrderTypeFormOptions: any = {
  'Budget Review (GUC)': budgetReviewOptions,
  'Collateral Desktop Analysis': ['CDA'],
  'Desk Review': ['Desk'],
  'Draw Inspection': ['Draw Inspection'],
  'Commercial Appraisal': ['Commercial Narrative'],
  'Residential Appraisal': [
    'FNMA 1004 URAR',
    'FNMA 1004 URAR Jumbo',
    'FNMA 1004 SFR Inv with 1007',
    'FNMA 1004 SFR Inv with 216',
    'FNMA 1004 SFR Inv with 216 & 1007',
    'FNMA 1004C Manufactured Housing',
    'Single Family 1004 As is + ARV',
    'FNMA 1073 Condo',
    'FNMA 1073 URAR Jumbo',
    'Condo 1073 As is + ARV',
    'FNMA 1073 with 1007',
    'FNMA 1073 with 216',
    'FNMA 1073 SFR Inv with 216 & 1007',
    'FNMA 1075 Condo Exterior Only',
    'FNMA 1025 2-4 Family',
    'FNMA 1025 2-4 Family As is + ARV',
    'FNMA 2055 Exterior Only',
    'FNMA 2075 Drive-By',
    'FNMA 1004D Final Inspection',
    'FNMA 1004D - Recert of Value',
    'Field Review 2000 SFR',
    'FNMA 216 - OIS',
    'FNMA 1007 - Rent Schedule',
    'Disaster Area Inspection Form',
    'Land Appraisal Form',
  ],
  'Feasibility Study (FNF)': feasibilityStudyOptions,
  'Broker Price Opinion (BPO)': [
    'Drive-by. Comm Sale and Income Eval (Tier 1)',
    'Drive-by. Comm Sale and Income Eval (Tier 2)',
    'Drive-by. Comm Sale Only Eval (Tier 1)',
    'Drive-by. Comm Sale Only Eval (Tier 2)',
    'Drive-by. Commercial Sales & Income BPO',
    'Drive-by. Commercial Sales Only BPO',
    'Drive-by. Commercial Sales w/Listings BPO',
    'Drive-by. Residential BPO',
    'Drive-by. Residential Evaluation',
    'Interior. Comm Sales and Income Eval (Tier 1)',
    'Interior. Comm Sales and Income Eval (Tier 2)',
    'Interior. Comm Sales Only Eval (Tier 1)',
    'Interior. Comm Sales Only Eval (Tier 2)',
    'Interior. Commercial Sales & Income BPO',
    'Interior. Commercial Sales Only BPO',
    'Interior. Commercial Sales w/Listings BPO',
    'Interior. Residential BPO',
    'Interior. Residential Evaluation',
  ],
}

export const mapLoanToAppraisal: any = {
  occupancy: {
    'Owner Occupied': 'Primary',
    'Non Owner Occupied': 'Investment',
    'Partially Owner Occupied': 'Secondary',
    Vacant: 'Investment',
    'Partially Vacant': 'Investment',
  },
}

export const header = (): FormTableHeader[] => [
  {
    key: 'orderDueDate',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Order Date</div>
        Due Date
      </span>
    ),
  },
  { key: 'id', title: 'Order ID' },
  {
    key: 'orderTypeForm',
    title: (
      <span>
        <div className="border-b w-fit mb-1 border-gray-300">Order Type</div>
        Appraisal Form
      </span>
    ),
  },
  // { key: 'appraisalForm', title: 'Appraisal Form' },
  { key: 'vendor', title: 'Vendor' },
  { key: 'status', title: 'Status' },
  { key: 'createdBy', title: 'By/Date' },
]

export const brokerCanNotEditFields = [
  'inspectionDate',
  'acceptedDate',
  'receivedByBorrowerDate',
  'waiverDate',
  'completeDate',
]

export const cardFields = [
  'cardFirstName',
  'cardLastName',
  'cardEmail',
  'paymentAmount',
  'cardNumber',
  'cardCode',
  'expMonth',
  'expYear',
  'ccType',
  'billingAddress',
]
export const paymentInformationFormKeys = ['dividerPay', 'isSendInvoice', 'paymentLinkEmail', ...cardFields]

export const contactPersonFields: any = {
  contactFirstName: 'borrowerFirstName',
  contactLastName: 'borrowerLastName',
  contactEmail: 'borrowerEmail',
  contactPhone: 'borrowerPhone',
}

export const OrderAppraisalOrderTypeOptions = [
  'Budget Review (GUC)',
  'Commercial Appraisal',
  'Collateral Desktop Analysis',
  'Draw Inspection',
  'Desk Review',
  'Residential Appraisal',
  'Feasibility Study (FNF)',
  'Broker Price Opinion (BPO)',
]

export const OrderAppraisalVendorOptions = [
  'AEI Consultants',
  'Appraisal Nation',
  'BBG Real Estate Services',
  'Bowery Valuations',
  'CBRE Commercial Real Estate Services',
  'CFSI Management',
  'Clear Capital',
  'Colliers',
  'Cushman & Wakefield',
  'Elite Valuations',
  'Hillman Consulting',
  'IRR Integra Realty Resources',
  'KOW Building Consultants',
  'Land Gorilla',
  'Nationwide Appraisal Network (NAN)',
  'Newmark Knight Frank',
  'Partners ESI',
  'Richer Values',
  'Trinity',
]

export interface FeasibilityHistory {
  id: number
  method: 'Sign' | 'Pay'
  type: 'ACH' | 'Credit Card'
  data: Record<string, any>
  fileKey: string
  createdAt: number
}

export const StripeStatusLabels: Record<string, string[]> = {
  succeeded: ['green', 'Succeeded'],
  processing: ['blue', 'Processing'],
  partially_funded: ['blue', 'Partially Funded'],
  requires_action: ['yellow', 'Requires Action'],
  canceled: ['gray', 'Cancelled'],
  payment_failed: ['red', 'Failed'],
  requires_payment_method: ['gray', 'Incomplete'],
  refunded: ['red', 'Refunded'],
}
