import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { usePermissions } from 'hooks/usePermissions'
import { useEffect, useState } from 'react'
import { isVendorEnabled } from 'services'

export const SitexProLink = ({ address, onClick }: { address: string; onClick: Function }) => {
  const [isEnabled, setEnabled] = useState(false)

  const { hasPermission } = usePermissions()
  const canViewSitexPro = hasPermission('CAN_VIEW_SITEXPRO')

  useEffect(() => {
    isVendorEnabled('SitexPro').then(setEnabled)
  }, [])

  if (!address || !isEnabled || !canViewSitexPro) return null

  return (
    <div className="flex items-center justify-end gap-1 text-shade-blue hover:underline cursor-pointer">
      <span className="font-semibold" onClick={() => onClick()}>
        SitexPro
      </span>
      <ArrowDownTrayIcon className="w-3 h-3" />
    </div>
  )
}
