import { PrinterIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'
import { downloadFile, downloadNetFundingSheet, gethud1PageData, posthud1Page1Data } from 'services'
import Api from 'services/api'
import { svgLoading } from 'stories/assets'
import { Button, PlainTable } from 'stories/components'
import { ClosingCostsDetailsModal } from 'stories/components/ClosingCostsDetailsModal/ClosingCostsDetailsModal'
import { InterimInterestModal } from 'stories/components/InterimInterestModal'
import { MIPFundingFeeModal } from 'stories/components/MIPFundingFeeModal'
import { PrepaidItemsDetailsModal } from 'stories/components/PrepaidItemDetailsModal/PrepaidItemDetailsModal'
import { formatDate, getPrice1or2decimal, getPrice2decimal, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

const tableHeader = ['', 'Total', 'Lender Paid', 'P.O.C.', 'Net From Wire']

export function Funding() {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])

  const [showFeeWithValue, setShowFeeWithValue] = useState(true)
  const [showEscrowedItem, setShowEscrowedItem] = useState(true)

  const [ccData, setCCData] = useState<Array<Array<any>>>([])
  const [ppData, setPPData] = useState<Array<Array<any>>>([])

  const [ClosingCosts, setClosingCosts] = useState<Array<Record<string, string>>>([])
  const [PrepaidItems, setPrepaidItems] = useState<Array<Record<string, string>>>([])
  const [Parties, setParties] = useState<Array<Record<string, string>>>([])
  const [closingCostsDetailData, setClosingCostsDetailData] = useState<any>({})
  const [prepaidItemsDetailData, setPrepaidItemsDetailData] = useState<any>({})
  const [showMIPFundingModal, setShowMIPFundingModal] = useState(false)
  const [showInterimInterestModal, setShowInterimInterestModal] = useState(false)
  const [totalCCNetfromWires, setTotalCCNetfromWire] = useState(0)

  // const [convData, setConvData] = useState<Array<Array<any>>>([
  //   ['Conv. UFMIP', '0.00', '', '', '0.00'],
  //   ['Conv. UFMIP Paid in Cash', '0.00', '', '', '0.00'],
  // ])

  const loanNumber = Api.getLoanNumber()
  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const res = await gethud1PageData('funding')
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setOrgData(res.data)
      setClosingCosts(res.ClosingCosts)
      setPrepaidItems(res.PrepaidItems)
      setParties(res.Parties)
      setIDs(res.IDs)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    const tempData: Array<any> = []
    const totalRow: Array<any> = [
      <p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">Total</p>,
      0,
      0,
      0,
      0,
    ]
    inputs['closingCosts']?.map((item: any) => {
      if (!showFeeWithValue || data[item.feeBKey] + data[item.feeSKey] != 0) {
        const row: any = [
          <span
            className="cursor-pointer hover:text-shade-blue hover:underline"
            onClick={() => {
              setClosingCostsDetailData({
                hudNo: { value: data[item.lineNoKey], key: item.lineNoKey },
                lineName: { value: item.title, key: 'title' },
                isPoints: {
                  value:
                    (data[item.lineNoKey] == 801 && [32, 34].includes(data[item.ClosingCostTypeKey])) ||
                    data[item.lineNoKey] == 802,
                  key: 'isPoints',
                },
                points: { value: data[item.feeMultipleKey], key: item.feeMultipleKey },
                borrowerAmount: { value: data[item.feeBKey], key: item.feeBKey },
                sellerAmount: { value: data[item.feeSKey], key: item.feeSKey },
                TRIDBlock: { value: data[item.TRIDBlockKey], key: item.TRIDBlockKey },
                GFEBlock: { value: data[item.GFEBlockKey], key: item.GFEBlockKey },
                ClosingCostType: { value: data[item.ClosingCostTypeKey], key: item.ClosingCostTypeKey },
                PaidToType: { value: data[item.PaidToTypeKey], key: item.PaidToTypeKey },
                Financed: { value: data[item.FinancedKey], key: item.FinancedKey },
                ResponsiblePartyType: { value: data[item.ResponsiblePartyTypeKey], key: item.ResponsiblePartyTypeKey },
                NetFromWire: { value: data[item.NetFromWireKey], key: item.NetFromWireKey },
                NotCounted: { value: data[item.NotCountedKey], key: item.NotCountedKey },
                ProviderChosenByBorrower: {
                  value: data[item.ProviderChosenByBorrowerKey],
                  key: item.ProviderChosenByBorrowerKey,
                },
                PaidByOtherType: { value: data[item.PaidByOtherTypeKey], key: item.PaidByOtherTypeKey },
                poc: { value: data[item.pocKey], key: item.pocKey },
                PPFC: { value: data[item.PPFCKey], key: item.PPFCKey },
                lineId: { value: data[item.lineIdKey], key: item.lineIdKey },
                PointsAndFeesAmountOV: {
                  value: data[item.PointsAndFeesAmountOVKey],
                  key: item.PointsAndFeesAmountOVKey,
                },
                PaidTo: { value: data[item.PaidToNameKey], key: item.PaidToNameKey },
                isPOCDisabled: { value: item?.isPOCDisabled, key: '' },
                partyType: { value: item?.partyType, key: '' },
                partyName: { value: data[item.defaultValueKey], key: '' },
                ccid: { value: item.ccid, key: '' },
              })
            }}
          >{`${item.title ? item.title : data[item.titleKey]} (${item.key})`}</span>,
          getPrice2decimal(+data[item.feeBKey] + +data[item.feeSKey], false, true),
          getPrice2decimal(data[item.PaidByOtherTypeKey] == 1 ? data[item.feeSKey] : '', false, true),
          getPrice2decimal(data[item.pocKey] == true ? +data[item.feeBKey] + +data[item.feeSKey] : '', false, true),
          getPrice2decimal(
            data[item.NetFromWireKey] == true && data[item.pocKey] == false
              ? +data[item.feeBKey] + +data[item.feeSKey]
              : '',
            false,
            true,
          ),
        ]
        for (let i = 1; i < 5; i++) totalRow[i] = Number(totalRow[i]) + Number(removeComma(row[i]))
        tempData.push(row)
      }
    })
    if (!showFeeWithValue) tempData.push(['Mortgage Insurance (902)', '', '', '', ''])
    inputs['prepaidItems']?.map((item: any, index: number) => {
      if (![0, 5].includes(index)) return
      const prefix = `PrepaidItems.${index}.`
      if (
        showFeeWithValue &&
        !Number(data[`${prefix}PremiumOV`] || data[`${prefix}MonthsInAdvance`] * data[`${prefix}Payment`])
      )
        return
      const row = [
        <span
          className="cursor-pointer hover:text-shade-blue hover:underline"
          onClick={() =>
            setPrepaidItemsDetailData({
              title: [3, 7].includes(data[`${prefix}PrepaidItemType`]) ? data[`${prefix}NameOV`] : item,
              payment: { value: data[`${prefix}Payment`], key: `${prefix}Payment` },
              periodicPayment: {
                value: data[`${prefix}PeriodicPaymentAmount`],
                key: `${prefix}PeriodicPaymentAmount`,
              },
              disbSched: { value: data[`${prefix}DisbursementSched`], key: `${prefix}DisbursementSched` },
              starting: { value: data[`${prefix}DisbursementStartPur`], key: `${prefix}DisbursementStartPur` },
              cushion: { value: data[`${prefix}_Cushion`], key: `${prefix}_Cushion` },
              cushionOV: { value: data[`${prefix}CushionOV`], key: `${prefix}CushionOV` },
              monthsInAdvance: { value: data[`${prefix}MonthsInAdvance`], key: `${prefix}MonthsInAdvance` },
              premiumOV: { value: data[`${prefix}PremiumOV`], key: `${prefix}PremiumOV` },
              premiumPBS: { value: data[`${prefix}PremiumPBSDesired`], key: `${prefix}PremiumPBSDesired` },
              premiumPOC: { value: data[`${prefix}PremiumPOC`], key: `${prefix}PremiumPOC` },
              netFromWire: { value: data[`${prefix}PremiumNetFromWire`], key: `${prefix}PremiumNetFromWire` },
              paidTo: { value: data[`${prefix}PremiumPaidToType`], key: `${prefix}PremiumPaidToType` },
              premPointsAndFeesOV: {
                value: data[`${prefix}PremiumPointsAndFeesAmountOV`],
                key: `${prefix}PremiumPointsAndFeesAmountOV`,
              },
              monthsInReserve: { value: data[`${prefix}MonthsInReserve`], key: `${prefix}MonthsInReserve` },
              reserveOV: { value: data[`${prefix}ReservesOV`], key: `${prefix}ReservesOV` },
              reservePBS: { value: data[`${prefix}ReservesPBSDesired`], key: `${prefix}ReservesPBSDesired` },
              reservesPOC: { value: data[`${prefix}ReservesPOC`], key: `${prefix}ReservesPOC` },
              resPointsAndFeesOV: {
                value: data[`${prefix}ReservesPointsAndFeesAmountOV`],
                key: `${prefix}ReservesPointsAndFeesAmountOV`,
              },
              atrNotes: { value: data[`${prefix}QMATRNotes`], key: `${prefix}QMATRNotes` },
              premiumPaidByOther: {
                value: data[`${prefix}PremiumPaidByOtherType`],
                key: `${prefix}PremiumPaidByOtherType`,
              },
              reservePaidByOther: {
                value: data[`${prefix}ReservesPaidByOtherType`],
                key: `${prefix}ReservesPaidByOtherType`,
              },
              prepaidItemType: {
                value: data[`${prefix}MISMOPrepaidItemTypeOV`],
                key: `${prefix}MISMOPrepaidItemTypeOV`,
              },
              disbStartYear: {
                value: data[`${prefix}DisbursementStartYear`],
                key: `${prefix}DisbursementStartYear`,
              },
              disbPeriods: { value: data[`${prefix}DisbursementPeriods`], key: `${prefix}DisbursementPeriods` },
              disbList: { value: data[`${prefix}DisbursementList`], key: `${prefix}DisbursementList` },
            })
          }
        >
          {item || data[`${prefix}NameOV`]}
          {` (${index == 0 ? '903' : '904'})`}
        </span>,
        data[`${prefix}PremiumOV`] || data[`${prefix}MonthsInAdvance`] * data[`${prefix}Payment`] || '',
        data[`${prefix}PremiumPaidByOtherType`] == 1 ? data[`${prefix}PremiumPBSDesired`] : '',
      ]
      row.push(data[`${prefix}PremiumPOC`] == false ? '' : row[1] || '')
      row.push(
        data[`${prefix}PremiumPOC`] == true
          ? ''
          : data[`${prefix}PremiumNetFromWire`] == true
          ? data[`${prefix}PremiumPaidByOtherType`] == 1
            ? Number(row[1]) - 2 * +data[`${prefix}PremiumPBSDesired`]
            : Number(row[1])
          : data[`${prefix}PremiumPaidByOtherType`] == 1
          ? -Number(data[`${prefix}PremiumPBSDesired`])
          : '',
      )
      totalRow[1] = Number(totalRow[1]) + Number(row[1])
      totalRow[2] = Number(totalRow[2]) + Number(row[2])
      totalRow[4] = Number(totalRow[4]) + Number(row[4])
      for (let i = 1; i < 5; i++) row[i] = getPrice2decimal(row[i], false, true)
      tempData.push(row)
    })
    setTotalCCNetfromWire(totalRow[4])
    for (let i = 1; i < 5; i++)
      totalRow[i] = (
        <p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">
          ${getPrice1or2decimal(totalRow[i], false, true)}
        </p>
      )
    tempData.push(totalRow)
    setCCData(tempData)
  }, [showFeeWithValue, data])

  useEffect(() => {
    const tempData: Array<any> = []
    const totalRow: Array<any> = [
      <p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">Total</p>,
      0,
      0,
      0,
      0,
    ]
    inputs['prepaidItems']?.map((item: any, index: number) => {
      const prefix = `PrepaidItems.${index}.`
      if (
        showEscrowedItem &&
        !Number(data[`${prefix}ReservesOV`] || data[`${prefix}MonthsInReserve`] * data[`${prefix}Payment`])
      )
        return
      const row = [
        <span
          className="cursor-pointer hover:text-shade-blue hover:underline"
          onClick={() =>
            setPrepaidItemsDetailData({
              title: [3, 7].includes(data[`${prefix}PrepaidItemType`]) ? data[`${prefix}NameOV`] : item,
              payment: { value: data[`${prefix}Payment`], key: `${prefix}Payment` },
              periodicPayment: {
                value: data[`${prefix}PeriodicPaymentAmount`],
                key: `${prefix}PeriodicPaymentAmount`,
              },
              disbSched: { value: data[`${prefix}DisbursementSched`], key: `${prefix}DisbursementSched` },
              starting: { value: data[`${prefix}DisbursementStartPur`], key: `${prefix}DisbursementStartPur` },
              cushion: { value: data[`${prefix}_Cushion`], key: `${prefix}_Cushion` },
              cushionOV: { value: data[`${prefix}CushionOV`], key: `${prefix}CushionOV` },
              monthsInAdvance: { value: data[`${prefix}MonthsInAdvance`], key: `${prefix}MonthsInAdvance` },
              premiumOV: { value: data[`${prefix}PremiumOV`], key: `${prefix}PremiumOV` },
              premiumPBS: { value: data[`${prefix}PremiumPBSDesired`], key: `${prefix}PremiumPBSDesired` },
              premiumPOC: { value: data[`${prefix}PremiumPOC`], key: `${prefix}PremiumPOC` },
              netFromWire: { value: data[`${prefix}PremiumNetFromWire`], key: `${prefix}PremiumNetFromWire` },
              paidTo: { value: data[`${prefix}PremiumPaidToType`], key: `${prefix}PremiumPaidToType` },
              premPointsAndFeesOV: {
                value: data[`${prefix}PremiumPointsAndFeesAmountOV`],
                key: `${prefix}PremiumPointsAndFeesAmountOV`,
              },
              monthsInReserve: { value: data[`${prefix}MonthsInReserve`], key: `${prefix}MonthsInReserve` },
              reserveOV: { value: data[`${prefix}ReservesOV`], key: `${prefix}ReservesOV` },
              reservePBS: { value: data[`${prefix}ReservesPBSDesired`], key: `${prefix}ReservesPBSDesired` },
              reservesPOC: { value: data[`${prefix}ReservesPOC`], key: `${prefix}ReservesPOC` },
              resPointsAndFeesOV: {
                value: data[`${prefix}ReservesPointsAndFeesAmountOV`],
                key: `${prefix}ReservesPointsAndFeesAmountOV`,
              },
              atrNotes: { value: data[`${prefix}QMATRNotes`], key: `${prefix}QMATRNotes` },
              premiumPaidByOther: {
                value: data[`${prefix}PremiumPaidByOtherType`],
                key: `${prefix}PremiumPaidByOtherType`,
              },
              reservePaidByOther: {
                value: data[`${prefix}ReservesPaidByOtherType`],
                key: `${prefix}ReservesPaidByOtherType`,
              },
              prepaidItemType: {
                value: data[`${prefix}MISMOPrepaidItemTypeOV`],
                key: `${prefix}MISMOPrepaidItemTypeOV`,
              },
              disbStartYear: {
                value: data[`${prefix}DisbursementStartYear`],
                key: `${prefix}DisbursementStartYear`,
              },
              disbPeriods: { value: data[`${prefix}DisbursementPeriods`], key: `${prefix}DisbursementPeriods` },
              disbList: { value: data[`${prefix}DisbursementList`], key: `${prefix}DisbursementList` },
            })
          }
        >
          {item || data[`${prefix}NameOV`]}
        </span>,
        data[`${prefix}ReservesOV`] || data[`${prefix}MonthsInReserve`] * data[`${prefix}Payment`] || '',
        data[`${prefix}ReservesPaidByOtherType`] == 1 ? data[`${prefix}ReservesPBSDesired`] : '',
        '',
      ]
      row.push(
        (data[`${prefix}ReservesPOC`] == true || !data['Secondary.EscrowDepositIsNettedFromWire']
          ? ''
          : (row[1] || 0) - (row[2] || 0)) || '',
      )
      totalRow[1] = Number(totalRow[1]) + Number(row[1])
      totalRow[2] = Number(totalRow[2]) + Number(row[2])
      totalRow[4] = Number(totalRow[4]) + Number(row[4])
      for (let i = 1; i < 5; i++) row[i] = getPrice2decimal(row[i], false, true)
      tempData.push(row)
    })
    const aggregateAdjustment = Math.round((calculateAggregateAdjustment() || 0) * 100) / 100
    tempData.push([
      'Aggregate Adjustment',
      getPrice2decimal(aggregateAdjustment, false, true),
      '',
      '',
      data['Secondary.EscrowDepositIsNettedFromWire'] ? getPrice2decimal(aggregateAdjustment, false, true) : '',
    ])
    totalRow[1] += aggregateAdjustment
    totalRow[4] += data['Secondary.EscrowDepositIsNettedFromWire'] ? aggregateAdjustment : 0
    for (let i = 1; i < 5; i++)
      totalRow[i] = (
        <p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">
          ${getPrice1or2decimal(totalRow[i], false, true)}
        </p>
      )
    tempData.push(totalRow)
    setPPData(tempData)
  }, [showEscrowedItem, data])

  const convData = () => {
    const tableData: any = [
      [
        <span
          className="cursor-pointer hover:text-shade-blue hover:underline"
          onClick={() => setShowMIPFundingModal(true)}
        >
          Conv. UFMIP
        </span>,
        '0.00',
        '',
        '',
        '0.00',
      ],
      [
        <span
          className="cursor-pointer hover:text-shade-blue hover:underline"
          onClick={() => setShowMIPFundingModal(true)}
        >
          Conv. UFMIP Paid in Cash
        </span>,
        getPrice2decimal(data['Loan.MIPFFPaidInCash'] || 0, false, true),
        data['Loan.MIPFFPaidInCashPaidByOtherType'] == 1
          ? getPrice2decimal(data['Loan.MIPFFPaidInCashPBSDesired'] || 0, false, true)
          : '',
        '',
        data['Secondary.MIPFFIsNettedFromWire']
          ? getPrice2decimal(
              Number(data['Loan.MIPFFPaidInCash']) -
                (data['Loan.MIPFFPaidInCashPaidByOtherType'] == 1
                  ? Number(data['Loan.MIPFFPaidInCashPBSDesired'] || 0)
                  : 0),
              false,
              true,
            )
          : '0.00',
        ,
      ],
    ]
    const totalRow = [<p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">Total</p>]
    for (let i = 1; i < 5; i++)
      totalRow[i] = (
        <p className="border-t-2 border-slate-500 border-separate pt-2 font-bold">
          ${getPrice2decimal(tableData[1][i], false, true)}
        </p>
      )
    tableData.push(totalRow)
    return tableData
  }

  const interimInterestData = () => {
    return [
      [
        <span
          className="cursor-pointer hover:text-shade-blue hover:underline"
          onClick={() => setShowInterimInterestModal(true)}
        >
          Interim Interest
        </span>,
        getPrice2decimal(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest'), false, true),
        getPrice2decimal(
          data['Loan.InterimInterestPaidByOtherType'] == 1 ? data['Loan.InterimInterestPBSDesired'] : '0.00',
          false,
          true,
        ),
        '',
        data['Secondary.InterimInterestIsNettedFromWire']
          ? getPrice2decimal(
              Number(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest')) -
                Number(data['Loan.InterimInterestPaidByOtherType'] == 1 ? data['Loan.InterimInterestPBSDesired'] : 0),
              false,
              true,
            )
          : '0.00',
      ],
    ]
  }

  const onDeleteFee = () => {
    initData()
    toast('Successfully deleted fee', { type: 'success' })
  }

  const onChange = (key: string, value: any, type?: string, orgData?: Record<string, any>) => {
    const hasOrgData = !!orgData
    if (!orgData) orgData = data
    let temp = cloneDeep(orgData!)

    const params = key.split('.')
    if (params.length > 2 && params[2] == 'CushionOV') value = value.split('.')[0]

    if (type === 'number') temp[key] = getPrice1or2decimal(value).replace(',', '')
    else temp[key] = value

    if (!hasOrgData) {
      setData(temp)
      if (!changed) setChanged(true)
    } else return temp
  }

  const onSaveChanges = async () => {
    setChanged(false)
    let json: any = {}
    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) json[key] = data[key]
    })
    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      json.ClosingCosts = ClosingCosts
      json.PrepaidItems = PrepaidItems
      json.Parties = Parties
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
    }
  }

  const closeCostingDetailModal = (save: false, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((v) => {
        const { key, value } = detailData[v]
        if (data[key] !== value && Object.keys(data).includes(key)) {
          newData = onChange(key, value, '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setPrepaidItemsDetailData({})
    setClosingCostsDetailData({})
  }

  const closeMIPFundingModal = (save: boolean, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((key) => {
        if (data[key] !== detailData[key]) {
          newData = onChange(key, detailData[key], '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setShowMIPFundingModal(false)
    setShowInterimInterestModal(false)
  }

  const calculateAggregateAdjustment = () => {
    const firstPaymentMonth = Number(formatDate(data['Loan.FirstPaymentDate'], 'M'))
    const firstPaymentYear = Number(formatDate(data['Loan.FirstPaymentDate'], 'YYYY'))
    const closingDateMonth = Number(formatDate(data['Status.SchedClosingDate'], 'M'))
    const closingDateYear = Number(formatDate(data['Status.SchedClosingDate'], 'YYYY'))

    let totalCush = 0
    let paymentToEscrow: number = 0
    let paymentFromEscrows: Array<number> = []
    for (let i = 0; i < 12; i++) {
      paymentFromEscrows.push(0)
    }
    for (let i = 0; i < 7; i++) {
      const disbursementSched = data[`PrepaidItems.${i}.DisbursementSched`]
      const payment = Number(data[`PrepaidItems.${i}.Payment`])
      const disbursementStartPur = data[`PrepaidItems.${i}.DisbursementStartPur`]
      const disbursementStartYear = data[`PrepaidItems.${i}.DisbursementStartYear`]
      const disbursementPeriods = data[`PrepaidItems.${i}.DisbursementPeriods`]
      const disbursementList = data[`PrepaidItems.${i}.DisbursementList`]?.split('|')
      const cushion = data[`PrepaidItems.${i}.CushionOV`] || data[`PrepaidItems.${i}._Cushion`] || 0
      if (disbursementSched > 1) totalCush += cushion * payment

      if (disbursementSched > 1) paymentToEscrow += payment
      if (disbursementSched == 2) {
        for (let j = 0; j < 12; j++) {
          paymentFromEscrows[j] += payment
        }
      } else if ([3, 4, 5].includes(Number(disbursementSched))) {
        if (disbursementStartPur == 0) continue
        let startMonth, startYear
        if (disbursementStartPur > 0 && disbursementStartPur < 13) {
          startMonth = disbursementStartPur
          startYear = disbursementStartYear
        } else {
          startMonth = closingDateMonth
          startYear = closingDateYear
        }
        let monthsInPeriod = 12
        if (disbursementSched == 3) monthsInPeriod = 3
        else if (disbursementSched == 4) monthsInPeriod = 6

        for (let j = (startMonth - firstPaymentMonth + 12) % 12; j < 12; j += monthsInPeriod) {
          if (!startYear || firstPaymentYear + (j + firstPaymentMonth > 12 ? 1 : 0) >= startYear) {
            paymentFromEscrows[j] += payment * monthsInPeriod
          }
        }
      } else if (disbursementSched == 6) {
        for (let j = 0; j < 12; j++) {
          if (disbursementPeriods[j] != '.') {
            const index = (j + 1 - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += payment * (disbursementPeriods[j].charCodeAt(0) - 'A'.charCodeAt(0) + 1)
          }
        }
      } else if (disbursementSched == 7) {
        disbursementList?.map((v: any) => {
          const [date, amount] = v.split('~')
          const month = new Date(date).getMonth() + 1
          let year = new Date(date).getFullYear()
          if (month < firstPaymentMonth) year--
          if (year == firstPaymentYear) {
            const index = (month - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += parseFloat(amount)
          }
        })
      }
    }
    let minBal = 1000000000
    let balance = 0
    for (let i = 0; i < 12; i++) {
      balance = balance + paymentToEscrow - paymentFromEscrows[i]
      if (minBal > balance) minBal = balance
    }

    let sum = totalCush - minBal
    for (let i = 0; i < 7; i++) {
      const prefix = `PrepaidItems.${i}.`
      if (['0', '1', '', 0, 1].includes(data[`${prefix}DisbursementSched`])) continue
      const monthInReserve = Number(data[`${prefix}MonthsInReserve`])
      const payment = Number(data[`${prefix}Payment`])
      const result = monthInReserve * payment
      sum -= result
    }

    return sum
  }

  const renderSectionHeader = (title: string) => {
    return <p className="bg-blue-100 p-2 md:col-span-4 col-span-1">{title}</p>
  }

  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  const calculateValue: any = (key: string) => {
    let CALCULATED_VALUE
    switch (key) {
      case 'ClosingCosts.808.TotalAmount':
        const { feeBKey, feeSKey } = inputs['closingCosts']?.find((v: any) => v.lineId == 808) || {}
        CALCULATED_VALUE = data[feeBKey] + data[feeSKey]
        break
      case 'Loan.UFMIP':
        CALCULATED_VALUE =
          0 -
          (data['Secondary.MIPFFIsNettedFromWire']
            ? Number(data['Loan.MIPFFPaidInCash'] || 0) -
              (data['Loan.MIPFFPaidInCashPaidByOtherType'] == 1
                ? Number(data['Loan.MIPFFPaidInCashPBSDesired'] || 0)
                : 0)
            : 0)
        break
      case 'Loan.Interim':
        CALCULATED_VALUE =
          0 -
          (data['Secondary.InterimInterestIsNettedFromWire']
            ? Number(data['Loan.InterimInterestOV'] || calculateValue('Loan._InterimInterest') || 0) -
              Number(data['Loan.InterimInterestPaidByOtherType'] == 1 ? data['Loan.InterimInterestPBSDesired'] || 0 : 0)
            : 0)
        break
      case 'Loan._DailyInterest':
        const roundTo = data['Loan.InterimIntDecimalsOV'] == 0 ? 2 : Number(data['Loan.InterimIntDecimalsOV']) + 1
        CALCULATED_VALUE =
          Math.round(
            ((+data['HUD1.Line202Calc'] *
              (data['Loan.BDInterimIntCalcMethodOV'] == 3 && data['Loan.LoanProductType'] == 1
                ? +data['Loan.ARMIndexValue'] + +data['Loan.ARMMargin']
                : +data['Loan.interestRate'])) /
              100 /
              (data['Loan.InterimIntDaysPerYearOV'] == 2 ? 365 : 360)) *
              Math.pow(10, roundTo),
          ) / Math.pow(10, roundTo)
        break
      case 'Loan.EscrowDeposit':
        let sum = 0
        for (let i = 0; i < ppData.length - 1; i++) sum += Number(removeComma(ppData[i][4]))
        CALCULATED_VALUE = 0 - Number(data['Secondary.EscrowDepositIsNettedFromWire'] ? sum : 0)
        break
      case 'Loan.Netted':
        CALCULATED_VALUE = 0 - Math.round(Number(totalCCNetfromWires) * 100) / 100
        break
      case 'Loan._InterimInterest':
        if (data['Loan.DailyInterestOV'])
          CALCULATED_VALUE = +data['Loan.InterimInterestDays'] * +data['Loan.DailyInterestOV']
        else {
          CALCULATED_VALUE = +data['Loan.InterimInterestDays'] * Number(calculateValue('Loan._DailyInterest'))
        }
        CALCULATED_VALUE = Math.round(CALCULATED_VALUE * 100) / 100
        break
      case 'Loan.CalculatedWireAmount':
        CALCULATED_VALUE =
          Math.round(
            (+data['Loan.BaseLoan'] +
              +(data['Secondary.FundingWireAdjustmentAmount1'] || 0) +
              +(data['Secondary.FundingWireAdjustmentAmount2'] || 0) +
              calculateValue('Loan.UFMIP') +
              calculateValue('Loan.Interim') +
              calculateValue('Loan.Netted') +
              calculateValue('Loan.EscrowDeposit')) *
              100,
          ) / 100
        break
      case 'Secondary.WarehouseAdvanceAmount':
        CALCULATED_VALUE =
          Math.round(
            Number(
              data['Secondary.WarehouseAdvanceCalcMethod'] == 1
                ? data['Loan.BaseLoan']
                : data['Secondary.FundingWireAmount'],
            ) * +data['Secondary.WarehouseAdvancePerc'],
          ) / 100
        break
      case 'Secondary._WarehouseHaircut':
        CALCULATED_VALUE = data['Secondary.FundingWireAmount'] - calculateValue('Secondary.WarehouseAdvanceAmount')
        break
      default:
        return
    }
    return CALCULATED_VALUE
  }

  const renderInputs = (inputs: any) => {
    return (
      <>
        {(inputs || []).map((input: any) => {
          const { valueKey, valueType, inputType } = input
          input.value = calculateValue(valueKey) || data[valueKey] || ''
          if (inputType == 'value') input.value = getPrice2decimal(input.value, false, true)
          else if (valueType === 'number') input.value = getPrice1or2decimal(input.value)
          if (input.value[0] === '-') input.value = `(${input.value.slice(1)})`
          input.history = true

          return (
            <div className={`input flex-1 col-span-${input.span ? input.span : 1}`} key={valueKey}>
              <RenderInput
                input={input}
                Key={valueKey}
                key={valueKey}
                onChange={onChange}
                showHistory={() => showHistory([valueKey])}
              />
            </div>
          )
        })}
      </>
    )
  }

  const onCopyClick = (key: string) => {
    const temp = cloneDeep(data)
    if (key == 'Secondary.FundingWireAmount') temp[key] = calculateValue('Loan.CalculatedWireAmount')
    setData(temp)
    setChanged(true)
  }

  const onDownload = async () => {
    setIsLoading(true)
    const pdfData = await downloadNetFundingSheet()
    downloadFile(
      `Net Funding WorkSheet ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`,
      pdfData,
    )
    setIsLoading(false)
  }

  return (
    <div className="HUD1Page3-container relative">
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on HUD1 Page3!\nAre you sure want to leave without Saving?`}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          Funding
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <p className={`hover:underline cursor-pointer flex justify-end`}>
          <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
            <PrinterIcon className="w-5 h-5 text-shade-blue" />
          </span>
        </p>
      </div>
      <div className="grid gap-4 sm:grid-cols-2 grid-cols-1">
        <div className="col-span-2 my-4">{renderSectionHeader('Funding Key Dates')}</div>
        {renderInputs(inputs['funding_key_dates'])}
        <div className="col-span-2 my-4">
          <div className="grid md:grid-cols-12 sm:grid-cols-6 grid-cols-1 gap-2 my-4">
            {renderInputs(inputs['extendedFields'])}
          </div>
          <div className="grid md:grid-cols-12 sm:grid-cols-6 grid-cols-1 gap-2">
            <div className="md:col-span-4 sm:col-span-2 grid grid-cols-1 gap-2">
              {renderInputs(inputs['add_to_wire'])}
            </div>
            <div className="md:col-span-8 sm:col-span-6 grid md:grid-cols-2 grid-cols-1 gap-2">
              {renderInputs(inputs['included_in_wire'])}
            </div>
          </div>
          <div className="grid md:grid-cols-12 sm:grid-cols-6 grid-cols-1 gap-2 my-4">
            {renderInputs(inputs['totalToBroker'])}
          </div>
        </div>
        <div className="col-span-1 my-4">
          <div className="my-4">{renderSectionHeader('Funding Wire Calculation')}</div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-2">
            {renderInputs(inputs['funding_wire_calc'])}
            <div className={`input flex-1 col-span-1 flex items-center`}>
              <Button outline color="gray" onClick={() => onCopyClick('Secondary.FundingWireAmount')}>
                Fill
              </Button>
            </div>
          </div>
        </div>
        <div className="col-span-1 my-4">
          <div className="my-4">{renderSectionHeader('Dates')}</div>
          <div className="grid grid-cols-1 gap-2">{renderInputs(inputs['dates'])}</div>
        </div>
        <div className="col-span-2 my-4">{renderSectionHeader('Warehouse Info')}</div>
        {renderInputs(inputs['warehouse_info'])}
      </div>
      <div className="my-8">{renderSectionHeader('CONV. UFMIP')}</div>
      <div className="-mt-4">
        <PlainTable header={tableHeader} data={convData()} />
      </div>
      <div className="my-8">{renderSectionHeader('INTERIM INTEREST')}</div>
      <div className="-mt-4">
        <PlainTable header={tableHeader} data={interimInterestData()} />
      </div>
      <div className="my-8">{renderSectionHeader('FEES')}</div>
      <div className="-mt-4">
        <RenderInput
          input={{ title: 'Show Only Fees with Values', inputType: 'checkbox', value: showFeeWithValue }}
          Key={'showFeeWithValue'}
          onChange={() => setShowFeeWithValue(!showFeeWithValue)}
          showHistory={() => showHistory(['showFeeWithValue'])}
        />
        <div className=" max-h-196 overflow-auto">
          <PlainTable header={tableHeader} data={ccData} />
        </div>
      </div>
      <div className="my-8">{renderSectionHeader('ESCROW DEPOSIT')}</div>
      <div className="-mt-4">
        <RenderInput
          input={{ title: 'Show Only Escrowed Items', inputType: 'checkbox', value: showEscrowedItem }}
          Key={'showEscrowedItem'}
          onChange={() => setShowEscrowedItem(!showEscrowedItem)}
          showHistory={() => showHistory(['showEscrowedItem'])}
        />
        <PlainTable header={tableHeader} data={ppData} />
      </div>
      <div className="flex justify-center mt-4">
        <Button disabled={!changed} onClick={onSaveChanges} loading={action === 'saveChanges'}>
          Save Changes
        </Button>
      </div>
      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
      {Object.keys(closingCostsDetailData).length > 0 && (
        <ClosingCostsDetailsModal
          closingCosts={closingCostsDetailData}
          loanNumber={loanNumber}
          loanAmount={data['HUD1.Line202Calc']}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
          onDelete={onDeleteFee}
        />
      )}
      {Object.keys(prepaidItemsDetailData).length > 0 && (
        <PrepaidItemsDetailsModal
          prepaidItems={prepaidItemsDetailData}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
        />
      )}
      {showMIPFundingModal && <MIPFundingFeeModal data={data} onClose={closeMIPFundingModal} />}
      {showInterimInterestModal && <InterimInterestModal data={data} onClose={closeMIPFundingModal} />}
    </div>
  )
}
