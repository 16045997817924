import {
  API_ACCOUNT_EXECUTIVES,
  API_ACCOUNT_EXECUTIVES_BY_ID,
  API_ADMIN_ALTERNATIVE_NAMES,
  API_ADMIN_CONFIG_BY_TYPE,
  API_ADMIN_CREATE_ARM_DETAIL,
  API_ADMIN_CREDENTIALS,
  API_ADMIN_DELETE_ARM_DETAIL,
  API_ADMIN_GET_ARM_DETAILS,
  API_ADMIN_GET_SETTING,
  API_ADMIN_UPDATE_ARM_DETAIL,
  API_ADMIN_UPDATE_SETTING,
  API_BANKER_DATA,
  API_BANKER_DELETE_DATA,
  API_BANKER_UPDATE_DATA,
  API_CONVERT_LOAN,
  API_EMAIL_BROADCAST,
  API_FAQ,
  API_FAQ_BY_ID,
  API_FAQ_EXCHANGE_ORDER,
  API_GET_NAVBAR_DATA,
  API_LOAN_DELETE_ALL,
  API_LOAN_TERM_SHEET,
  API_LOGIN_PAGE_MODAL,
  API_PERMISSION_DETAILS,
  API_REMOVE_LOAN_TERM_SHEET,
  API_SEND_BROKER_VERIFICATION_MESSAGE,
  API_SEND_BROKER_VERIFICATION_PDF,
  API_SEND_BROKER_VERIFICATION_REQUEST,
  API_SEND_SIGN_BROKER_APPROVAL,
  API_SYNC_LOAN_TERM_SHEET,
  API_UPDATE_BROKER_VERIFICATION_DOCUMENT,
  API_UPDATE_LOAN_TERM_SHEET,
} from 'config'
import { AccountVerificationType, BrokerIdentifyCategory, BusinessHour } from 'pages/Admin'
import type { BrokerVerificationDocument } from 'pages/ManageAccounts'
import Api from 'services/api'

import { fileJsonConvert } from '../documents'

export const AccountVerificationSettingKey = {
  [AccountVerificationType.Broker]: 'BROKER_VERIFICATION',
  [AccountVerificationType.Correspondent]: 'CORRESPONDENT_VERIFICATION',
  [AccountVerificationType.Tablefunder]: 'TABLEFUNDER_VERIFICATION',
  [AccountVerificationType.ContractorApproval]: 'CONTRACTOR_APPROVAL',
}

export const getPermissionDetails = () => {
  return Api.get(API_PERMISSION_DETAILS)
}

export const updatePermissions = (data: any) => {
  return Api.post(API_PERMISSION_DETAILS, data)
}

export const convertLoan = (loanNumber: number, type: string) => {
  if (type === 'Delete') return Api.delete(API_LOAN_DELETE_ALL, {}, { loanNumber })
  return Api.post(API_CONVERT_LOAN, { type }, { loanNumber })
}

export const getSetting = (key: string) => {
  return Api.get(API_ADMIN_GET_SETTING, {}, { key })
}

export const getNavBarData = () => {
  return Api.get(API_GET_NAVBAR_DATA)
}

export const updateSetting = (key: string, value: string) => {
  return Api.put(API_ADMIN_UPDATE_SETTING, { key, value })
}

export const getBrokerVerificationCategories = async (
  type: AccountVerificationType,
): Promise<BrokerIdentifyCategory[]> => {
  const categories = await getSetting(AccountVerificationSettingKey[type])
  try {
    const values = categories || {}
    return JSON.parse(values.value) || []
  } catch {
    return []
  }
}

export const setBusinessHours = (value: BusinessHour) => {
  return Api.put(API_ADMIN_UPDATE_SETTING, { key: 'BUSINESS_HOURS', value: JSON.stringify(value) })
}

export const getBusinessHours = async (): Promise<BusinessHour | null> => {
  const buzHours = await getSetting('BUSINESS_HOURS')
  try {
    const values = buzHours || {}
    return JSON.parse(values.value) || null
  } catch {
    return null
  }
}

export const getARMDetails = () => {
  return Api.get(API_ADMIN_GET_ARM_DETAILS, {})
}

export const submitARMDetail = (id: number, data: Record<string, string | number | boolean>) => {
  if (!id) return Api.post(API_ADMIN_CREATE_ARM_DETAIL, data)

  return Api.put(API_ADMIN_UPDATE_ARM_DETAIL, data, { id })
}

export const removeARMDetal = (id: number) => {
  return Api.delete(API_ADMIN_DELETE_ARM_DETAIL, {}, { id })
}

export const setBrokerVerificationCategories = async (
  values: BrokerIdentifyCategory[],
  type: AccountVerificationType,
) => {
  return updateSetting(AccountVerificationSettingKey[type], JSON.stringify(values))
}

export const sendBrokerVerificationRequest = (userId: number) => {
  return Api.post(API_SEND_BROKER_VERIFICATION_REQUEST, {}, { userId })
}

export const updateBrokerVerificationDocument = (userId: number, doc: BrokerVerificationDocument[]) => {
  return Api.post(API_UPDATE_BROKER_VERIFICATION_DOCUMENT, doc, { userId })
}

export const deleteBrokerVerificationDocument = (userId: number, docIds: number[]) => {
  return Api.delete(API_UPDATE_BROKER_VERIFICATION_DOCUMENT, docIds, { userId })
}

export const sendBrokerVerificationMessage = (userId: number, data: any) => {
  return Api.post(API_SEND_BROKER_VERIFICATION_MESSAGE, data, { userId })
}

export const sendBrokerVerificationPdf = (userId: number) => {
  return Api.post(API_SEND_BROKER_VERIFICATION_PDF, {}, { userId }, { timeout: 150000 })
}

export const sendSignBrokerApprovalRequest = (userId: number) => {
  return Api.post(API_SEND_SIGN_BROKER_APPROVAL, {}, { userId })
}

export const getBroadcastEmails = (filters: Record<string, any>) => {
  return Api.get(API_EMAIL_BROADCAST, filters)
}

export const createBroadcastEmail = (data: Record<string, string | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_EMAIL_BROADCAST, data, {}, json.files, { timeout: 300000 })
}

export const getBankerData = (data: Record<string, any>) => {
  return Api.get(API_BANKER_DATA, data)
}

export const updateBankerData = (data: Record<string, any>) => {
  return Api.post(API_BANKER_UPDATE_DATA, data)
}

export const deleteBankerData = (data: Record<string, any>) => {
  return Api.post(API_BANKER_DELETE_DATA, data)
}

export const logInPageModalAPI = (data: Record<string, any>) => {
  return Api.post(API_LOGIN_PAGE_MODAL, data)
}

export const filterAccountExecutives = () => {
  return Api.get(API_ACCOUNT_EXECUTIVES)
}

export const submitAccountExecutive = (data: Record<string, any>) => {
  return Api.uploadFiles(API_ACCOUNT_EXECUTIVES, data, {}, [])
}

export const updateAccountExecutive = (id: number, data: Record<string, any>) => {
  return Api.uploadFiles(API_ACCOUNT_EXECUTIVES_BY_ID, data, { id }, [])
}

export const updateAccountExecutiveProps = (id: number, data: Record<string, any>) => {
  return Api.put(API_ACCOUNT_EXECUTIVES_BY_ID, data, { id })
}

export const deleteAccountExecutive = (id: number) => {
  return Api.delete(API_ACCOUNT_EXECUTIVES_BY_ID, {}, { id })
}

export const getFaq = () => {
  return Api.get(API_FAQ)
}

export const addFaq = (data: Record<string, string>) => {
  return Api.post(API_FAQ, data)
}

export const updateFaq = (id: number, data: Record<string, string>) => {
  return Api.put(API_FAQ_BY_ID, data, { id })
}

export const deleteFaq = (id: number) => {
  return Api.delete(API_FAQ_BY_ID, {}, { id })
}

export const exchangeFaqOrder = (id1: number, id2: number) => {
  return Api.post(API_FAQ_EXCHANGE_ORDER, {}, { id1, id2 })
}

export const getLoanTermSheetTemp = (productType: string, transactionType: string) => {
  return Api.get(API_LOAN_TERM_SHEET, {}, { productType, transactionType })
}

export const updateLoanTermSheetTemp = (id: number, data: any) => {
  return Api.put(API_UPDATE_LOAN_TERM_SHEET, data, { id })
}

export const syncLoanTermSheetTemp = (id: number, data: any) => {
  return Api.put(API_SYNC_LOAN_TERM_SHEET, data, { id })
}

export const addLoanTermSheetField = (id: number, data: any) => {
  return Api.post(API_UPDATE_LOAN_TERM_SHEET, data, { id })
}

export const removeLoanTermSheetField = (id: number, index: number) => {
  return Api.delete(API_REMOVE_LOAN_TERM_SHEET, {}, { id, index })
}

export const getAlternativeNames = (cond: { firstName: string; middleName: string; lastName: string }) => {
  return Api.get(API_ADMIN_ALTERNATIVE_NAMES, cond)
}

export const updateAlternativeNames = (
  cond: { firstName: string; middleName: string; lastName: string },
  data: Record<string, any>[],
) => {
  return Api.put(API_ADMIN_ALTERNATIVE_NAMES, {
    ...cond,
    data,
  })
}

export const getAdminConfig = (type: string, props: Record<string, any> = {}) => {
  return Api.get(API_ADMIN_CONFIG_BY_TYPE, props, { type })
}

export const setAdminConfig = (type: string, values: Record<string, any>) => {
  return Api.post(API_ADMIN_CONFIG_BY_TYPE, values, { type })
}

export const getCredentials = (filterQuery: Record<string, any>) => {
  return Api.get(API_ADMIN_CREDENTIALS, filterQuery)
}
