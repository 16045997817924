import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { AccountType, COMPANY_NAME_FC, InputType, INVALID_ALL_INPUTS, ProductTypes } from 'config'
import type { ReviewFormCategory, ReviewFormTemplate } from 'pages/Admin'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  deleteReviewForm,
  downloadFile,
  getAppraisalReviewPdf,
  getReviewForm,
  getReviewFormCategories,
  updateReviewForm,
  updateReviewFormData,
} from 'services'
import { Button, ButtonGroup, Checkbox, TextArea, Toggle } from 'stories/components'
import { confirm, InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AddReviewFormModal } from './AddReviewFormModal'
import { defaultInputs } from './constants'

export interface ReviewFormData extends ReviewFormTemplate {
  isChecked?: boolean
  isDeleted?: boolean
  comment?: string
}

const ReviewForm = ({ child = false }) => {
  const [loading, setLoading] = useState(true)
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [addressMap, setAddressMap] = useState<Record<string, string>>({})
  const [currentId, setCurrentId] = useState(0)
  const [categories, setCategories] = useState<ReviewFormCategory[] | null>(null)
  const [isShowAddModal, setShowAddModal] = useState(false)
  const [selectedData, setSelectedData] = useState<ReviewFormData | null>(null)
  const [isUploadStore, setUploadStore] = useState(false)
  const [overviewData, setOverviewData] = useState<any>({})

  const { auth, loan } = useSelector((state: any) => ({
    auth: state.auth,
    loan: state.loan,
  }))
  const isAdmin: boolean = auth.profile.accountType === AccountType.ADMIN

  useEffect(() => {
    setLoanNumber()
    fetchAll()
  }, [])

  const fetchAll = async () => {
    setLoading(true)

    const categories = await getReviewFormCategories()
    setCategories(categories)
  }

  useEffect(() => {
    if (!categories) return

    fetchReviewForm(0)
  }, [categories])

  const formCount = useMemo(() => Object.keys(addressMap).length, [addressMap])

  const fetchReviewForm = (index: number) => {
    setLoading(true)

    getReviewForm(index)
      .then(({ data, addressMap }: { data: Record<string, any>; addressMap: Record<string, string> }) => {
        setAddressMap(addressMap)
        const newInputs = cloneDeep(defaultInputs())
        for (const key in data) {
          if (newInputs[key]) newInputs[key].value = data[key]
        }

        const isFixAndGroup = [ProductTypes.FixAndFlip, ProductTypes.GroundUpConstruction].includes(loan.productType)
        newInputs.appraiserAfterRepairProperty.visible = isFixAndGroup
        newInputs.appraiserARVValuationType.visible = isFixAndGroup
        setInputs(newInputs)
        setCurrentId(data.id)
      })
      .finally(() => setLoading(false))
  }

  const onCreateForm = () => {
    fetchReviewForm(formCount)
  }

  const onDeleteForm = async () => {
    const title = inputs.title.value
    const address = inputs.propertyAddress.value
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure to remove this form?
        <br />
        <span className="text-gray-600">Title: {title}</span>
        <br />
        <span className="text-gray-600">Address: {address}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    await deleteReviewForm(currentId)
    fetchReviewForm(0)
  }

  const isTitleDuplicate = (id: number, title: string) => {
    const found = inputs.data.value.find((item: ReviewFormData) => item.title.trim() == title.trim())
    if (!found) return false
    return found.id != id
  }

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    const newInputs = cloneDeep(inputs)
    const input: any = newInputs[key]
    let error = InputValidate(input)
    input.error = error
    setInputs(newInputs)

    if (input.error && input.error.length > 0) return

    setLoading(true)
    if (key != 'data') await updateReviewForm(currentId, key, input.value)
    setLoading(false)

    if (key == 'title') {
      const newMap = cloneDeep(addressMap)
      const index = Object.keys(addressMap).indexOf(`${currentId}`)
      newMap[`${currentId}`] = `${index + 1}. ${input.value}`
      setAddressMap(newMap)
    }
  }

  const onAdd = (category: ReviewFormCategory) => {
    setShowAddModal(true)
    setSelectedData({
      categoryId: category.id,
    } as any)
  }

  const onClose = () => {
    setSelectedData(null)
    setShowAddModal(false)
  }

  const onEdit = (item: ReviewFormData) => {
    setShowAddModal(true)
    setSelectedData(item)
  }

  const onRemove = async (data: ReviewFormData[], itemId: number, onChange: Function) => {
    const index = data.findIndex((v: ReviewFormData) => v.id == itemId)

    const content = (
      <div className="text-gray-600 mb-4 text-md">
        Are you sure want to delete this item?
        <div className="text-gray-900 flex flex-col text-left text-sm">
          <span>Title: {data[index].title}</span>
          <span>Description: {data[index].description}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(data)
    const removedData = newData[index]

    const isFromTemplate = !!removedData.no
    if (isFromTemplate) removedData.isDeleted = true
    else newData.splice(index, 1)

    setLoading(true)
    if (isFromTemplate) await updateReviewFormData(currentId, { update: removedData })
    else await updateReviewFormData(currentId, { remove: removedData })

    onChange(newData)
    setLoading(false)
  }

  const onSubmitModal = async (item: ReviewFormData) => {
    const newData: ReviewFormData[] = cloneDeep(inputs.data.value)
    setLoading(true)

    if (item.id) {
      const index = newData.findIndex((v: ReviewFormData) => v.id == item.id)
      newData[index] = item
      await updateReviewFormData(currentId, { update: item })
    } else {
      item.id = Date.now()
      newData.push(item)
      await updateReviewFormData(currentId, { add: item })
    }
    onChange('data', newData)
    setLoading(false)
  }

  const onUpdateData = useCallback(
    async (
      data: ReviewFormData[] = [],
      itemId: number,
      updatingData: Record<string, any>,
      onChange: Function,
      isSaving = false,
    ) => {
      const newData: ReviewFormData[] = cloneDeep(data)
      const index = newData.findIndex((v: ReviewFormData) => v.id == itemId)
      newData[index] = {
        ...newData[index],
        ...updatingData,
      }
      if (isSaving) {
        setLoading(true)
        await updateReviewFormData(currentId, { update: newData[index] })
      }
      onChange(newData)
      setLoading(false)
    },
    [inputs, currentId],
  )

  const onGenerate = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in newStats) {
      const { value, disabled = false } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true
      if (!disabled && value !== undefined) data[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return
    }
    if (overviewData.loanNumber === undefined) {
      return toast('Try again!', { type: 'error' })
    }
    data.loanNumber = `${overviewData.loanNumber}`

    setLoading(true)

    const pdfData: Blob = await getAppraisalReviewPdf({
      categories,
      data,
      inputs,
      isUploadStore,
    })
    downloadFile(`${COMPANY_NAME_FC} Appraisal Review - ${data.loanNumber}.pdf`, pdfData)
    setUploadStore(false)
    setLoading(false)
  }

  const renderCategory = (category: ReviewFormCategory, data: ReviewFormData[] = [], onChange: Function) => {
    const items = data
      .filter((item) => item.categoryId == category.id && !item.isDeleted)
      .sort((a, b) => (a.order > b.order ? 1 : -1))

    return items.map((item: ReviewFormData, index: number) => (
      <div className="p-3 border-b-2 border-black-100" key={`item-${item.id}-${index}`}>
        <div className="flex justify-between">
          <Checkbox
            id={`item-check-${item.id}-${index}`}
            title={item.title}
            checked={item.isChecked || false}
            value={item.isChecked || false}
            onChange={(value) => onUpdateData(data, item.id, { isChecked: value }, onChange, true)}
          />
          {isAdmin && (
            <div className="flex gap-1  ">
              <button className="hover-shadow1 p-1 rounded cursor-pointer text-shade-blue" onClick={() => onEdit(item)}>
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </button>
              <button
                className="hover-shadow1 p-1 rounded cursor-pointer text-red-800"
                onClick={() => onRemove(data, item.id, onChange)}
              >
                <TrashIcon className="w-4 h-4"></TrashIcon>
              </button>
            </div>
          )}
        </div>
        <p className="text-black-500 text-[14px] italic">{item.description}</p>
        <TextArea
          rows={2}
          title="Comment"
          value={item.comment}
          onChange={(value) => onUpdateData(data, item.id, { comment: value }, onChange, false)}
          onBlur={() => onUpdateData(data, item.id, {}, onChange, true)}
        />
      </div>
    ))
  }

  const renderItems = useCallback(
    (value: ReviewFormData[] = [], onChange: Function) => {
      if (!categories) return <div />

      return (
        <div>
          {categories.map((category) => {
            const count = value.filter((item) => item.categoryId == category.id && !item.isDeleted).length
            return (
              <div className="mb-3" key={`category-${category.id}`}>
                <div className="flex items-baseline border-b-2 border-shade-blue px-3">
                  <div className="flex-1 font-semibold text-[17px]">
                    {category.value} ({count})
                  </div>

                  <button
                    className="flex hover-shadow1 p-1 cursor-pointer text-shade-blue items-center rounded m-2"
                    onClick={() => onAdd(category)}
                  >
                    <PlusIcon className="w-4 h-4"></PlusIcon>
                    <span className="text-sm">Add</span>
                  </button>
                </div>
                <div>{renderCategory(category, value, onChange)}</div>
              </div>
            )
          })}
        </div>
      )
    },
    [categories, currentId],
  )

  const loanOverviewDataFeed = (data: any) => {
    setOverviewData(data)
  }

  return (
    <div className={`ReviewForm-container ${!child && 'py-6 px-2'}`}>
      <div className={`${child && 'hidden'}`}>
        <Overview title="Appraisal Review" feedOverview={loanOverviewDataFeed} noChangeTitle={child} />
      </div>
      <div className="max-w-screen-2xl m-auto">
        <div className={`relative bg-white ${!child && 'shadow1 rounded mb-4 p-6'}`}>
          <LayoutLoading show={loading} />

          <div className="review-form-container overflow-show">
            <div className="flex items-center justify-between mb-4 flex-4">
              {!!formCount && (
                <ButtonGroup
                  title={addressMap}
                  value={`${currentId}`}
                  onChange={(v) => fetchReviewForm(Object.keys(addressMap).indexOf(`${v}`))}
                />
              )}
              {!!formCount && (
                <div className="flex items-center flex-4">
                  <Button onClick={onCreateForm}>Add</Button>
                  <Button color="red" disabled={formCount == 1} onClick={onDeleteForm}>
                    Delete
                  </Button>
                </div>
              )}
            </div>
            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-5">
              {Object.keys(inputs).map((key, index) => {
                const input = inputs[key]
                if (input.visible === false) return null
                if (key == 'data') (input as any).render = renderItems
                return (
                  <div
                    className={`input col-span-1 ${input.span && 'md:col-span-2 lg:col-span-3'} items-center`}
                    key={`item-${index}-${key}`}
                  >
                    <RenderInput input={input} Key={key} onChange={onChange} onBlur={onBlur} />
                  </div>
                )
              })}
            </div>
            <div className="">
              <div className="w-96 mx-auto py-3">
                <Toggle
                  id="upload-pdf-store"
                  title="Upload to Document Storage"
                  value={isUploadStore}
                  onChange={(value) => setUploadStore(value)}
                />
              </div>

              <div className="w-96 mx-auto">
                <Button full onClick={onGenerate}>
                  Generate Document
                </Button>
              </div>
            </div>
          </div>

          {isShowAddModal && categories && (
            <AddReviewFormModal
              categories={categories}
              data={selectedData}
              isDuplicate={(id: number, title: string) => isTitleDuplicate(id, title)}
              onClose={() => onClose()}
              onSubmit={(values: ReviewFormData) => onSubmitModal(values)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ReviewForm
