import {
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, Prompt } from 'react-router-dom'
import { downloadFile, downloadHUD1PDF, gethud1PageData, posthud1Page1Data } from 'services'
import Api from 'services/api'
import svgLoading from 'stories/assets/loading.svg'
import { Button } from 'stories/components'
import { OverrideCaclModal } from 'stories/components/OverrideCalcModal/OverrideCalcModal'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'
import { openAuditLog } from 'utils'
import {
  convertNegative2Parentheses,
  convertParentheses2Negative,
  getPrice1or2decimal,
  getPrice2decimal,
} from 'utils/convertor'
import { confirmOptions } from 'utils/modals/confirmOptions'
import { RenderInput } from 'utils/RenderInput'

import { HUD1InputType } from './constant'

export function HUD1Page1() {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])
  const [changed, setChanged] = useState(false)
  const [ovData, setOVData] = useState<any>({})
  const [focusedKey, setFocusedKey] = useState('')

  const loanNumber = Api.getLoanNumber()
  const calculateKeys = [
    'HUD1.TotalFromBorrowerCalc',
    'HUD1.Line202Calc',
    'HUD1.TotalByBorrowerCalc',
    'HUD1.TotalFromBorrower',
    'HUD1.TotalByBorrower',
    'HUD1.TotalSettlementBorrowerCalc',
    'HUD1.TotalFromSellerCalc',
    'HUD1.TotalReductionsSellerCalc',
    'HUD1.TotalFromSeller',
    'HUD1.TotalReductionsSeller',
    'HUD1.TotalSettlementSellerCalc',
  ]

  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const res = await gethud1PageData('page1')
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setOrgData(res.data)
      setIDs(res.IDs)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  const onChange = (key: string, value: string) => {
    let temp = cloneDeep(data)
    temp[key] = value
    setData(temp)
    if (!changed) setChanged(true)
  }

  const onBlur = (key: string, value: string, type?: string) => {
    setFocusedKey('')
    let temp = cloneDeep(data)
    if (type === 'number') {
      value = convertParentheses2Negative(value)
      temp[key] = getPrice1or2decimal(value.replaceAll(',', '')).replaceAll(',', '')
    } else temp[key] = value
    setData(temp)
  }

  const onFocus = (key: string) => {
    const temp = cloneDeep(data)
    temp[key] = convertNegative2Parentheses(data[key])
    setData(temp)
    setFocusedKey(key)
  }

  const onCopyClick = (key: number, valueKey: string) => {
    const sourceKey = key + 300
    const sourceValueKey = valueKey.replace(key.toString(), sourceKey.toString())
    let temp = cloneDeep(data)
    temp[valueKey] = data[sourceValueKey]
    setData(temp)
    if (!changed) setChanged(true)
  }

  const showInputValue = (value: any, key: string) => {
    if (key !== focusedKey) {
      value = getPrice2decimal(value, false, true)
      return convertNegative2Parentheses(value)
    }
    return value
  }

  const renderHUD1Input = (input: any, key: number) => {
    const { type, title, titleBold, titleKey, fromKey, toKey, valueKey, overrideKey, canOverride } = input
    let VALUE = data[valueKey]
    const OVVALUE = data[overrideKey]
    let CALCULATED_VALUE: any = getPrice2decimal(calculateValue(valueKey), false, true)
    VALUE = CALCULATED_VALUE || VALUE
    if (type === HUD1InputType.onlyTitle) {
      // only title
      return (
        <div className={`flex gap-2 mt-4 ${titleBold ? 'font-semibold' : ''}`}>
          {!isNaN(Number(key)) && <div className="w-[28px]">{key}.</div>} {title}
        </div>
      )
    }
    if (type === HUD1InputType.titleInputValue) {
      // title + input value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1">
            <div className="flex gap-2">
              <div className="w-[28px] cursor-pointer hover:underline" onClick={() => showHistory([valueKey])}>
                {key}.
              </div>{' '}
              {title}
            </div>
          </div>
          <div className="relative">
            <input
              className="px-2 text-right border w-[120px]"
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.titleCalculatedValue) {
      // title + calculated value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1 flex justify-between items-center">
            <div className={`flex gap-2 ${titleBold ? 'font-semibold' : ''}`}>
              <div
                className={`w-[28px] cursor-pointer hover:underline`}
                onClick={() => showHistory(overrideKey ? [valueKey, overrideKey] : [valueKey])}
              >
                {key}.
              </div>{' '}
              {title}
            </div>
            {canOverride && (
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title,
                    overrideKey,
                    calcValue: CALCULATED_VALUE || data[valueKey],
                    ovValue: data[overrideKey] || '',
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )}
          </div>
          <div className="">
            <input
              disabled={true}
              className={`px-2 text-right border cursor-not-allowed w-[120px] ${OVVALUE ? 'bg-red-100' : ''}`}
              value={convertNegative2Parentheses(getPrice2decimal(OVVALUE || VALUE, false, true))}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.inputTitleInputValue) {
      // input title + input value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex flex-1 gap-2">
            <div className="w-[28px]  cursor-pointer hover:underline" onClick={() => showHistory([titleKey, valueKey])}>
              {key}.
            </div>
            <input
              className="px-2 border flex-1"
              value={data[titleKey]}
              onChange={(e) => onChange(titleKey, e.target.value)}
            />
          </div>
          <div className="">
            <input
              className="px-2 text-right border w-[120px]"
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.inputFromToValue) {
      // input from & to date + input value
      return (
        <div className="flex flex-wrap justify-end gap-2">
          <div
            className="w-[28px]  cursor-pointer hover:underline"
            onClick={() => showHistory([fromKey, toKey, valueKey])}
          >
            {key}.
          </div>
          <div className="flex flex-wrap flex-1 gap-2 items-center">
            <div className="w-[75px]">{title}</div>
            <input
              className="px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500"
              value={data[fromKey]}
              type="date"
              onChange={(e) => onChange(fromKey, e.target.value)}
            />
            <span>to</span>
            <input
              className="px-0 border border-gray-200 text-[14px] w-[120px] py-0 placeholder-gray-500"
              value={data[toKey]}
              type="date"
              onChange={(e) => onChange(toKey, e.target.value)}
            />
          </div>
          <div className="">
            <input
              className="px-2 text-right border w-[120px]"
              value={showInputValue(VALUE, valueKey)}
              onBlur={(e) => {
                onBlur(valueKey, e.target.value, 'number')
              }}
              onFocus={() => onFocus(valueKey)}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
    if (type === HUD1InputType.titleCopyInput) {
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1">
            <div className="flex gap-2">
              <div className="w-[28px] cursor-pointer hover:underline" onClick={() => showHistory([valueKey])}>
                {key}.
              </div>{' '}
              {title}
            </div>
          </div>
          <Tooltip message="Copy from Seller">
            <span className="cursor-pointer hover:text-shade-blue" onClick={() => onCopyClick(key, valueKey)}>
              <DocumentDuplicateIcon className="w-4 h-4"></DocumentDuplicateIcon>
            </span>
          </Tooltip>
          <div className="flex-end">
            <input
              className="px-2 text-right border w-[120px]"
              value={VALUE}
              onChange={(e) => onChange(valueKey, e.target.value)}
            />
          </div>
        </div>
      )
    }
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onChange(ovData.overrideKey, data.ovValue)
    setOVData({})
  }

  const calculateValue = (key: string) => {
    let CALCULATED_VALUE: any = undefined
    switch (key) {
      case 'HUD1.TotalFromBorrowerCalc':
        CALCULATED_VALUE =
          +(data['HUD1.TotalBorrowerChargesOV']
            ? data['HUD1.TotalBorrowerChargesOV']
            : data['HUD1.TotalBorrowerCharges'] || 0) +
          dataSum([
            'Loan.PurPrice',
            'HUD1.Line102Amount',
            'HUD1.Line104Amount',
            'HUD1.Line105Amount',
            'HUD1.Line106Amount',
            'HUD1.Line107Amount',
            'HUD1.Line108Amount',
            'HUD1.Line109Amount',
            'HUD1.Line110Amount',
            'HUD1.Line111Amount',
            'HUD1.Line112Amount',
          ])
        break
      case 'HUD1.Line202Calc':
        CALCULATED_VALUE = data['HUD1.Line202Calc']
        break
      case 'HUD1.TotalByBorrowerCalc':
        CALCULATED_VALUE =
          (data['HUD1.Line201Amount'] ? +data['HUD1.Line201Amount'] : +data['HUD1.Line201Amount2010Calc'] || 0) +
          (data['HUD1.Line202Amount'] ? +data['HUD1.Line202Amount'] : +data['HUD1.Line202Calc'] || 0) +
          dataSum([
            'HUD1.Line203Amount',
            'HUD1.Line204Amount',
            'HUD1.Line205Amount',
            'HUD1.Line206Amount',
            'HUD1.Line207Amount',
            'HUD1.Line208Amount',
            'HUD1.Line209Amount',
            'HUD1.Line210Amount',
            'HUD1.Line211Amount',
            'HUD1.Line212Amount',
            'HUD1.Line213Amount',
            'HUD1.Line214Amount',
            'HUD1.Line215Amount',
            'HUD1.Line216Amount',
            'HUD1.Line217Amount',
            'HUD1.Line218Amount',
            'HUD1.Line219Amount',
          ])
        break
      case 'HUD1.TotalFromBorrower':
        CALCULATED_VALUE = data['HUD1.TotalFromBorrowerOV']
          ? data['HUD1.TotalFromBorrowerOV']
          : calculateValue('HUD1.TotalFromBorrowerCalc')
        break
      case 'HUD1.TotalByBorrower':
        CALCULATED_VALUE = data['HUD1.TotalByBorrowerOV']
          ? data['HUD1.TotalByBorrowerOV']
          : calculateValue('HUD1.TotalByBorrowerCalc')
        break
      case 'HUD1.TotalSettlementBorrowerCalc':
        CALCULATED_VALUE = calculateValue('HUD1.TotalFromBorrower') - calculateValue('HUD1.TotalByBorrower')
        break
      case 'HUD1.TotalFromSellerCalc':
        CALCULATED_VALUE = dataSum([
          'Loan.PurPrice',
          'HUD1.Line402Amount',
          'HUD1.Line403Amount',
          'HUD1.Line404Amount',
          'HUD1.Line405Amount',
          'HUD1.Line406Amount',
          'HUD1.Line407Amount',
          'HUD1.Line408Amount',
          'HUD1.Line409Amount',
          'HUD1.Line410Amount',
          'HUD1.Line411Amount',
          'HUD1.Line412Amount',
        ])
        break
      case 'HUD1.TotalReductionsSellerCalc':
        CALCULATED_VALUE =
          +(data['HUD1.TotalSettlementSellerOV']
            ? data['HUD1.TotalSettlementSellerOV']
            : data['HUD1.TotalSellerCharges'] || 0) +
          (data['HUD1.Line506Amount'] ? +data['HUD1.Line506Amount'] : +data['HUD1.Line506Amount2010Calc'] || 0) +
          dataSum([
            'HUD1.Line501Amount',
            'HUD1.Line503Amount',
            'HUD1.Line504Amount',
            'HUD1.Line505Amount',
            'HUD1.Line507Amount',
            'HUD1.Line508Amount',
            'HUD1.Line509Amount',
            'HUD1.Line510Amount',
            'HUD1.Line511Amount',
            'HUD1.Line512Amount',
            'HUD1.Line513Amount',
            'HUD1.Line514Amount',
            'HUD1.Line515Amount',
            'HUD1.Line516Amount',
            'HUD1.Line517Amount',
            'HUD1.Line518Amount',
            'HUD1.Line519Amount',
          ])
        break
      case 'HUD1.TotalFromSeller':
        CALCULATED_VALUE = data['HUD1.TotalFromSellerOV']
          ? data['HUD1.TotalFromSellerOV']
          : calculateValue('HUD1.TotalFromSellerCalc')
        break
      case 'HUD1.TotalReductionsSeller':
        CALCULATED_VALUE = data['HUD1.TotalReductionsSellerOV']
          ? data['HUD1.TotalReductionsSellerOV']
          : calculateValue('HUD1.TotalReductionsSellerCalc')
        break
      case 'HUD1.TotalSettlementSellerCalc':
        CALCULATED_VALUE = calculateValue('HUD1.TotalFromSeller') - calculateValue('HUD1.TotalReductionsSeller')
        break
      default:
    }
    return CALCULATED_VALUE
  }

  const dataSum = (keys: Array<string>) => {
    let sum = 0
    keys.forEach((key) => {
      if (data[key]) sum += +data[key] || 0
    })
    return sum
  }

  const onSaveChanges = async () => {
    setChanged(false)
    let json: any = {}
    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) json[key] = data[key]
    })
    calculateKeys.map((v) => {
      json[v] = calculateValue(v)
    })
    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
    }
  }

  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  const onDownload = async () => {
    const res: any = await confirmOptions('Print HUD1', [
      { name: 'Page 1', value: 0 },
      { name: 'All Pages', value: 1 },
    ])
    if (res === false) return
    setIsLoading(true)
    const pdfData = await downloadHUD1PDF(res === 0 ? 'hud1-Page1' : 'hud1-all')
    downloadFile(
      `HUD1${res === 0 ? '-Page1' : ''} ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`,
      pdfData,
    )
    setIsLoading(false)
  }

  return (
    <div className="HUD1Page1-container relative">
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on HUD1 Page1!\nAre you sure want to leave without Saving?`}
      />
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold flex items-center mb-3">
          HUD1 Page1
          {isLoading && (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          )}
        </h2>
        <p className={`hover:underline cursor-pointer flex justify-end`}>
          <span className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDownload}>
            <PrinterIcon className="w-5 h-5 text-shade-blue" />
          </span>
        </p>
      </div>
      <div className="my-4">
        <div className="grid gap-4 md:grid-cols-4 grid-cols-1">
          <div className={`col-span-1 md:col-span-4 bg-gray-200 p-2`}>
            <p>B. Type of Loan</p>
          </div>
          {inputs['bTypeOfLoan']?.map((input: any) => {
            const { key } = input
            input.value = data[key]
            input.history = true
            return (
              <div className={`input col-span-1`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} showHistory={() => showHistory([key])} />
              </div>
            )
          })}
          <div className={`col-span-1 md:col-span-2 `}>
            <div className="bg-gray-200 p-2">
              <Link
                className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
                to={`/parties/${loanNumber}?edit=Builder or Seller&redirectTo=${encodeURI(
                  `/closing_screen/${loanNumber}?menu=hud1Page1`,
                )}`}
              >
                E. Name and Address of Seller
                <div className="flex items-center">
                  (Edit <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2"></ArrowTopRightOnSquareIcon>)
                </div>
              </Link>
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Name',
                  value: data['PartySeller']?.name || '',
                }}
                Key={'PartySellerName'}
                onChange={onChange}
              />
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Address',
                  value: data['PartySeller']?.address || '',
                }}
                Key={'PartySellerAddress'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className={`col-span-1 md:col-span-2 `}>
            <div className="bg-gray-200 p-2">
              <Link
                className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
                to={`/parties/${loanNumber}?edit=Lender&redirectTo=${encodeURI(
                  `/closing_screen/${loanNumber}?menu=hud1Page1`,
                )}`}
              >
                F. Name and Address of Lender
                <div className="flex items-center">
                  (Edit <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2"></ArrowTopRightOnSquareIcon>)
                </div>
              </Link>
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Company',
                  value: data['PartyLender']?.company || '',
                }}
                Key={'PartyLenderCompany'}
                onChange={onChange}
              />
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Address',
                  value: data['PartyLender']?.address || '',
                }}
                Key={'PartyLenderAddress'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className={`md:col-span-2 `}>
            <div className={`bg-gray-200 p-2`}>
              <p>G. Property Location</p>
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Address',
                  value: data['propertyAddress'],
                }}
                Key={'propertyAddress'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className={`md:col-span-2 `}>
            <div className="bg-gray-200 p-2">
              <Link
                className="flex items-center gap-3 hover:underline hover:text-shade-blue cursor-pointer w-fit"
                to={`/parties/${loanNumber}?edit=Settlement Company&redirectTo=${encodeURI(
                  `/closing_screen/${loanNumber}?menu=hud1Page1`,
                )}`}
              >
                H. Name and Address of Settlement Company
                <div className="flex items-center">
                  (Edit <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2"></ArrowTopRightOnSquareIcon>)
                </div>
              </Link>
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Company',
                  value: data['PartySettlement']?.company || '',
                }}
                Key={'PartySettlementCompany'}
                onChange={onChange}
              />
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Name',
                  value: data['PartySettlement']?.name || '',
                }}
                Key={'PartySettlementName'}
                onChange={onChange}
              />
            </div>
            <div className="input mt-1">
              <RenderInput
                input={{
                  inputType: 'text',
                  readOnly: true,
                  title: 'Address',
                  value: data['PartySettlement']?.address || '',
                }}
                Key={'PartySettlementAddress'}
                onChange={onChange}
              />
            </div>
          </div>
          <div className={`md:col-span-4 bg-gray-200 p-2`}>
            <p>I. Settlement Info</p>
          </div>
          {(inputs['settlementInfo'] || []).map((input: any) => {
            const { key } = input
            input.value = data[key]
            input.history = true
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} showHistory={() => showHistory([key])} />
              </div>
            )
          })}
          <div className={`md:col-span-2 `}>
            <div className="bg-gray-200 p-2">J. Summary of Borrower's Transaction</div>
            <div className="mt-4 text-[15px]">
              {(inputs['borrowerTransaction'] || []).map((input: any) => {
                const { key } = input
                return (
                  <div className={`input my-1`} key={key}>
                    {renderHUD1Input(input, key)}
                  </div>
                )
              })}
            </div>
          </div>
          <div className={`md:col-span-2 `}>
            <div className="bg-gray-200 p-2">K. Summary of Seller's Transaction</div>
            <div className="mt-4 text-[15px]">
              {(inputs['sellerTransaction'] || []).map((input: any) => {
                const { key } = input
                return (
                  <div className={`input my-1`} key={key}>
                    {renderHUD1Input(input, key)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Button disabled={!changed} onClick={onSaveChanges} loading={action === 'saveChanges'}>
          Save Changes
        </Button>
      </div>
      {Object.keys(ovData).length > 0 && (
        <OverrideCaclModal
          key={ovData.overrideKey}
          title={ovData.title}
          calcValue={getPrice1or2decimal(ovData.calcValue)}
          ovValue={ovData.ovValue}
          onClose={closeOVModal}
        />
      )}
      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
    </div>
  )
}
