import { Overview } from 'components/Overview'
import { useEffect } from 'react'
import { setLoanNumber } from 'utils/setLoanNumber'

import { AmortizationScheduleContent } from './AmortizationScheduleContent'

export default function AmortizationSchedule() {
  useEffect(() => {
    setLoanNumber()
  }, [])

  return (
    <div className="amortization-schedule-container py-6 px-2">
      <Overview title="Amortization Schedule" />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-4 md:p-6 p-2">
          <AmortizationScheduleContent />
        </div>
      </div>
    </div>
  )
}
